import { CartTestDtoToCartTestConverter } from './CartTestDtoToCartTestConverter';
export class CartDtoToCartConverter {
    cartTestDtoConverter;
    constructor(cartTestDtoConverter) {
        this.cartTestDtoConverter = cartTestDtoConverter;
    }
    static create = (cartTestDtoConverter) => {
        if (!cartTestDtoConverter) {
            cartTestDtoConverter = CartTestDtoToCartTestConverter.create();
        }
        return new CartDtoToCartConverter(cartTestDtoConverter);
    };
    toModel = (source) => {
        return {
            id: source.id,
            labId: source.labId,
            patientId: source.patientId,
            stripeClientSecret: source.stripeClientSecret,
            stripePaymentIntentAmount: source.stripePaymentIntentAmount,
            stripePaymentIntentId: source.stripePaymentIntentId,
            stripePaymentIntentStatus: source.stripePaymentIntentStatus,
            totals: source.totals,
            fees: source.fees,
            transactionId: source.transactionId,
            cartTests: source.cartTests ? source.cartTests.map((test) => this.cartTestDtoConverter.toModel(test)) : new Array(),
        };
    };
}
