import axios from 'axios';
export const getAuthTokenInterceptor = (authToken) => (config) => {
    if (authToken) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config.headers[AdminApiClientConfigFactory.HEADER_AUTH_TOKEN] = authToken;
    }
    return config;
};
export class AdminApiClientConfigFactory {
    static HEADER_AUTH_TOKEN = 'x-auth-token';
    static HEADER_CONTENT_TYPE = 'Content-Type';
    static HEADER_ACCEPT = 'Accept';
    static HEADER_ACCESS_CONTROL_ALLOW_ORIGIN = 'Access-Control-Allow-Origin';
    static HEADER_CF_ACCESS_CLIENT_ID = 'CF-Access-Client-Id';
    static HEADER_CF_ACCESS_CLIENT_SECRET = 'CF-Access-Client-Secret';
    static HEADER_CACHE_CONTROL = 'Cache-Control';
    static HEADER_PRAGMA = 'Pragma';
    static HEADER_EXPIRES = 'Expires';
    static DEFAULT_CF_ACCESS_CLIENT_ID = 'f7300a35d87d37a81c5b0896e68b6d51.access';
    static DEFAULT_CF_ACCESS_CLIENT_SECRET = 'e222e74419e673e98c8d7d69b2054d79a29306ad2f6452a3a50c6e96f6c43d28';
    static DEFAULT_ALLOWED_ALL_ORIGINS = '*';
    static DEFAULT_CONTENT_TYPE = 'application/json';
    static DEFAULT_NO_CACHE = 'no-cache';
    static DEFAULT_EXPIRATION = '0';
    static create = (authToken) => {
        return AdminApiClientConfigFactory.createAxiosClient(AdminApiClientConfigFactory.DEFAULT_CONTENT_TYPE, AdminApiClientConfigFactory.DEFAULT_ALLOWED_ALL_ORIGINS, AdminApiClientConfigFactory.DEFAULT_CF_ACCESS_CLIENT_ID, AdminApiClientConfigFactory.DEFAULT_CF_ACCESS_CLIENT_SECRET, AdminApiClientConfigFactory.DEFAULT_NO_CACHE, AdminApiClientConfigFactory.DEFAULT_NO_CACHE, AdminApiClientConfigFactory.DEFAULT_EXPIRATION, authToken);
    };
    static createAxiosClient = (contentType, allowedOrigins, accessClientId, accessClientSecret, cacheControl, pragma, expires, authToken) => {
        const instance = axios.create();
        instance.defaults.headers.common[AdminApiClientConfigFactory.HEADER_CONTENT_TYPE] = contentType;
        instance.defaults.headers.common[AdminApiClientConfigFactory.HEADER_ACCEPT] = contentType;
        instance.defaults.headers.get[AdminApiClientConfigFactory.HEADER_ACCESS_CONTROL_ALLOW_ORIGIN] = allowedOrigins;
        instance.defaults.headers.common[AdminApiClientConfigFactory.HEADER_CF_ACCESS_CLIENT_ID] = accessClientId;
        instance.defaults.headers.common[AdminApiClientConfigFactory.HEADER_CF_ACCESS_CLIENT_SECRET] = accessClientSecret;
        instance.defaults.headers.common[AdminApiClientConfigFactory.HEADER_CACHE_CONTROL] = cacheControl;
        instance.defaults.headers.common[AdminApiClientConfigFactory.HEADER_PRAGMA] = pragma;
        instance.defaults.headers.common[AdminApiClientConfigFactory.HEADER_EXPIRES] = expires;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        instance.interceptors.request.use(getAuthTokenInterceptor(authToken));
        return instance;
    };
}
