import { ItemBuilder, ComparisonOperator } from '..';
import { ReturnValue } from '@aws-sdk/client-dynamodb';
export class DynamoRepository {
    dbClient;
    tableName;
    static DEFAULT_ID_KEY = 'id';
    constructor(dbClient, tableName) {
        this.dbClient = dbClient;
        this.tableName = tableName;
        this.tableName = tableName;
    }
    query = () => {
        return this.dbClient.query().withTableName(this.tableName);
    };
    put = () => {
        return this.dbClient.put().withTableName(this.tableName);
    };
    scan = () => {
        return this.dbClient.scan().withTableName(this.tableName);
    };
    update = () => {
        return this.dbClient.update().withTableName(this.tableName);
    };
    delete = () => {
        return this.dbClient.delete().withTableName(this.tableName);
    };
    batchWrite = () => {
        return this.dbClient.batchWriteItem();
    };
    findById = (identifier, idKey = DynamoRepository.DEFAULT_ID_KEY) => {
        return this.query().withKeyCondition(idKey, ComparisonOperator.Equals, identifier);
    };
    saveEntity = (object) => {
        const itemBuilder = ItemBuilder.builder();
        Object.keys(object).forEach((attributeName) => {
            itemBuilder.with(attributeName, object[attributeName]);
        });
        return this.put().withItem(itemBuilder.build());
    };
    deleteById = (identifier, idKey = DynamoRepository.DEFAULT_ID_KEY) => {
        return this.delete().withKey(ItemBuilder.builder().with(idKey, identifier).build());
    };
    updateItemById = (identifier, idKey = DynamoRepository.DEFAULT_ID_KEY) => {
        return this.update().withKey(ItemBuilder.builder().with(idKey, identifier).build()).withReturnValues(ReturnValue.ALL_NEW);
    };
}
