import { SessionService } from '../SessionService';
import { CarouselItemDtoToCarouselItemConverter } from './CarouselItemDtoToCarouselItemConverter';
import { ContactUsConfigDtoToContactUsConfigConverter } from './ContactUsConfigDtoToContactUsConfigConverter';
export class DatAppConfitDtoToDatAppConfigConverter {
    carouselItemConverter;
    contactUsConfigConverter;
    static TO_BE_CONFIGURED = 'To Be Configured';
    static DEFAULT_HERO_LABEL_COLOR = '#fffff';
    constructor(carouselItemConverter, contactUsConfigConverter) {
        this.carouselItemConverter = carouselItemConverter;
        this.contactUsConfigConverter = contactUsConfigConverter;
    }
    static create = () => {
        return new DatAppConfitDtoToDatAppConfigConverter(CarouselItemDtoToCarouselItemConverter.create(), ContactUsConfigDtoToContactUsConfigConverter.create());
    };
    toModel = (source) => {
        return {
            hero: source.heroImage
                ? source.heroImage
                : {
                    label: {
                        text: DatAppConfitDtoToDatAppConfigConverter.TO_BE_CONFIGURED,
                        color: DatAppConfitDtoToDatAppConfigConverter.DEFAULT_HERO_LABEL_COLOR,
                    },
                },
            theme: source.theme ? JSON.parse(source.theme) : undefined,
            supportedStates: source.supportedStates || [],
            residenceMsg: source.residenceMsg || DatAppConfitDtoToDatAppConfigConverter.TO_BE_CONFIGURED,
            feesMsg: source.feesMsg,
            enabled: source.enabled !== undefined ? source.enabled : false,
            labLocationSubHeader: source.labLocationSubHeader || DatAppConfitDtoToDatAppConfigConverter.TO_BE_CONFIGURED,
            reviewingResults: source.reviewingResults || DatAppConfitDtoToDatAppConfigConverter.TO_BE_CONFIGURED,
            cancellationPolicy: source.cancellationPolicy || DatAppConfitDtoToDatAppConfigConverter.TO_BE_CONFIGURED,
            expirationPolicy: source.expirationPolicy || DatAppConfitDtoToDatAppConfigConverter.TO_BE_CONFIGURED,
            nonRefundableFee: source.nonRefundableFee || 0,
            inactivityTimeoutIntervalInMs: source.inactivityTimeoutIntervalInMs || SessionService.DEFAULT_TIME_OUT_INTERVAL,
            inactivityWarningIntervalInMs: source.inactivityWarningIntervalInMs || SessionService.DEFAULT_TIME_OUT_WARNING_INTERVAL,
            signInAlreadyHaveAnAccountHeader: source.signInAlreadyHaveAnAccountHeader || SessionService.DEFAULT_SIGN_IN_ALREADY_HAVE_ACCOUNT_HEADER,
            signInLoginHeader: source.signInLoginHeader || SessionService.DEFAULT_SIGN_IN_LOGIN_HEADER,
            carouselItems: source.carouselItems ? source.carouselItems.map((item) => this.carouselItemConverter.toModel(item)) : [],
            contactUsConfig: source.contactUs ? this.contactUsConfigConverter.toModel(source.contactUs) : undefined,
            userInitiatedOrderCancellationEnabled: source.userInitiatedOrderCancellationEnabled,
            serviceAreasEnabled: source.serviceAreasEnabled,
            guestCheckoutEnabled: source.guestCheckoutEnabled,
            checkoutFeeLabel: source.checkoutFeeLabel || 'Fees',
            checkoutFeeInPennies: source.checkoutFeeInPennies || 0,
            genders: source.genders || {},
        };
    };
}
