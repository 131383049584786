import { LuminateRuntimeException } from '../models';
export const UNKNOWN_ERROR_MSG = 'Unknown error';
export const apiGlobalErrorHandler = (error, _request, response, _next) => {
    let customError;
    if (!(error instanceof LuminateRuntimeException)) {
        customError = new LuminateRuntimeException(error.message ? error.message : UNKNOWN_ERROR_MSG, error);
    }
    else {
        customError = error;
    }
    console.error(customError.message, customError);
    response.status(customError.status).send(customError);
};
