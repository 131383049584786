import { AdminApiClientConfigFactory } from '../services';
export class IdvApi {
    apiBaseUrl;
    axiosInstance;
    static IDV_BASE_PATH = '/api/v2/idv';
    static ANSWERS_URL = '/answer';
    static REST_URL = '/reset';
    constructor(apiBaseUrl, axiosInstance) {
        this.apiBaseUrl = apiBaseUrl;
        this.axiosInstance = axiosInstance;
    }
    static create = (apiBaseUrl, authToken) => {
        const instance = AdminApiClientConfigFactory.create(authToken);
        return new IdvApi(apiBaseUrl, instance);
    };
    getIdvSessionState = async (idvSessionId) => {
        return await this.axiosInstance.post(`${this.apiBaseUrl}${IdvApi.IDV_BASE_PATH}`, { idvSessionId });
    };
    submitAnswer = async (idvSessionId, question, submission) => {
        console.log('Submitting Answer', { idvSessionId, question, submission });
        try {
            return await this.axiosInstance.post(`${this.apiBaseUrl}${IdvApi.IDV_BASE_PATH}${IdvApi.ANSWERS_URL}`, {
                idvSessionId: idvSessionId,
                question: question,
                submission: submission,
            });
        }
        catch (ex) {
            console.error('Answer Submission Error', ex);
            throw ex;
        }
    };
    reset = async (patientId) => {
        await this.axiosInstance.post(`${this.apiBaseUrl}${IdvApi.IDV_BASE_PATH}${IdvApi.REST_URL}`, {
            patientId,
        });
    };
}
