export class PatientDtoToPatientProfileConverter {
    static create = () => {
        return new PatientDtoToPatientProfileConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            userId: source.userId,
            firstName: source.firstName,
            lastName: source.lastName,
            email: source.email,
            address1: source.address,
            address2: source.address2,
            city: source.city,
            state: source.state,
            zip: source.zip,
            phone: source.phone,
            phoneType: source.phoneType,
            gender: source.gender,
            dateOfBirth: source.dob ? new Date(source.dob) : undefined,
            preferencesDto: source.preferencesDto,
        };
    };
}
