export class DatFastingContentConverter {
    static create = () => {
        return new DatFastingContentConverter();
    };
    toModel = (source) => {
        return {
            header: source.header,
            description: source.description,
            subHeader: source.subHeader,
            subDescription: source.subDescription
        };
    };
}
