export class NativeNavigationCarouselActionDtoToNativeNavigationCarouselActionConverter {
    static create = () => {
        return new NativeNavigationCarouselActionDtoToNativeNavigationCarouselActionConverter();
    };
    toModel = (source) => {
        return {
            label: source.label,
            screen: source.screen,
            parameters: source.parameters,
        };
    };
}
