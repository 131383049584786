import { OrderDTOStatusEnumToOrderStatusConverter } from './OrderDTOStatusEnumToOrderStatusConverter';
export class OrderStatusHistoryDtoToOrderStatusHistoryConverter {
    orderDtoStatusConverter;
    constructor(orderDtoStatusConverter) {
        this.orderDtoStatusConverter = orderDtoStatusConverter;
    }
    static create = () => {
        return new OrderStatusHistoryDtoToOrderStatusHistoryConverter(OrderDTOStatusEnumToOrderStatusConverter.create());
    };
    toModel = (source) => {
        return {
            status: this.orderDtoStatusConverter.toModel(source.status),
            orderId: source.orderId,
            createdDateTime: source.createdDateTime ? new Date(source.createdDateTime) : undefined,
            firstName: source.firstName,
            lastName: source.lastName,
            userId: source.userId,
        };
    };
}
