import { ContactUsPhoneConfigDtoToContactUsPhoneConfigConverter } from './ContactUsPhoneConfigDtoToContactUsPhoneConfigConverter';
export class ContactUsConfigDtoToContactUsConfigConverter {
    phoneConfigConverter;
    constructor(phoneConfigConverter) {
        this.phoneConfigConverter = phoneConfigConverter;
    }
    static create = () => {
        return new ContactUsConfigDtoToContactUsConfigConverter(ContactUsPhoneConfigDtoToContactUsPhoneConfigConverter.create());
    };
    toModel = (source) => {
        return {
            addressDetailsLabel: source.addressDetailsLabel,
            banner: source.banner,
            contactDetailsLabel: source.contactDetailsLabel,
            description: source.description,
            email: source.email,
            hideAddressDetails: source.hideAddressDetails,
            phones: source.phones ? source.phones.map((phoneConfig) => this.phoneConfigConverter.toModel(phoneConfig)) : [],
        };
    };
}
