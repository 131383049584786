import { OrderableTestPublicControllerApi } from '@luminate/connect-api-client-ts';
import { CollectionType, Pagination } from '../models';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
import { OrderableTestBundleDtoToOrderableTestConverter, OrderableTestDtoToOrderableTestConverter } from './converter';
export class PublicOrderableTestService {
    publicOrderableTestApi;
    testBundleDtoConverter;
    orderableTestDtoConverter;
    constructor(publicOrderableTestApi, testBundleDtoConverter, orderableTestDtoConverter) {
        this.publicOrderableTestApi = publicOrderableTestApi;
        this.testBundleDtoConverter = testBundleDtoConverter;
        this.orderableTestDtoConverter = orderableTestDtoConverter;
    }
    /**
     * Create a new Public Orderable Test Service instance.
     * @returns {PublicOrderableTestService} New service instance.
     */
    static create = (apiBaseUrl) => {
        return new PublicOrderableTestService(new OrderableTestPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), OrderableTestBundleDtoToOrderableTestConverter.create(), OrderableTestDtoToOrderableTestConverter.create());
    };
    /**
     * Retrieve the list of tests marked as visible and can be ordered from a Lab.
     * @param {number} labId Unique identifier of the lab from which the tests will be retrieved.
     * @param {CollectionType} [collectionType] Type of orderable test to retrieve.
     * @param {number} [page] The page of tests to be retrieved.
     * @param {number} [pageSize] The size of the page to be retrieved.
     * @returns {Promise<Array<OrderableTest>>} List of tests marked as visible that can be ordered from the lab.
     */
    listVisibleOrderableTests = async (labId, collectionType, page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE) => {
        const response = await this.publicOrderableTestApi.getVisibleOrderableTestsListUsingGET(labId, collectionType, page, pageSize);
        return response.data ? response.data.map((test) => this.orderableTestDtoConverter.toModel(test)) : new Array();
    };
    /**
     * Retrieve a list of tests filtered by the specified criteria from a Lab.
     * @param {number} labId Unique identifier of the lab from which the tests will be retrieved.
     * @param {string} filter Filter criteria to be used to limit the tests retrieved.
     * @param {number} [page] The page of tests to be retrieved.
     * @param {number} [pageSize] The size of the page to be retrieved.
     * @returns {Promise<Array<OrderableTest>>} List of tests marked as visible that can be ordered from the lab matching the specified filter criteria.
     */
    listVisibleOrderableTestsByFilter = async (labId, filter, page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE) => {
        const response = await this.publicOrderableTestApi.getVisibleOrderableTestsByNameUsingGET(labId, filter, page, pageSize);
        return response.data ? response.data.map((test) => this.orderableTestDtoConverter.toModel(test)) : new Array();
    };
    /**
     * Retrieve the list of tests marked visible for a category from a Lab.
     * @param {number} labId Unique identifier of the lab from which the tests will be retrieved.
     * @param {number} categoryId Unique identifier of the category  for which the tests will be retrieved.
     * @param {number} [page] The page of tests to be retrieved.
     * @param {number} [pageSize] The size of the page to be retrieved.
     * @returns {Promise<OrderableTest>} List of tests marked as visible that can be ordered from the lab in a category.
     */
    listVisibleOrderableTestsByCategory = async (labId, categoryId, page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE) => {
        const response = await this.publicOrderableTestApi.getVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, pageSize);
        return response.data ? response.data.map((test) => this.orderableTestDtoConverter.toModel(test)) : new Array();
    };
    /**
     * Retrieve the details for an orderable test.
     * @param {number} orderableTestId Unique identifier for the test to be retrieved.
     * @returns {Promise<OrderableTest | undefined>} The test.
     */
    listOrderableTestDetails = async (orderableTestId) => {
        const response = await this.publicOrderableTestApi.getOrderableTestDetailsUsingGET(orderableTestId);
        return response.data ? this.convertTest(response.data) : undefined;
    };
    /**
     * Retrieve the Featured Category Tests collection for a Lab.
     * @param {number} labId Unique identifier for the lab from which the featured tests will be retrieved.
     * @param {number} categoryId Unique identifier for the category from which the featured tests will be retrieved.
     * @param {number} page Page to be retrieved.
     * @param {number} pageSize Page size to be retrieved.
     * @returns {Promise<Array<OrderableTest>>} Featured test collection.
     */
    getCategoryFeaturedTests = async (labId, categoryId, page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE) => {
        const response = await this.publicOrderableTestApi.getFeaturedVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, pageSize);
        return response.data ? response.data.map((test) => this.orderableTestDtoConverter.toModel(test)) : new Array();
    };
    /**
     * Retrieve the Featured Tests collection for a Lab.
     * @param {number} labId Unique identifier for the lab from which the featured tests will be retrieved.
     * @param {number} page Page to be retrieved.
     * @param {number} pageSize Page size to be retrieved.
     * @returns {Promise<Array<OrderableTest>>} Featured test collection.
     */
    getFeaturedTests = async (labId, page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE) => {
        const response = await this.publicOrderableTestApi.getVisibleFeaturedOrderableTestsListUsingGET(labId, page, pageSize);
        return response.data ? response.data.map((test) => this.orderableTestDtoConverter.toModel(test)) : new Array();
    };
    /**
     * Convert an OrderableTestDTO | OrderableTestBundleDTO into an OrderableTest.
     * @param {OrderableTestDTO | OrderableTestBundleDTO} toConvert Test to be converted.
     * @returns {OrderableTest} Converted test.
     */
    convertTest = (toConvert) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (toConvert && 'collectionType' in toConvert && toConvert.collectionType === CollectionType.PANEL) {
            return this.orderableTestDtoConverter.toModel(toConvert);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
        }
        else if (toConvert && 'collectionType' in toConvert && toConvert.collectionType === CollectionType.BUNDLE) {
            return this.testBundleDtoConverter.toModel(toConvert);
        }
    };
}
