import { AdminAppEvent, ExpoAdminAppEvent, VueAdminAppEvent } from './AdminAppEvents';
export const PATIENT_INVITE_START_EVENT_NAME = 'invite-patient-start';
export const PATIENT_INVITE_FINISH_EVENT_NAME = 'invite-patient-finish';
export class StartPatientInviteEvent extends ExpoAdminAppEvent {
    constructor() {
        super({ eventName: PATIENT_INVITE_START_EVENT_NAME });
    }
    static isInstanceOf = (object) => {
        return AdminAppEvent.isInstanceOf(object, PATIENT_INVITE_START_EVENT_NAME);
    };
}
export class FinishPatientInviteEvent extends VueAdminAppEvent {
    constructor() {
        super({ eventName: PATIENT_INVITE_FINISH_EVENT_NAME });
    }
    static isInstanceOf = (object) => {
        return AdminAppEvent.isInstanceOf(object, PATIENT_INVITE_FINISH_EVENT_NAME);
    };
}
