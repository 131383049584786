import axios from 'axios';
export class PatientProfileApi {
    apiBaseUrl;
    axios;
    static PROFILE_BASE_PATH = '/api/v2/patient';
    static PROFILE_INFO = '/profile-info';
    static UPDATE_ADDRESS = '/update-address';
    static UPDATE_EMAIL = '/update-email';
    static UPDATE_GENDER = '/update-gender';
    static INVITE_PATIENT = '/invite';
    static ADMIN_BASE_PATH = '/api/admin/patient';
    static PATIENT_LINKS = '/search/links/';
    static PATIENT_UNLINK = '/unlink';
    static REGISTRATION_REQUEST = '/send-registration-request';
    PROFILE_BASE_URL;
    ADMIN_BASE_URL;
    constructor(apiBaseUrl, axios) {
        this.apiBaseUrl = apiBaseUrl;
        this.axios = axios;
        this.PROFILE_BASE_URL = `${apiBaseUrl}${PatientProfileApi.PROFILE_BASE_PATH}`;
        this.ADMIN_BASE_URL = `${apiBaseUrl}${PatientProfileApi.ADMIN_BASE_PATH}`;
    }
    getProfile = async () => {
        return this.axios.get(`${this.PROFILE_BASE_URL}${PatientProfileApi.PROFILE_INFO}`);
    };
    updateProfile = async (address1, city, state, zip, phone, phoneType, address2) => {
        return this.axios.post(`${this.PROFILE_BASE_URL}${PatientProfileApi.UPDATE_ADDRESS}`, {
            address1,
            address2,
            city,
            state,
            zipCode: zip,
            phone: phone,
            phoneType: phoneType,
        });
    };
    changeEmailAddress = async (email, confirmEmail, password) => {
        return this.axios.post(`${this.PROFILE_BASE_URL}${PatientProfileApi.UPDATE_EMAIL}`, {
            email,
            confirmEmail,
            password,
        });
    };
    updateGender = async (gender) => {
        return this.axios.post(`${this.PROFILE_BASE_URL}${PatientProfileApi.UPDATE_GENDER}`, {
            gender,
        });
    };
    invitePatient = async (inviteRequest) => {
        return this.axios.post(`${this.PROFILE_BASE_URL}${PatientProfileApi.INVITE_PATIENT}`, inviteRequest);
    };
    sendRegistrationRequest = async (patientId) => {
        return this.axios.post(`${this.ADMIN_BASE_URL}${PatientProfileApi.REGISTRATION_REQUEST}/${patientId}`);
    };
    getLinks = async (patientId) => {
        return this.axios.get(`${this.ADMIN_BASE_URL}${PatientProfileApi.PATIENT_LINKS}${patientId}`);
    };
    unlinkPatient = async (minorPatientId, parentPatientId) => {
        return this.axios.post(`${this.ADMIN_BASE_URL}${PatientProfileApi.PATIENT_UNLINK}`, { minorPatientId, parentPatientId });
    };
}
export class PatientProfileApiFactory {
    static create = (apiBaseUrl, axiosInstance) => {
        if (!axiosInstance) {
            axiosInstance = axios.create();
        }
        return new PatientProfileApi(apiBaseUrl, axiosInstance);
    };
}
