import { CartTestDtoToCartTestConverter } from './CartTestDtoToCartTestConverter';
export class PreCheckoutStatusDtoToPreCheckoutStatusConverter {
    cartTestDtoConverter;
    constructor(cartTestDtoConverter) {
        this.cartTestDtoConverter = cartTestDtoConverter;
    }
    static create = (cartTestDtoConverter) => {
        if (!cartTestDtoConverter) {
            cartTestDtoConverter = CartTestDtoToCartTestConverter.create();
        }
        return new PreCheckoutStatusDtoToPreCheckoutStatusConverter(cartTestDtoConverter);
    };
    toModel = (source) => {
        return {
            validAge: source.validAge !== undefined ? source.validAge : false,
            validGender: source.validGender !== undefined ? source.validGender : false,
            validResidency: source.validResidency !== undefined ? source.validResidency : false,
            validTelecom: source.validTelecom !== undefined ? source.validTelecom : false,
            testsFailingGenderCheck: source.testsFailingGenderCheck
                ? source.testsFailingGenderCheck.map((cartTestDto) => this.cartTestDtoConverter.toModel(cartTestDto))
                : new Array(),
        };
    };
}
