import { DateUtilities } from '../DateUtilities';
export class OrderRefundDtoToOrderRefundConverter {
    static create = () => {
        return new OrderRefundDtoToOrderRefundConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            amount: source.amount,
            orderId: source.orderId,
            refundId: source.refundId,
            createdDateTime: DateUtilities.fromString(source.createdDateTime),
            description: source.description,
        };
    };
}
