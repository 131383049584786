import { CollectionType, SampleType } from '../../models';
import { OrderableTestDtoToOrderableTestConverter } from './OrderableTestDtoToOrderableTestConverter';
import { CategoryDtoToCategoryConverter } from './CategoryDtoToCategoryConverter';
export class OrderableTestBundleDtoToOrderableTestConverter {
    orderableTestDtoConverter;
    categoryDtoConverter;
    constructor(orderableTestDtoConverter, categoryDtoConverter) {
        this.orderableTestDtoConverter = orderableTestDtoConverter;
        this.categoryDtoConverter = categoryDtoConverter;
    }
    static create = (orderableTestDtoConverter, categoryDtoConverter) => {
        if (!orderableTestDtoConverter) {
            orderableTestDtoConverter = OrderableTestDtoToOrderableTestConverter.create();
        }
        if (!categoryDtoConverter) {
            categoryDtoConverter = CategoryDtoToCategoryConverter.create();
        }
        return new OrderableTestBundleDtoToOrderableTestConverter(orderableTestDtoConverter, categoryDtoConverter);
    };
    toModel = (source) => {
        return {
            testId: source.testId,
            labId: source.labId,
            collectionType: CollectionType[source.collectionType],
            sampleType: source.sampleType ? SampleType[source.sampleType] : undefined,
            code: source.code,
            name: source.name,
            imageUrl: source.imageUrl,
            shortDescription: source.shortDescription ? source.shortDescription : '',
            longDescription: source.longDescription ? source.longDescription : '',
            turnAroundTime: source.turnAroundTime,
            price: source.price,
            visible: source.visible,
            fasting: source.fasting,
            minors: source.minors,
            featured: source.featured,
            gender: source.gender,
            orderableTests: source.orderableTestDTOList
                ? source.orderableTestDTOList.map((test) => this.orderableTestDtoConverter.toModel(test))
                : new Array(),
            categories: source.categoryDTOList ? source.categoryDTOList.map((category) => this.categoryDtoConverter.toModel(category)) : new Array(),
        };
    };
}
