import { LabEntityDtoToLabConverter } from './LabEntityDtoToLabConverter';
import { DatAppConfitDtoToDatAppConfigConverter } from './DatAppConfitDtoToDatAppConfigConverter';
export class SessionDtoToLabContextConverter {
    labDtoConverter;
    datAppConfigConverter;
    constructor(labDtoConverter, datAppConfigConverter) {
        this.labDtoConverter = labDtoConverter;
        this.datAppConfigConverter = datAppConfigConverter;
    }
    static create = () => {
        return new SessionDtoToLabContextConverter(LabEntityDtoToLabConverter.create(), DatAppConfitDtoToDatAppConfigConverter.create());
    };
    toModel = (source) => {
        return {
            lab: source.lab ? this.labDtoConverter.toModel(source.lab) : undefined,
            appConfig: source.datAppConfig ? this.datAppConfigConverter.toModel(source.datAppConfig) : undefined,
        };
    };
}
