export class IndividualTestToIndividualTestDtoConverter {
    static create = () => {
        return new IndividualTestToIndividualTestDtoConverter();
    };
    toModel = (source) => {
        return {
            testId: source.testId,
            code: source.code,
            name: source.name,
        };
    };
}
