import { v4 as uuid } from 'uuid';
export var ActivityLogType;
(function (ActivityLogType) {
    ActivityLogType["SIGNUP_DISPLAYED"] = "SIGNUP_DISPLAYED";
    ActivityLogType["AUTHENTICATION_COMPLETED"] = "AUTHENTICATION_COMPLETED";
    ActivityLogType["GUARDIAN_RECORDS_SUBMITTED"] = "GUARDIAN_RECORDS_SUBMITTED";
    ActivityLogType["COVID19_LINK_CLICKED"] = "COVID19_LINK_CLICKED";
    ActivityLogType["BALANCE_MODAL_CLOSED"] = "BALANCE_MODAL_CLOSED";
    ActivityLogType["BALANCE_MODAL_DISPLAYED"] = "BALANCE_MODAL_DISPLAYED";
    ActivityLogType["BALANCE_MODAL_PAY_BILL_BUTTON_CLICKED"] = "BALANCE_MODAL_PAY_BILL_BUTTON_CLICKED";
    ActivityLogType["BILL_PAY_LINK_CLICKED"] = "BILL_PAY_LINK_CLICKED";
    ActivityLogType["COVID19_TREATMENT_LINK_CLICKED"] = "COVID19_TREATMENT_LINK_CLICKED";
    ActivityLogType["COVID19_ANTIBODY_LINK_CLICKED"] = "COVID19_ANTIBODY_LINK_CLICKED";
    ActivityLogType["DAT_LINK_CLICKED"] = "DAT_LINK_CLICKED";
    ActivityLogType["SCHEDULING_LINK_CLICKED"] = "SCHEDULING_LINK_CLICKED";
    ActivityLogType["SIGNUP_COMPLETED"] = "SIGNUP_COMPLETED";
    ActivityLogType["SIGNUP_EXISTING_PATIENT_WITH_NOTIFICATION_CODE_DISPLAYED"] = "SIGNUP_EXISTING_PATIENT_WITH_NOTIFICATION_CODE_DISPLAYED";
    ActivityLogType["SMS_RECEIVED_EMAIL"] = "SMS_RECEIVED_EMAIL";
    ActivityLogType["SMS_RECEIVED_HELP"] = "SMS_RECEIVED_HELP";
    ActivityLogType["SMS_RECEIVED_OTHER"] = "SMS_RECEIVED_OTHER";
    ActivityLogType["SMS_RECEIVED_STOP"] = "SMS_RECEIVED_STOP";
    ActivityLogType["SMS_RECEIVED_YES"] = "SMS_RECEIVED_YES";
    ActivityLogType["WELCOME_EMAIL_SENT"] = "WELCOME_EMAIL_SENT";
    ActivityLogType["EMAIL_SENT_RELEASE"] = "EMAIL_SENT_RELEASE";
    ActivityLogType["COUNSELING_PROGRAM"] = "COUNSELING_PROGRAM";
    ActivityLogType["REGISTRATION_MESSAGE_SENT"] = "REGISTRATION_MESSAGE_SENT";
})(ActivityLogType || (ActivityLogType = {}));
export class ActivityLogEntity {
    activityLogType;
    id;
    timestamp;
    static FIELDS = {
        ID: 'id',
        ACTIVITY_KEY: 'activity_key',
        TIMESTAMP: 'timestamp',
        PATIENT_ID: 'patient_id',
    };
    patientId;
    constructor(activityLogType, id = uuid().replaceAll('-', ''), timestamp = new Date()) {
        this.activityLogType = activityLogType;
        this.id = id;
        this.timestamp = timestamp;
    }
    static from = (type, id, timestamp) => {
        return new type(id, timestamp);
    };
}
