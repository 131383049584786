import { OrderStatus } from '../../models';
export class OrderDTOStatusEnumToOrderStatusConverter {
    static PAYMENT_COMPLETED = 'PAYMENT_COMPLETED';
    static REFUND = 'REFUND';
    static FULFILLED = 'FULFILLED';
    static EXPIRED = 'EXPIRED';
    static create = () => {
        return new OrderDTOStatusEnumToOrderStatusConverter();
    };
    toModel = (source) => {
        switch (source) {
            case OrderDTOStatusEnumToOrderStatusConverter.PAYMENT_COMPLETED:
                return OrderStatus.OPEN;
            case OrderDTOStatusEnumToOrderStatusConverter.REFUND:
                return OrderStatus.REFUNDED;
            case OrderDTOStatusEnumToOrderStatusConverter.FULFILLED:
                return OrderStatus.CLOSED;
            case OrderDTOStatusEnumToOrderStatusConverter.EXPIRED:
                return OrderStatus.EXPIRED;
            default:
                return undefined;
        }
    };
}
