export class DatAcknowledgmentConverter {
    static create = () => {
        return new DatAcknowledgmentConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            content: source.content,
            labId: source.labId,
            displayOrder: source.displayOrder,
        };
    };
}
