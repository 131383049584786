import { AccessionViewEntityConverter } from './converter';
import { AccessionViewsRepository } from '../esl';
export class AccessionViewsService {
    accessionViewsRepository;
    accessionViewEntityConverter;
    constructor(accessionViewsRepository, accessionViewEntityConverter) {
        this.accessionViewsRepository = accessionViewsRepository;
        this.accessionViewEntityConverter = accessionViewEntityConverter;
    }
    static create = (accessionViewsRepository, accessionViewEntityConverter) => {
        return new AccessionViewsService(accessionViewsRepository || AccessionViewsRepository.create(), accessionViewEntityConverter || AccessionViewEntityConverter.create());
    };
    getAccessionViewsByPatientAndExternalLabIdWithTypes = async (patientId, externalLabId, types) => {
        const result = await this.accessionViewsRepository.getAccessionViewsByPatientAndExternalLabIdWithTypes(patientId, externalLabId, types);
        return result.Items?.map((item) => this.accessionViewEntityConverter.toModel(item)) || [];
    };
    getAccessionViewsByPatientAndExternalLabIdWithTypesAfterTimestamp = async (patientId, externalLabId, type, timestamp) => {
        return (await this.getAccessionViewsByPatientAndExternalLabIdWithTypes(patientId, externalLabId, type)).filter((accessionView) => accessionView.timestamp && accessionView.timestamp >= timestamp);
    };
    getAccessionViewsByPatientAndExternalLabIdAndType = async (patientId, externalLabId, type) => {
        const result = await this.accessionViewsRepository.getAccessionViewsByPatientAndExternalLabIdAndType(patientId, externalLabId, type);
        return result.Items?.map((item) => this.accessionViewEntityConverter.toModel(item)) || [];
    };
    getAccessionViewsByPatientAndExternalLabIdAndTypeAfterTimestamp = async (patientId, externalLabId, type, timestamp) => {
        return (await this.getAccessionViewsByPatientAndExternalLabIdAndType(patientId, externalLabId, type)).filter((accessionView) => accessionView.timestamp && accessionView.timestamp >= timestamp);
    };
}
