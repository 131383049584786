import { ItemBuilder } from './ItemBuilder';
export class ExpressionAttributeValuesBuilder {
    itemBuilder = ItemBuilder.builder();
    static DEFAULT_KEY_PREFIX = ':';
    keyPrefix = ExpressionAttributeValuesBuilder.DEFAULT_KEY_PREFIX;
    static builder = () => {
        return new ExpressionAttributeValuesBuilder();
    };
    generateKey = (name) => {
        return `${this.keyPrefix}${name}`;
    };
    withKeyPrefix = (keyPrefix) => {
        this.keyPrefix = keyPrefix;
        return this;
    };
    with = (name, value, attributeValueNameOverride) => {
        this.itemBuilder.with(this.generateKey(attributeValueNameOverride || name), value);
        return this;
    };
    withString = (name, value) => {
        this.itemBuilder.withString(this.generateKey(name), value);
        return this;
    };
    withNumber = (name, value) => {
        this.itemBuilder.withNumber(this.generateKey(name), value);
        return this;
    };
    withBoolean = (name, value) => {
        this.itemBuilder.withBoolean(this.generateKey(name), value);
        return this;
    };
    withMap = (name, value) => {
        this.itemBuilder.withMap(this.generateKey(name), value);
        return this;
    };
    build = () => {
        return this.itemBuilder.build();
    };
}
