import { FaqItemDtoToFaqConverter } from './FaqItemDtoToFaqConverter';
export class FaqCollectionDtoToFaqCollectionConverter {
    faqItemConverter;
    constructor(faqItemConverter) {
        this.faqItemConverter = faqItemConverter;
    }
    static create = () => {
        return new FaqCollectionDtoToFaqCollectionConverter(FaqItemDtoToFaqConverter.create());
    };
    toModel = (source) => {
        return {
            id: source.id,
            name: source.name,
            questions: source.questions.map((faqDto) => this.faqItemConverter.toModel(faqDto)),
        };
    };
}
