import { Command as $Command } from "@smithy/smithy-client";
import { marshallInput, unmarshallOutput } from "../commands/utils";
export class DynamoDBDocumentClientCommand extends $Command {
    addMarshallingMiddleware(configuration) {
        const { marshallOptions = {}, unmarshallOptions = {} } = configuration.translateConfig || {};
        marshallOptions.convertTopLevelContainer = marshallOptions.convertTopLevelContainer ?? true;
        unmarshallOptions.convertWithoutMapWrapper = unmarshallOptions.convertWithoutMapWrapper ?? true;
        this.clientCommand.middlewareStack.addRelativeTo((next, context) => async (args) => {
            args.input = marshallInput(this.input, this.inputKeyNodes, marshallOptions);
            context.dynamoDbDocumentClientOptions =
                context.dynamoDbDocumentClientOptions || DynamoDBDocumentClientCommand.defaultLogFilterOverrides;
            const input = args.input;
            context.dynamoDbDocumentClientOptions.overrideInputFilterSensitiveLog = () => {
                return context.inputFilterSensitiveLog?.(input);
            };
            return next(args);
        }, {
            name: "DocumentMarshall",
            relation: "before",
            toMiddleware: "serializerMiddleware",
            override: true,
        });
        this.clientCommand.middlewareStack.addRelativeTo((next, context) => async (args) => {
            const deserialized = await next(args);
            const output = deserialized.output;
            context.dynamoDbDocumentClientOptions =
                context.dynamoDbDocumentClientOptions || DynamoDBDocumentClientCommand.defaultLogFilterOverrides;
            context.dynamoDbDocumentClientOptions.overrideOutputFilterSensitiveLog = () => {
                return context.outputFilterSensitiveLog?.(output);
            };
            deserialized.output = unmarshallOutput(deserialized.output, this.outputKeyNodes, unmarshallOptions);
            return deserialized;
        }, {
            name: "DocumentUnmarshall",
            relation: "before",
            toMiddleware: "deserializerMiddleware",
            override: true,
        });
    }
}
DynamoDBDocumentClientCommand.defaultLogFilterOverrides = {
    overrideInputFilterSensitiveLog(...args) { },
    overrideOutputFilterSensitiveLog(...args) { },
};
