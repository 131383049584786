export class ClientEvent {
    name;
    data;
    constructor(name, data) {
        this.name = name;
        this.data = data;
    }
}
export class AdminAppEvent extends ClientEvent {
    constructor(name, data) {
        super(name, data);
    }
    static isInstanceOf = (object, eventName) => {
        if (eventName === undefined) {
            return 'eventName' in object;
        }
        else {
            return 'eventName' in object && object.eventName === eventName;
        }
    };
}
export class ExpoAdminAppEvent extends AdminAppEvent {
    constructor(data) {
        super('expo', data);
    }
}
export class VueAdminAppEvent extends AdminAppEvent {
    constructor(data) {
        super('vue', data);
    }
}
