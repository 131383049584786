import { ShoppingCartPatientControllerApi } from '@luminate/connect-api-client-ts';
import { CartDtoToCartConverter, PreCheckoutStatusDtoToPreCheckoutStatusConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class PatientShoppingCartService {
    patientShoppingCartApi;
    cartDtoConverter;
    precheckouStatusDtoConverter;
    constructor(patientShoppingCartApi, cartDtoConverter, precheckouStatusDtoConverter) {
        this.patientShoppingCartApi = patientShoppingCartApi;
        this.cartDtoConverter = cartDtoConverter;
        this.precheckouStatusDtoConverter = precheckouStatusDtoConverter;
    }
    /**
     * Helper factory used to create a new instance of {@link PatientShoppingCartService}.
     * @param {@link string} apiBaseUrl Endpoint URL used to make requests for {@link ShoppingCartPatientControllerApi}.
     * @param {@link string} authToken Authorization use when making requests.
     * @param {@link CartDtoToCartConverter} cartDtoConverter Service to convert {@link CartDTO} into {@link Cart}.
     * @param {@link PreCheckoutStatusDtoToPreCheckoutStatusConverter} precheckoutStatusDtoConverter Service to convert dto into application domain models.
     */
    static create = (apiBaseUrl, authToken, cartDtoConverter, precheckoutStatusDtoConverter) => {
        return new PatientShoppingCartService(new ShoppingCartPatientControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), cartDtoConverter ? cartDtoConverter : CartDtoToCartConverter.create(), precheckoutStatusDtoConverter ? precheckoutStatusDtoConverter : PreCheckoutStatusDtoToPreCheckoutStatusConverter.create());
    };
    /**
     * Assign a guest cart with a Patient.
     * @param {string} transactionId Unique identifier of the guest cart to be assigned to the currently authenticated patient.
     * @returns {Promise<Cart>} The patient's cart.
     */
    assignShoppingCartToUser = async (transactionId) => {
        const response = await this.patientShoppingCartApi.assignShoppingCartToUserUsingPOST(transactionId);
        return this.cartDtoConverter.toModel(response.data);
    };
    /**
     * Create / Update the payment intent necessary for Stripe in order to be able to facilitate a payment request.
     * @param {string} transactionId Unique identifier of the patient's cart for which they intend to submit payment.
     * @returns {Promise<Cart>} The patient's caret updated with the Stripe payment intent details.
     */
    createOrUpdatePaymentIntent = async (transactionId) => {
        const response = await this.patientShoppingCartApi.createOrUpdatePaymentIntentUsingPOST(transactionId);
        return this.cartDtoConverter.toModel(response.data);
    };
    /**
     * Perform pre-checkout validation to ensure that the patient will be able to successfully submit the order which may include Wheel Integrations.
     * @param transactionId
     */
    performPrecheckoutValidation = async (transactionId) => {
        const response = await this.patientShoppingCartApi.pereformPreCheckoutValidationUsingGET(transactionId);
        return this.precheckouStatusDtoConverter.toModel(response.data);
    };
    /**
     * Retrieve the most recent shopping cart for an authenticated user.
     * @return {Promise<Cart>} Shopping cart for authenticated user.
     */
    retrieveMostRecentCart = async () => {
        const response = await this.patientShoppingCartApi.getMostRecentCartForUserUsingGET();
        return this.cartDtoConverter.toModel(response.data);
    };
}
