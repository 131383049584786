import axios from 'axios';
export class AdminLabServiceApi {
    apiBaseUrl;
    axios;
    static ADMIN_LAB_BASE_PATH = '/api/admin/labs';
    static ADMIN_LAB_DTO_PARAMETER = '?dto=true';
    ADMIN_LAB_BASE_URL;
    constructor(apiBaseUrl, axios) {
        this.apiBaseUrl = apiBaseUrl;
        this.axios = axios;
        this.ADMIN_LAB_BASE_URL = `${apiBaseUrl}${AdminLabServiceApi.ADMIN_LAB_BASE_PATH}`;
    }
    static create = (apiBaseUrl, axiosInstance) => {
        if (!axiosInstance) {
            axiosInstance = axios.create();
        }
        return new AdminLabServiceApi(apiBaseUrl, axiosInstance);
    };
    getExternalLabIdMap = async () => {
        return this.axios.get(`${this.ADMIN_LAB_BASE_URL}`);
    };
    getLabs = async () => {
        return this.axios.get(`${this.ADMIN_LAB_BASE_URL}${AdminLabServiceApi.ADMIN_LAB_DTO_PARAMETER}`);
    };
}
