import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient } from '@aws-sdk/lib-dynamodb';
import { QueryOperation } from './QueryOperation';
import { PutOperation } from './PutOperation';
import { ScanOperation } from './ScanOperation';
import { BatchWriteItemOperation } from './BatchWriteItemOperation';
import { UpdateItemOperation } from './UpdateItemOperation';
import { DeleteItemOperation } from './DeleteItemOperation';
export class LhDynamoClient {
    dynamoDbClient;
    constructor(dynamoDbClient) {
        this.dynamoDbClient = dynamoDbClient;
    }
    static create = (dynamoDbClient) => {
        return new LhDynamoClient(dynamoDbClient || new DynamoDBClient({ region: 'us-east-1' }));
    };
    query = () => {
        return new QueryOperation(DynamoDBDocumentClient.from(this.dynamoDbClient));
    };
    put = () => {
        return new PutOperation(DynamoDBDocumentClient.from(this.dynamoDbClient));
    };
    scan = () => {
        return new ScanOperation(DynamoDBDocumentClient.from(this.dynamoDbClient));
    };
    update = () => {
        return new UpdateItemOperation(DynamoDBDocumentClient.from(this.dynamoDbClient));
    };
    delete = () => {
        return new DeleteItemOperation(DynamoDBDocumentClient.from(this.dynamoDbClient));
    };
    batchWriteItem = () => {
        return new BatchWriteItemOperation(DynamoDBDocumentClient.from(this.dynamoDbClient));
    };
}
