export class IndividualTestDtoToIndividualTestConverter {
    static create = () => {
        return new IndividualTestDtoToIndividualTestConverter();
    };
    toModel = (source) => {
        return {
            code: source.code,
            testId: source.testId,
            name: source.name,
        };
    };
}
