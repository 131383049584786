import { DatHowItWorksPublicControllerApi } from '@luminate/connect-api-client-ts/src/api';
import { DatHowItWorksContentConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class DatHowItWorksService {
    api;
    converter;
    constructor(api, converter) {
        this.api = api;
        this.converter = converter;
    }
    static create = (apiBaseUrl) => {
        return new DatHowItWorksService(new DatHowItWorksPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), DatHowItWorksContentConverter.create());
    };
    getDatHowItWorks = async () => {
        const response = await this.api.getHowItWorksContentByWhiteLabelDomainUsingGET();
        return this.converter.toModel(response.data);
    };
}
