import { DateUtilities } from '../DateUtilities';
export class InvitePatientRequestToInvitePatientRequestDtoConverter {
    static create = () => {
        return new InvitePatientRequestToInvitePatientRequestDtoConverter();
    };
    toModel = (source) => {
        return {
            labId: source.labId,
            firstName: source.firstName,
            middleName: source.middleName,
            lastName: source.lastName,
            email: source.email,
            dateOfBirth: DateUtilities.formatAsShortDate(source.dateOfBirth),
            gender: source.gender,
            lastFour: source.lastFour,
            phoneType: source.phoneType,
            phoneNumber: source.phoneNumber,
            address1: source.address1,
            address2: source.address2,
            city: source.city,
            state: source.state,
            zip: source.zip,
            force: source.force,
        };
    };
}
