export class Pagination {
    static DEFAULT_PAGE = 0;
    static DEFAULT_PAGE_SIZE = 50;
    static getPage(values, pageSize = Pagination.DEFAULT_PAGE_SIZE) {
        const currentPage = values.length <= pageSize ? 0 : Math.floor(values.length / pageSize);
        const nextPage = values.length === pageSize * (currentPage + 1) ? currentPage + 1 : currentPage;
        return { currentPage, nextPage, pageSize, hasMore: nextPage > currentPage };
    }
    static getNetNew(existing, potential, compareFunction) {
        return potential.filter((possible) => existing.findIndex((previous) => compareFunction(previous, possible)) === -1);
    }
    static async loadPage(existing, retrievalFunction, compareFunction, pageSize = Pagination.DEFAULT_PAGE_SIZE) {
        const page = Pagination.getPage(existing, pageSize);
        const newData = await retrievalFunction(page);
        const netNewData = Pagination.getNetNew(existing, newData, compareFunction);
        return [...existing, ...netNewData];
    }
}
