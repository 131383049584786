export class PushRegistryEntity {
    token;
    user_id;
    patient_id;
    registration_date;
    platform;
    enabled;
    static FIELDS = {
        TOKEN: 'token',
        USER_ID: 'user_id',
        PATIENT_ID: 'patient_id',
        REGISTRATION_DATE: 'registration_date',
        PLATFORM: 'platform',
        ENABLED: 'enabled',
    };
    static builder = () => {
        return new PushRegistryEntity();
    };
    withToken = (token) => {
        this.token = token;
        return this;
    };
    withUserId = (userId) => {
        this.user_id = userId;
        return this;
    };
    withPatientId = (patientId) => {
        this.patient_id = patientId;
        return this;
    };
    withRegistrationDate = (registrationDate) => {
        this.registration_date = registrationDate;
        return this;
    };
    withPlatform = (platform) => {
        this.platform = platform;
        return this;
    };
    withEnabled = (enabled) => {
        this.enabled = enabled;
        return this;
    };
}
