import { SessionPublicControllerApi, MobileSessionPublicControllerApi, ConfigControllerApi } from '@luminate/connect-api-client-ts';
import { AuthenticationException, ValidUsernameResponseType } from '../models';
import { AuthenticationApiFactory } from '../esl';
import { CreateUserRequestToSignupRequestConverter, MobileSessionDtoToMobileSessionConverter, SessionDtoToLabContextConverter, AdminAppConfigDTOToAdminAppConfigConverter, ValidatePasswordResponseDtoConverter, } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
import { JwtUtils } from './JwtUtils';
export class SessionService {
    sessionPublicApi;
    authenticationApi;
    mobileSessionPublicApi;
    adminAppConfigApi;
    createUserRequestConverter;
    mobileSessionDtoConverter;
    datSessionDtoConverter;
    validatePasswordResponseConverter;
    adminAppConfigConverter;
    logger;
    constructor(sessionPublicApi, authenticationApi, mobileSessionPublicApi, adminAppConfigApi, createUserRequestConverter, mobileSessionDtoConverter, datSessionDtoConverter, validatePasswordResponseConverter, adminAppConfigConverter, logger) {
        this.sessionPublicApi = sessionPublicApi;
        this.authenticationApi = authenticationApi;
        this.mobileSessionPublicApi = mobileSessionPublicApi;
        this.adminAppConfigApi = adminAppConfigApi;
        this.createUserRequestConverter = createUserRequestConverter;
        this.mobileSessionDtoConverter = mobileSessionDtoConverter;
        this.datSessionDtoConverter = datSessionDtoConverter;
        this.validatePasswordResponseConverter = validatePasswordResponseConverter;
        this.adminAppConfigConverter = adminAppConfigConverter;
        this.logger = logger;
    }
    static DEFAULT_TIME_OUT_INTERVAL = 480000;
    static DEFAULT_TIME_OUT_WARNING_INTERVAL = 120000;
    static USERNAME_VALIDATION_SUCCESS = 'success';
    static USERNAME_EXISTS = 'USERNAME_EXISTS';
    static INVALID_USERNAME = 'INVALID_USERNAME';
    static INVALID_PASSWORD = 'INVALID_PASSWORD';
    static USERNAME_ALREADY_USED_MSG = 'Username already in use.';
    static INVALID_PASSWORD_MSG = 'For security purposes we can not accept the password you have chosen.  Please select another password and try again.';
    static UNABLE_TO_CREATE_ACCOUNT_MSG = 'We were unable to create your account at this time.  Please try again later.';
    static DEFAULT_SIGN_IN_ALREADY_HAVE_ACCOUNT_HEADER = 'Already have a Patient Portal Account?';
    static DEFAULT_SIGN_IN_LOGIN_HEADER = 'Sign into my account';
    static create = (apiBaseUrl, authToken, logger) => {
        return new SessionService(new SessionPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), AuthenticationApiFactory.create(apiBaseUrl, undefined, logger), new MobileSessionPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), new ConfigControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), CreateUserRequestToSignupRequestConverter.create(), MobileSessionDtoToMobileSessionConverter.create(), SessionDtoToLabContextConverter.create(), ValidatePasswordResponseDtoConverter.create(), AdminAppConfigDTOToAdminAppConfigConverter.create(), logger || console);
    };
    /**
     * Retrieve the current session from the Luminate Connect Api.
     * @returns {Promise<Session>} The current session.
     */
    getSession = async () => {
        const response = await this.sessionPublicApi.getSessionContextUsingGET();
        return this.datSessionDtoConverter.toModel(response.data);
    };
    getMobileSession = async (labId) => {
        const response = await this.mobileSessionPublicApi.getMobileSessionContextUsingGET(labId);
        return this.mobileSessionDtoConverter.toModel(response.data);
    };
    getAdminAppConfigForLab = async (labId) => {
        const response = await this.adminAppConfigApi.getApplicationConfigurationUsingGET1(labId);
        return this.adminAppConfigConverter.toModel(response.data);
    };
    /**
     * Perform user authentication which generates the jwt token and is set as a cookie on the response.
     * @param {string} userName Name credential for the authentication attempt.
     * @param {string} password Password credential for the authentication attempt.
     * @param {string} captcha Captcha token used for Captcha protected authentication requests.
     * @returns {Promise<AuthData>} Authentication response.
     * @throws {AuthData} For authentication failures.
     */
    authenticate = async (userName, password, captcha) => {
        this.logger.info('Performing Authentication', { userName, password, captcha });
        try {
            const response = await this.authenticationApi.authenticate(userName, password, captcha);
            this.logger.info('Authentication Response', response);
            return {
                authData: JwtUtils.toAuthData(response.data?.jwtToken),
                idvPrompt: response.data?.idvPrompt,
            };
        }
        catch (e) {
            this.logger.error('Failed to authenticate', e);
            throw e;
        }
    };
    /**
     * Submit a request to retrieve the username for the given email address.
     * @param {string} email Email address associated with the account to be retrieved.
     */
    forgotUsername = async (email) => {
        await this.authenticationApi.forgotUsername(email);
    };
    /**
     * Submit a request to have an email sent with instructions for resetting an account password.
     * @param {string} userName Username associated with the account to have the password reset.
     */
    forgotPassword = async (userName) => {
        await this.authenticationApi.forgotPassword(userName);
    };
    /**
     * Create a new Patient user account.
     * @returns {string} JWT Token for the new user.
     */
    signup = async (createUserRequest) => {
        this.logger.info('Patient Registration', createUserRequest);
        try {
            const signupRequest = this.createUserRequestConverter.toModel(createUserRequest);
            this.logger.info('Signup Request', signupRequest);
            const response = await this.authenticationApi.signup(signupRequest);
            this.logger.info('Patient Registration Response', response);
            return {
                authData: JwtUtils.toAuthData(response.data?.jwtToken),
                idvPrompt: response.data?.idvPrompt,
            };
        }
        catch (ex) {
            this.logger.error('Patient Registration Failed', ex);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const errorDescription = ex.response.data.error_description;
            if (errorDescription === SessionService.INVALID_USERNAME) {
                this.logger.warn('Patient Username Already Used');
                throw new AuthenticationException(SessionService.USERNAME_ALREADY_USED_MSG, ex);
            }
            else if (errorDescription === SessionService.INVALID_PASSWORD) {
                this.logger.warn('Password fails security requirements.');
                throw new AuthenticationException(SessionService.INVALID_PASSWORD_MSG, ex);
            }
            else {
                this.logger.warn('Unable to create account.');
                throw new AuthenticationException(SessionService.UNABLE_TO_CREATE_ACCOUNT_MSG, ex);
            }
        }
    };
    isValidUsername = async (username) => {
        const response = await this.authenticationApi.validNewUsername(username);
        if (response !== undefined && response.status === SessionService.USERNAME_VALIDATION_SUCCESS) {
            return ValidUsernameResponseType.NOT_IN_USE;
        }
        else if (response !== undefined && response.error_description === SessionService.USERNAME_EXISTS) {
            return ValidUsernameResponseType.ALREADY_IN_USE;
        }
        else {
            return ValidUsernameResponseType.IN_VALID_FORMAT;
        }
    };
    isValidPassword = async (password) => {
        const response = await this.authenticationApi.validatePassword(password);
        return this.validatePasswordResponseConverter.toModel(response);
    };
    signout = async () => {
        await this.authenticationApi.signout();
    };
}
