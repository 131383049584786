import { DatHowItWorksStepConverter } from './DatHowItWorksStepConverter';
export class DatHowItWorksContentConverter {
    datHowItWorksStepConverter;
    constructor(datHowItWorksStepConverter) {
        this.datHowItWorksStepConverter = datHowItWorksStepConverter;
    }
    static create = () => {
        return new DatHowItWorksContentConverter(DatHowItWorksStepConverter.create());
    };
    toModel = (source) => {
        return {
            pageDescription: source.pageDescription,
            pageTitle: source.pageTitle,
            steps: source.steps ? source.steps.map((step) => this.datHowItWorksStepConverter.toModel(step)) : [],
        };
    };
}
