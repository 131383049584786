import { DatFastingPublicControllerApi } from '@luminate/connect-api-client-ts/src/api';
import { DatFastingContentConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class DatFastingService {
    api;
    converter;
    constructor(api, converter) {
        this.api = api;
        this.converter = converter;
    }
    static create = (apiBaseUrl) => {
        return new DatFastingService(new DatFastingPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), DatFastingContentConverter.create());
    };
    getDatFastingContent = async () => {
        const response = await this.api.getFastingContentByWhiteLabelDomainUsingGET();
        return this.converter.toModel(response.data);
    };
}
