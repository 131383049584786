import { AdminApiClientConfigFactory } from '../services';
export class PreferencesApi {
    apiBaseUrl;
    axiosInstance;
    constructor(apiBaseUrl, axiosInstance) {
        this.apiBaseUrl = apiBaseUrl;
        this.axiosInstance = axiosInstance;
    }
    static BASE_PATIENT_V2_PATH = '/api/v2/patient';
    static PREFERENCES_PATH = '/preferences';
    static create = (apiBaseUrl, authToken) => {
        return new PreferencesApi(apiBaseUrl, AdminApiClientConfigFactory.create(authToken));
    };
    updateUserPreferences = async (preferences) => {
        const urlPath = `${this.apiBaseUrl}${PreferencesApi.BASE_PATIENT_V2_PATH}${PreferencesApi.PREFERENCES_PATH}`;
        await this.axiosInstance.post(urlPath, JSON.stringify(Object.fromEntries(preferences)));
    };
}
