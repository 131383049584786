import { ActivityLogEntity, ActivityLogType } from './ActivityLogEntities';
export class AuthenticationCompletedActivityLogEntity extends ActivityLogEntity {
    providerId;
    userType;
    labEntityExternalId;
    userId;
    userName;
    patient_id_lab_external_id_activity_key;
    static FIELDS = {
        ...{
            LAB_ENTITY_EXTERNAL_ID: 'lab_entity_external_id',
            PROVIDER_ID: 'provider_id',
            USER_TYPE: 'user_type',
            USER_ID: 'user_id',
            USERNAME: 'username',
            PATIENT_EXTERNAL_LAB_ACTIVITY_KEY: 'patient_id_lab_external_id_activity_key',
        },
        ...ActivityLogEntity.FIELDS,
    };
    constructor(id, timestamp) {
        super(ActivityLogType.AUTHENTICATION_COMPLETED, id, timestamp);
    }
    withPatientIdLabEntityExternalIdActivityKey = (patient_id_lab_external_id_activity_key) => {
        this.patient_id_lab_external_id_activity_key = patient_id_lab_external_id_activity_key;
        return this;
    };
    withPatientId = (patientId) => {
        this.patientId = patientId;
        return this;
    };
    withProviderId = (providerId) => {
        this.providerId = providerId;
        return this;
    };
    withUserType = (userType) => {
        this.userType = userType;
        return this;
    };
    withUserId = (userId) => {
        this.userId = userId;
        return this;
    };
    withUserName = (userName) => {
        this.userName = userName;
        return this;
    };
    withLabEntityExternalId = (labEntityExternalId) => {
        this.labEntityExternalId = labEntityExternalId;
        return this;
    };
}
