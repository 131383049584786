import { LabLocationPublicControllerApi } from '@luminate/connect-api-client-ts';
import { LabLocationDtoToLabLocationConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class LabLocationService {
    labLocationPatientApi;
    locationConverter;
    constructor(labLocationPatientApi, locationConverter) {
        this.labLocationPatientApi = labLocationPatientApi;
        this.locationConverter = locationConverter;
    }
    static create = (apiBaseUrl) => {
        return new LabLocationService(new LabLocationPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), LabLocationDtoToLabLocationConverter.create());
    };
    getLocations = async (labId) => {
        const response = await this.labLocationPatientApi.getLabLocationsUsingGET1(labId);
        return response.data ? response.data.map((location) => this.locationConverter.toModel(location)) : [];
    };
}
