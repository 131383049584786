export class CartTestDtoToCartTestConverter {
    static create = () => {
        return new CartTestDtoToCartTestConverter();
    };
    toModel = (source) => {
        return {
            testId: source.testId,
            transactionId: source.transactionId,
            quantity: source.quantity,
            shortDescription: source.shortDescription,
            longDescription: source.longDescription,
            turnAroundTime: source.turnAroundTime,
            labId: source.labId,
            name: source.name,
            price: source.price,
            code: source.code,
        };
    };
}
