import { AdminLabServiceApi } from '../esl';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class LabService {
    adminLabServiceApi;
    constructor(adminLabServiceApi) {
        this.adminLabServiceApi = adminLabServiceApi;
    }
    static create = (apiBaseUrl, authToken) => {
        return new LabService(AdminLabServiceApi.create(apiBaseUrl, AdminApiClientConfigFactory.create(authToken)));
    };
    getExternalIds = async () => {
        const result = await this.adminLabServiceApi.getExternalLabIdMap();
        return Array.from(Object.entries(result.data)).map((item) => {
            return { id: parseInt(item[0]), externalId: item[1] };
        });
    };
    getLabs = async () => {
        const result = await this.adminLabServiceApi.getLabs();
        return Array.from(Object.entries(result.data)).map((item) => {
            item[1].id = parseInt(item[0]);
            return item[1];
        });
    };
}
