import { QueryCommand } from '@aws-sdk/client-dynamodb';
import { KeyConditionExpressionOperation } from './KeyConditionExpressionOperation';
export class QueryOperation extends KeyConditionExpressionOperation {
    constructor(dynamoDbDocClient) {
        super(dynamoDbDocClient, QueryCommand, {});
    }
    withLimit = (limit) => {
        this.spec.Limit = limit;
        return this;
    };
    withConsistentRead = (consistentRead) => {
        this.spec.ConsistentRead = consistentRead;
        return this;
    };
    withScanIndexForward = (scanIndexForward) => {
        this.spec.ScanIndexForward = scanIndexForward;
        return this;
    };
    withExclusiveStartKey = (startKey) => {
        this.spec.ExclusiveStartKey = startKey;
        return this;
    };
    withReturnConsumedCapacity = (returnConsumedCapacity) => {
        this.spec.ReturnConsumedCapacity = returnConsumedCapacity;
        return this;
    };
    withProjectionExpression = (projectionExpression) => {
        this.spec.ProjectionExpression = projectionExpression;
        return this;
    };
    withSelect = (select) => {
        this.spec.Select = select;
        return this;
    };
}
