import { ItemBuilder } from '../../ItemBuilder';
import { PushRegistryEntity } from '../PushRegisteryEntity';
export class PushRegistryEntityConverter {
    static create = () => {
        return new PushRegistryEntityConverter();
    };
    toEntity = (source) => {
        return ItemBuilder.builder()
            .with(PushRegistryEntity.FIELDS.TOKEN, source.token)
            .with(PushRegistryEntity.FIELDS.USER_ID, source.user_id)
            .with(PushRegistryEntity.FIELDS.PATIENT_ID, source.patient_id)
            .with(PushRegistryEntity.FIELDS.REGISTRATION_DATE, source.registration_date)
            .with(PushRegistryEntity.FIELDS.PLATFORM, source.platform)
            .with(PushRegistryEntity.FIELDS.ENABLED, source.enabled)
            .build();
    };
    toModel = (source) => {
        return {
            token: source[PushRegistryEntity.FIELDS.TOKEN].S,
            user_id: source[PushRegistryEntity.FIELDS.USER_ID].S,
            patient_id: source[PushRegistryEntity.FIELDS.PATIENT_ID].S,
            registration_date: source[PushRegistryEntity.FIELDS.REGISTRATION_DATE].S,
            platform: source[PushRegistryEntity.FIELDS.PLATFORM].S,
            enabled: source[PushRegistryEntity.FIELDS.ENABLED].BOOL,
        };
    };
}
