import { DateUtilities } from '../DateUtilities';
export class CreateUserRequestToSignupRequestConverter {
    static create = () => {
        return new CreateUserRequestToSignupRequestConverter();
    };
    toModel = (source) => {
        return {
            firstName: source.firstName,
            middleName: source.middleName,
            lastName: source.lastName,
            birthdate: source.dateOfBirth ? DateUtilities.formatYYYYMMDD(source.dateOfBirth) : undefined,
            email: source.email,
            username: source.userName,
            password: source.password,
            termsAgreed: source.agreeToTerms,
            guardianRequest: source.guardianRequest ? source.guardianRequest : false,
            guardianFirstName: source.guardianFirstName,
            guardianLastName: source.guardianLastName,
            guardianBirthdate: source.guardianDateOfBirth ? DateUtilities.formatYYYYMMDD(source.guardianDateOfBirth) : undefined,
            gender: source.gender,
            ssn4: source.ssn4,
            phone: source.phone,
            phoneType: source.phoneType,
            address: source.address1,
            address2: source.address2,
            city: source.city,
            state: source.state,
            zipCode: source.zip,
        };
    };
}
