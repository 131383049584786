import { ExpressionNamesAndValuesOperation } from './ExpressionNamesAndValuesOperation';
export class IndexOperation extends ExpressionNamesAndValuesOperation {
    constructor(dynamoDbDocClient, commandType, spec) {
        super(dynamoDbDocClient, commandType, spec);
    }
    withIndexName = (indexName) => {
        this.spec.IndexName = indexName;
        return this;
    };
}
