import { AdminAppEvent, ExpoAdminAppEvent, VueAdminAppEvent } from './AdminAppEvents';
export const DAT_ORDER_REFUND_START_EVENT_NAME = 'dat-order-refund-start';
export const DAT_ORDER_REFUND_END_EVENT_NAME = 'dat-order-refund-end';
export class StartDatOrderRefundEvent extends ExpoAdminAppEvent {
    constructor(orderId, orderNumber, patientFirstName, patientLastName, patientEmail, total, nonRefundableFee) {
        super({
            eventName: DAT_ORDER_REFUND_START_EVENT_NAME,
            orderId,
            orderNumber,
            patientFirstName,
            patientLastName,
            patientEmail,
            total,
            nonRefundableFee,
        });
    }
    static isInstanceOf = (object) => {
        return AdminAppEvent.isInstanceOf(object, DAT_ORDER_REFUND_START_EVENT_NAME);
    };
}
export class EndDatOrderRefundEvent extends VueAdminAppEvent {
    constructor() {
        super({ eventName: DAT_ORDER_REFUND_END_EVENT_NAME });
    }
    static isInstanceOf = (object) => {
        return AdminAppEvent.isInstanceOf(object, DAT_ORDER_REFUND_END_EVENT_NAME);
    };
}
