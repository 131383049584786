import { isAxiosError } from 'axios';
import { ActivityLogType, PatientProfileApiFactory } from '../esl';
import { DuplicatePatientException, LuminateRuntimeException } from '../models';
import { InvitePatientRequestToInvitePatientRequestDtoConverter, InvitePatientResponseDtoToInvitePatientResponseConverter, PatientDtoToPatientProfileConverter, PatientLinksDtoToPatientLinksConverter, PatientUnlinkRequestToPatientUnlinkRequestDtoConverter, } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
import { ActivityLogService } from './ActivityLogService';
export class PatientProfileService {
    patientProfileApi;
    patientDtoConverter;
    patientLinksDtoConverter;
    invitePatientRequestToInvitePatientRequestDtoConverter;
    invitePatientResponseDtoToInvitePatientResponseConverter;
    patientUnlinkRequestToPatientUnlinkRequestDtoConverter;
    activityLogService;
    constructor(patientProfileApi, patientDtoConverter, patientLinksDtoConverter, invitePatientRequestToInvitePatientRequestDtoConverter, invitePatientResponseDtoToInvitePatientResponseConverter, patientUnlinkRequestToPatientUnlinkRequestDtoConverter, activityLogService) {
        this.patientProfileApi = patientProfileApi;
        this.patientDtoConverter = patientDtoConverter;
        this.patientLinksDtoConverter = patientLinksDtoConverter;
        this.invitePatientRequestToInvitePatientRequestDtoConverter = invitePatientRequestToInvitePatientRequestDtoConverter;
        this.invitePatientResponseDtoToInvitePatientResponseConverter = invitePatientResponseDtoToInvitePatientResponseConverter;
        this.patientUnlinkRequestToPatientUnlinkRequestDtoConverter = patientUnlinkRequestToPatientUnlinkRequestDtoConverter;
        this.activityLogService = activityLogService;
    }
    static create = (apiBaseUrl, authToken, patientProfileApi, patientDtoConverter, patientLinksDtoConverter, invitePatientRequestToInvitePatientRequestDtoConverter, invitePatientResponseDtoToInvitePatientResponseConverter, patientUnlinkRequestToPatientUnlinkRequestDtoConverter, activityLogService) => {
        return new PatientProfileService(patientProfileApi || PatientProfileApiFactory.create(apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), patientDtoConverter || PatientDtoToPatientProfileConverter.create(), patientLinksDtoConverter || PatientLinksDtoToPatientLinksConverter.create(), invitePatientRequestToInvitePatientRequestDtoConverter || InvitePatientRequestToInvitePatientRequestDtoConverter.create(), invitePatientResponseDtoToInvitePatientResponseConverter || InvitePatientResponseDtoToInvitePatientResponseConverter.create(), patientUnlinkRequestToPatientUnlinkRequestDtoConverter || PatientUnlinkRequestToPatientUnlinkRequestDtoConverter.create(), activityLogService || ActivityLogService.create());
    };
    getProfile = async () => {
        const response = await this.patientProfileApi.getProfile();
        return this.patientDtoConverter.toModel(response.data);
    };
    updateProfile = async (address1, city, state, zip, phone, phoneType, address2) => {
        const response = await this.patientProfileApi.updateProfile(address1, city, state, zip, phone, phoneType, address2);
        return this.patientDtoConverter.toModel(response.data);
    };
    changeEmailAddress = async (email, confirmEmail, password) => {
        const response = await this.patientProfileApi.changeEmailAddress(email, confirmEmail, password);
        return this.patientDtoConverter.toModel(response.data);
    };
    updateGender = async (gender) => {
        const response = await this.patientProfileApi.updateGender(gender);
        return this.patientDtoConverter.toModel(response.data);
    };
    invitePatient = async (invite) => {
        try {
            const response = await this.patientProfileApi.invitePatient(this.invitePatientRequestToInvitePatientRequestDtoConverter.toModel(invite));
            return this.invitePatientResponseDtoToInvitePatientResponseConverter.toModel(response.data);
        }
        catch (ex) {
            if (isAxiosError(ex)) {
                const axiosError = ex;
                if (axiosError.response !== undefined && axiosError.response.status === 409) {
                    throw new DuplicatePatientException(axiosError.response.data, 'Patient already exists.  Inspect duplicates attached.', ex);
                }
            }
            throw new LuminateRuntimeException('Unknown error inviting patient', ex);
        }
    };
    sendRegistrationRequest = async (patientId) => {
        try {
            await this.patientProfileApi.sendRegistrationRequest(patientId);
        }
        catch (ex) {
            throw new LuminateRuntimeException('Error sennding Registration Request', ex);
        }
    };
    getLinks = async (patientId) => {
        const response = await this.patientProfileApi.getLinks(patientId);
        return response.data.map((linkDto) => this.patientLinksDtoConverter.toModel(linkDto));
    };
    unlinkPatient = async (minorPatientId, parentPatientId) => {
        try {
            const response = await this.patientProfileApi.unlinkPatient(minorPatientId, parentPatientId);
            return this.patientUnlinkRequestToPatientUnlinkRequestDtoConverter.toModel(response.data);
        }
        catch (ex) {
            throw new LuminateRuntimeException('Unknown error unlinking patients', ex);
        }
    };
    getLastLoginDate = async (patientId, externalLabId) => {
        const lastAuthenticationCompletedLog = await this.activityLogService.getLatestActivityLogByPatientIdAndLabExternalIdAndActivityKey(patientId.toString(), externalLabId, ActivityLogType.AUTHENTICATION_COMPLETED);
        return lastAuthenticationCompletedLog?.timestamp;
    };
}
