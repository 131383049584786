import assert from 'assert';
import { ConditionExpressionBuilder } from './ConditionExpressionBuilder';
import { ExpressionAttributeNamesBuilder } from './ExpressionAttributeNamesBuilder';
import { ExpressionAttributeValuesBuilder } from './ExpressionAttributeValuesBuilder';
import { ExpressionNamesAndValuesOperation } from './ExpressionNamesAndValuesOperation';
export class ConditionExpressionOperation extends ExpressionNamesAndValuesOperation {
    static WITH_CONDITION_ASSERTION_MSG = 'withCondition can only be specified if a filter had not already been set.  See andCondition or orCondition to extend an existing filter.';
    static AND_CONDITION_ASSERTION_MSG = 'andCondition can only be specified if a filter has already been set.  See withCondition to set the initial filter expression.';
    static OR_CONDITION_ASSERTION_MSG = 'orCondition can only be specified if a filter has already been set.  See withCondition to set the initial filter expression.';
    constructor(dynamoDbDocClient, commandType, spec) {
        super(dynamoDbDocClient, commandType, spec);
    }
    withConditionExpression = (conditionExpression) => {
        this.spec.ConditionExpression = conditionExpression;
        return this;
    };
    withCondition = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        assert(!this.spec.ConditionExpression, ConditionExpressionOperation.WITH_CONDITION_ASSERTION_MSG);
        this.spec.ConditionExpression = conditionExpression;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    andCondition = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        assert(this.spec.ConditionExpression, ConditionExpressionOperation.AND_CONDITION_ASSERTION_MSG);
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        this.spec.ConditionExpression = `${this.spec.ConditionExpression} AND ${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    orCondition = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        assert(this.spec.ConditionExpression, ConditionExpressionOperation.OR_CONDITION_ASSERTION_MSG);
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        this.spec.ConditionExpression = `${this.spec.ConditionExpression} OR ${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
}
