import { ComparisonOperator, LhDynamoClient, PushRegistryEntity, PushRegistryEntityConverter } from '..';
import { DynamoRepository } from './DynamoRepository';
export class PushRegistrarRepository extends DynamoRepository {
    pushRegistryEntityConverter;
    static TABLE_NAME = 'push-registrar';
    static PATIENT_ID_INDEX = 'patient_id_index';
    constructor(dbClient, environment = process.env.ENV, pushRegistryEntityConverter) {
        super(dbClient, `lh-${environment}-${PushRegistrarRepository.TABLE_NAME}`);
        this.pushRegistryEntityConverter = pushRegistryEntityConverter;
    }
    static create = (dbClient, environment, pushRegistryEntityConverter) => {
        return new PushRegistrarRepository(dbClient || LhDynamoClient.create(), environment, pushRegistryEntityConverter || PushRegistryEntityConverter.create());
    };
    findByPatientId = async (patientId) => {
        const result = await this.query()
            .withIndexName(PushRegistrarRepository.PATIENT_ID_INDEX)
            .withKeyCondition(PushRegistryEntity.FIELDS.PATIENT_ID, ComparisonOperator.Equals, patientId.toString())
            .execute();
        return result.Items?.map((entity) => this.pushRegistryEntityConverter.toModel(entity)) || [];
    };
    setEnabled = async (token, enabled) => {
        return await this.updateItemById(token, PushRegistryEntity.FIELDS.TOKEN).withUpdate(PushRegistryEntity.FIELDS.ENABLED, enabled).execute();
    };
    register = async (token, platform, userId, patientId) => {
        const entity = PushRegistryEntity.builder()
            .withToken(token)
            .withPlatform(platform)
            .withUserId(userId)
            .withPatientId(patientId)
            .withEnabled(true)
            .withRegistrationDate(new Date().toISOString());
        await this.saveEntity(entity).execute();
        return entity;
    };
}
