export class ValidatePasswordResponseDtoConverter {
    static VALIDATE_PASSWORD_RESPONSE_ERROR = 'error';
    static create = () => {
        return new ValidatePasswordResponseDtoConverter();
    };
    toModel = (source) => {
        return {
            isValid: source.status !== ValidatePasswordResponseDtoConverter.VALIDATE_PASSWORD_RESPONSE_ERROR,
            errorMsg: source.error_description,
        };
    };
}
