export class WheelFollowUpDetailsDtoToWheelFollowUpDetailsConverter {
    static create = () => {
        return new WheelFollowUpDetailsDtoToWheelFollowUpDetailsConverter();
    };
    toModel = (source) => {
        return {
            connected: source.connected,
            interpretation: source.interpretation,
            patientContactRequired: source.patientContactRequired,
        };
    };
}
