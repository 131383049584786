import { ActivityLogEntity, AuthenticationCompletedActivityLogEntity, ItemBuilder } from '../../esl';
import { DateUtilities } from '../DateUtilities';
import { EnumUtils } from '../EnumUtils';
import { UserType, ConversionException } from '../../models';
export class AuthenticationCompletedActivityLogEntityConverter {
    static MODEL_CONVERSION_ERROR_MSG = 'Unable to convert entity to AuthenticationCompletedActivityLogEntity. Entity missing id or timestamp.';
    toModel = (source) => {
        if (!source[ActivityLogEntity.FIELDS.ID]?.S || !source[ActivityLogEntity.FIELDS.TIMESTAMP]?.S) {
            throw new ConversionException(AuthenticationCompletedActivityLogEntityConverter.MODEL_CONVERSION_ERROR_MSG, source);
        }
        return ActivityLogEntity.from(AuthenticationCompletedActivityLogEntity, source[ActivityLogEntity.FIELDS.ID].S, DateUtilities.fromString(source[ActivityLogEntity.FIELDS.TIMESTAMP].S))
            .withPatientId(source[ActivityLogEntity.FIELDS.PATIENT_ID]?.S)
            .withLabEntityExternalId(source[AuthenticationCompletedActivityLogEntity.FIELDS.LAB_ENTITY_EXTERNAL_ID]?.S)
            .withProviderId(source[AuthenticationCompletedActivityLogEntity.FIELDS.PROVIDER_ID]?.S)
            .withUserType(EnumUtils.getByValue(UserType, source[AuthenticationCompletedActivityLogEntity.FIELDS.USER_TYPE]?.S))
            .withUserId(source[AuthenticationCompletedActivityLogEntity.FIELDS.USER_ID]?.S)
            .withUserName(source[AuthenticationCompletedActivityLogEntity.FIELDS.USERNAME]?.S)
            .withPatientIdLabEntityExternalIdActivityKey(source[AuthenticationCompletedActivityLogEntity.FIELDS.PATIENT_EXTERNAL_LAB_ACTIVITY_KEY]?.S);
    };
    toEntity = (source) => {
        return ItemBuilder.builder()
            .withString(ActivityLogEntity.FIELDS.ID, source.id)
            .withString(ActivityLogEntity.FIELDS.ACTIVITY_KEY, source.activityLogType)
            .withString(ActivityLogEntity.FIELDS.TIMESTAMP, source.timestamp.toISOString())
            .withString(AuthenticationCompletedActivityLogEntity.FIELDS.PATIENT_ID, source.patientId)
            .withString(AuthenticationCompletedActivityLogEntity.FIELDS.LAB_ENTITY_EXTERNAL_ID, source.labEntityExternalId)
            .withString(AuthenticationCompletedActivityLogEntity.FIELDS.PROVIDER_ID, source.providerId)
            .withString(AuthenticationCompletedActivityLogEntity.FIELDS.USER_TYPE, source.userType)
            .withString(AuthenticationCompletedActivityLogEntity.FIELDS.USER_ID, source.userId)
            .withString(AuthenticationCompletedActivityLogEntity.FIELDS.USERNAME, source.userName)
            .withString(AuthenticationCompletedActivityLogEntity.FIELDS.PATIENT_EXTERNAL_LAB_ACTIVITY_KEY, source.patient_id_lab_external_id_activity_key || `${source.patientId}:${source.labEntityExternalId}:${source.activityLogType}`)
            .build();
    };
}
