export class DatHowItWorksStepPartConverter {
    static create = () => {
        return new DatHowItWorksStepPartConverter();
    };
    toModel = (source) => {
        return {
            description: source.description,
            imgPath: source.imgPath,
            title: source.title,
        };
    };
}
