import { OrderStatusNotificationType } from '../../models';
import { OrderDtoToOrderConverter } from './OrderDtoToOrderConverter';
export class OrderStatusNotificationDtoToOrderStatusNotificationConverter {
    orderDtoConverter;
    constructor(orderDtoConverter) {
        this.orderDtoConverter = orderDtoConverter;
    }
    static create = () => {
        return new OrderStatusNotificationDtoToOrderStatusNotificationConverter(OrderDtoToOrderConverter.create());
    };
    toModel = (source) => {
        return {
            status: OrderStatusNotificationType[source.status],
            order: this.orderDtoConverter.toModel(source.order),
        };
    };
}
