import { ConfigsRepository } from '../../repository';
export class ConfigConverter {
    static create = () => {
        return new ConfigConverter();
    };
    toModel = (source) => {
        const config = {};
        source.forEach((item) => {
            const category = item[ConfigsRepository.FIELD_CATEGORY].S;
            const field = item[ConfigsRepository.FIELD_CONFIG_NAME].S;
            if (!Object.keys(config).includes(category)) {
                config[category] = {};
            }
            config[category][field] = this.getItemValue(item);
        });
        return config;
    };
    getItemValue = (item) => {
        return (item[ConfigsRepository.FIELD_CONFIG_VALUE].BOOL ||
            item[ConfigsRepository.FIELD_CONFIG_VALUE].S ||
            item[ConfigsRepository.FIELD_CONFIG_VALUE].N ||
            item[ConfigsRepository.FIELD_CONFIG_VALUE].M);
    };
}
