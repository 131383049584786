import { DeleteItemCommand, } from '@aws-sdk/client-dynamodb';
import { ConditionExpressionOperation } from './ConditionExpressionOperation';
export class DeleteItemOperation extends ConditionExpressionOperation {
    constructor(dynamoDbDocClient) {
        super(dynamoDbDocClient, DeleteItemCommand, {});
    }
    withKey = (Key) => {
        this.spec.Key = Key;
        return this;
    };
    withReturnValues = (ReturnValues) => {
        this.spec.ReturnValues = ReturnValues;
        return this;
    };
    withReturnConsumedCapacity = (ReturnConsumedCapacity) => {
        this.spec.ReturnConsumedCapacity = ReturnConsumedCapacity;
        return this;
    };
    withReturnItemCollectionMetrics = (ReturnItemCollectionMetrics) => {
        this.spec.ReturnItemCollectionMetrics = ReturnItemCollectionMetrics;
        return this;
    };
    withReturnValuesOnConditionCheckFailure = (ReturnValuesOnConditionCheckFailure) => {
        this.spec.ReturnValuesOnConditionCheckFailure = ReturnValuesOnConditionCheckFailure;
        return this;
    };
}
