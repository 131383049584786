import { TableOperation } from './TableOperation';
export class ExpressionNamesAndValuesOperation extends TableOperation {
    constructor(dynamoDbDocClient, commandType, spec) {
        super(dynamoDbDocClient, commandType, spec);
    }
    withExpressionAttributeNames = (expressionAttributeNames) => {
        this.spec.ExpressionAttributeNames = expressionAttributeNames;
        return this;
    };
    withExpressionAttributeValues = (expressionAttributeValues) => {
        this.spec.ExpressionAttributeValues = expressionAttributeValues;
        return this;
    };
    addExpressionNames = (names) => {
        this.spec.ExpressionAttributeNames = this.spec.ExpressionAttributeNames ? { ...this.spec.ExpressionAttributeNames, ...names } : names;
    };
    addExpressionValues = (values) => {
        this.spec.ExpressionAttributeValues = this.spec.ExpressionAttributeValues ? { ...this.spec.ExpressionAttributeValues, ...values } : values;
    };
}
