import { AdminAppEvent, ExpoAdminAppEvent, VueAdminAppEvent } from './AdminAppEvents';
export const PATIENT_LINK_START_EVENT_NAME = 'patient-link-start';
export const PATIENT_LINK_FINISH_EVENT_NAME = 'patient-link-finish';
export class StartPatientLinkEvent extends ExpoAdminAppEvent {
    constructor(patient) {
        super({ eventName: PATIENT_LINK_START_EVENT_NAME, data: patient });
    }
    static isInstanceOf = (object) => {
        return AdminAppEvent.isInstanceOf(object, PATIENT_LINK_START_EVENT_NAME);
    };
}
export class FinishPatientLinkEvent extends VueAdminAppEvent {
    constructor() {
        super({ eventName: PATIENT_LINK_FINISH_EVENT_NAME });
    }
    static isInstanceOf = (object) => {
        return AdminAppEvent.isInstanceOf(object, PATIENT_LINK_FINISH_EVENT_NAME);
    };
}
