import { PreferencesApi } from '../esl';
export class PreferencesService {
    preferencesApi;
    constructor(preferencesApi) {
        this.preferencesApi = preferencesApi;
    }
    static create = (apiBaseUrl, authToken) => {
        return new PreferencesService(PreferencesApi.create(apiBaseUrl, authToken));
    };
    updateUserPreferences = async (selectedPreferences) => {
        await this.preferencesApi.updateUserPreferences(selectedPreferences);
    };
}
