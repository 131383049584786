export class LabEntityDtoToLabConverter {
    static create = () => {
        return new LabEntityDtoToLabConverter();
    };
    toModel = (source) => {
        return {
            code: source.code,
            id: source.id,
            name: source.name,
            address1: source.address1,
            address2: source.address2,
            city: source.city,
            state: source.state,
            zip: source.zip,
            phone: source.phone,
        };
    };
}
