export class FaqItemDtoToFaqConverter {
    static create = () => {
        return new FaqItemDtoToFaqConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            question: source.question,
            answer: source.answer,
        };
    };
}
