import { DatHowItWorksStepPartConverter } from './DatHowItWorksStepPartConverter';
export class DatHowItWorksStepConverter {
    datHowItWorksStepPartConverter;
    constructor(datHowItWorksStepPartConverter) {
        this.datHowItWorksStepPartConverter = datHowItWorksStepPartConverter;
    }
    static create = () => {
        return new DatHowItWorksStepConverter(DatHowItWorksStepPartConverter.create());
    };
    toModel = (source) => {
        return {
            detailsPart: source.detailsPart ? this.datHowItWorksStepPartConverter.toModel(source.detailsPart) : undefined,
            shortPart: source.shortPart ? this.datHowItWorksStepPartConverter.toModel(source.shortPart) : undefined,
        };
    };
}
