import { Operation } from './Operation';
export class TableOperation extends Operation {
    constructor(dynamoDbDocClient, commandType, spec) {
        super(dynamoDbDocClient, commandType, spec);
    }
    withTableName = (tableName) => {
        this.spec.TableName = tableName;
        return this;
    };
}
