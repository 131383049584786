export var AccessionViewType;
(function (AccessionViewType) {
    AccessionViewType["PDF"] = "pdf";
    AccessionViewType["PAGE"] = "page";
    AccessionViewType["READ"] = "read";
    AccessionViewType["RELEASE"] = "release";
    AccessionViewType["MOBILE"] = "mobile";
})(AccessionViewType || (AccessionViewType = {}));
export const LabResultsReviewedTypes = [AccessionViewType.PAGE, AccessionViewType.PDF, AccessionViewType.MOBILE];
export class AccessionViewEntity {
    static FIELDS = {
        PATIENT_ID: 'patient_id',
        USER_ID: 'user_id',
        EXTERNAL_LAB_ID: 'lab_entity_external_id',
        TYPE: 'type',
        ACCESSION: 'accession',
        TIMESTAMP: 'timestamp',
    };
    patientId;
    userId;
    externalLabId;
    type;
    accession;
    timestamp;
}
