export class PatientLinksDtoToPatientLinksConverter {
    static create = () => {
        return new PatientLinksDtoToPatientLinksConverter();
    };
    toModel = (source) => {
        return {
            firstName: source.firstName,
            lastName: source.lastName,
            dob: source.dob,
            email: source.email,
            expirationDate: source.expirationDate,
            patientId: source.patientId,
            identities: source.identities,
        };
    };
}
