import { LuminateRuntimeException } from './LuminateRuntimeException';
export class DuplicatePatientException extends LuminateRuntimeException {
    static EXCEPTION_NAME = 'DuplicatePatientException';
    static DUPLICATE_PATIENT_STATUS_CODE = 409;
    duplicatePatients;
    constructor(duplicatePatients, message, cause, stack) {
        super(message, cause, stack, DuplicatePatientException.EXCEPTION_NAME, cause?.status || DuplicatePatientException.DUPLICATE_PATIENT_STATUS_CODE);
        this.duplicatePatients = duplicatePatients;
    }
}
