export class PatientUnlinkRequestToPatientUnlinkRequestDtoConverter {
    static create = () => {
        return new PatientUnlinkRequestToPatientUnlinkRequestDtoConverter();
    };
    toModel = (source) => {
        return {
            minorPatientId: source.minorPatientId,
            parentPatientId: source.parentPatientId,
        };
    };
}
