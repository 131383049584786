import { IdvApi } from '../esl';
export class IdvService {
    idvApi;
    constructor(idvApi) {
        this.idvApi = idvApi;
    }
    static create = (apiBaseUrl, authToken) => {
        return new IdvService(IdvApi.create(apiBaseUrl, authToken));
    };
    getIdvSessionState = async (idvSessionId) => {
        const response = await this.idvApi.getIdvSessionState(idvSessionId);
        return response.data;
    };
    submitAnswer = async (idvSessionId, question, submission) => {
        try {
            const response = await this.idvApi.submitAnswer(idvSessionId, question, submission);
            return response.data;
        }
        catch (ex) {
            console.error('Submission Error', ex);
            throw ex;
        }
    };
    reset = async (patientId) => {
        await this.idvApi.reset(patientId);
    };
}
