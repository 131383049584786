import { ConditionalOperator } from '@aws-sdk/client-dynamodb';
import { ComparisonOperator } from './ComparisonOperator';
export class ConditionExpressionBuilder {
    expressions = new Array();
    static DEFAULT_KEY_PREFIX = '#';
    static DEFAULT_VALUE_PREFIX = ':';
    keyPrefix = ConditionExpressionBuilder.DEFAULT_KEY_PREFIX;
    valuePrefix = ConditionExpressionBuilder.DEFAULT_VALUE_PREFIX;
    static builder = () => {
        return new ConditionExpressionBuilder();
    };
    generateKeyName = (attributeKeyName) => {
        return `${this.keyPrefix}${attributeKeyName}`;
    };
    generateValueName = (attributeKeyName, attributeValueName) => {
        return `${this.valuePrefix}${attributeValueName ? attributeValueName : attributeKeyName}`;
    };
    withKeyPrefix = (keyPrefix) => {
        this.keyPrefix = keyPrefix;
        return this;
    };
    withValuePrefix = (valuePrefix) => {
        this.valuePrefix = valuePrefix;
        return this;
    };
    withCondition = (attributeKeyName, comparator, attributeValueName) => {
        this.expressions.push(`${this.generateKeyName(attributeKeyName)} ${comparator} ${this.generateValueName(attributeKeyName, attributeValueName)}`);
        return this;
    };
    whereIn = (attributeKeyName, values, attributeValueName) => {
        const attributeValueNameExpression = values
            ?.map((value, index) => `${this.generateValueName(attributeKeyName, attributeValueName)}${index}`)
            .join(',');
        this.expressions.push(`${this.generateKeyName(attributeKeyName)} ${ComparisonOperator.In} ( ${attributeValueNameExpression} )`);
        return this;
    };
    equals = (attributeKeyName, attributeValueName) => {
        return this.withCondition(attributeKeyName, ComparisonOperator.Equals, attributeValueName);
    };
    doesNotEqual = (attributeKeyName, attributeValueName) => {
        return this.withCondition(attributeKeyName, ComparisonOperator.DoesNotEqual, attributeValueName);
    };
    lessThan = (attributeKeyName, attributeValueName) => {
        return this.withCondition(attributeKeyName, ComparisonOperator.LessThan, attributeValueName);
    };
    lessThanOrEquals = (attributeKeyName, attributeValueName) => {
        return this.withCondition(attributeKeyName, ComparisonOperator.LessThanOrEquals, attributeValueName);
    };
    greaterThan = (attributeKeyName, attributeValueName) => {
        return this.withCondition(attributeKeyName, ComparisonOperator.GreaterThan, attributeValueName);
    };
    greaterThanOrEquals = (attributeKeyName, attributeValueName) => {
        return this.withCondition(attributeKeyName, ComparisonOperator.GreaterThanOrEquals, attributeValueName);
    };
    and = () => {
        this.expressions.push(ConditionalOperator.AND);
        return this;
    };
    or = () => {
        this.expressions.push(ConditionalOperator.OR);
        return this;
    };
    build = () => {
        return this.expressions.join(' ');
    };
}
