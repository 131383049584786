import { _toNum, _toStr, loadRestJsonErrorCode, parseJsonBody as parseBody, parseJsonErrorBody as parseErrorBody, } from "@aws-sdk/core";
import { HttpRequest as __HttpRequest } from "@smithy/protocol-http";
import { _json, collectBody, decorateServiceException as __decorateServiceException, expectString as __expectString, take, withBaseException, } from "@smithy/smithy-client";
import { BatchEntryIdsNotDistinct, BatchRequestTooLong, EmptyBatchRequest, InvalidAddress, InvalidAttributeName, InvalidAttributeValue, InvalidBatchEntryId, InvalidIdFormat, InvalidMessageContents, InvalidSecurity, KmsAccessDenied, KmsDisabled, KmsInvalidKeyUsage, KmsInvalidState, KmsNotFound, KmsOptInRequired, KmsThrottled, MessageNotInflight, OverLimit, PurgeQueueInProgress, QueueDeletedRecently, QueueDoesNotExist, QueueNameExists, ReceiptHandleIsInvalid, RequestThrottled, ResourceNotFoundException, TooManyEntriesInBatchRequest, UnsupportedOperation, } from "../models/models_0";
import { SQSServiceException as __BaseException } from "../models/SQSServiceException";
export const se_AddPermissionCommand = async (input, context) => {
    const headers = sharedHeaders("AddPermission");
    let body;
    body = JSON.stringify(se_AddPermissionRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CancelMessageMoveTaskCommand = async (input, context) => {
    const headers = sharedHeaders("CancelMessageMoveTask");
    let body;
    body = JSON.stringify(se_CancelMessageMoveTaskRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ChangeMessageVisibilityCommand = async (input, context) => {
    const headers = sharedHeaders("ChangeMessageVisibility");
    let body;
    body = JSON.stringify(se_ChangeMessageVisibilityRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ChangeMessageVisibilityBatchCommand = async (input, context) => {
    const headers = sharedHeaders("ChangeMessageVisibilityBatch");
    let body;
    body = JSON.stringify(se_ChangeMessageVisibilityBatchRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateQueueCommand = async (input, context) => {
    const headers = sharedHeaders("CreateQueue");
    let body;
    body = JSON.stringify(se_CreateQueueRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteMessageCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteMessage");
    let body;
    body = JSON.stringify(se_DeleteMessageRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteMessageBatchCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteMessageBatch");
    let body;
    body = JSON.stringify(se_DeleteMessageBatchRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteQueueCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteQueue");
    let body;
    body = JSON.stringify(se_DeleteQueueRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetQueueAttributesCommand = async (input, context) => {
    const headers = sharedHeaders("GetQueueAttributes");
    let body;
    body = JSON.stringify(se_GetQueueAttributesRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetQueueUrlCommand = async (input, context) => {
    const headers = sharedHeaders("GetQueueUrl");
    let body;
    body = JSON.stringify(se_GetQueueUrlRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListDeadLetterSourceQueuesCommand = async (input, context) => {
    const headers = sharedHeaders("ListDeadLetterSourceQueues");
    let body;
    body = JSON.stringify(se_ListDeadLetterSourceQueuesRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListMessageMoveTasksCommand = async (input, context) => {
    const headers = sharedHeaders("ListMessageMoveTasks");
    let body;
    body = JSON.stringify(se_ListMessageMoveTasksRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListQueuesCommand = async (input, context) => {
    const headers = sharedHeaders("ListQueues");
    let body;
    body = JSON.stringify(se_ListQueuesRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListQueueTagsCommand = async (input, context) => {
    const headers = sharedHeaders("ListQueueTags");
    let body;
    body = JSON.stringify(se_ListQueueTagsRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_PurgeQueueCommand = async (input, context) => {
    const headers = sharedHeaders("PurgeQueue");
    let body;
    body = JSON.stringify(se_PurgeQueueRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ReceiveMessageCommand = async (input, context) => {
    const headers = sharedHeaders("ReceiveMessage");
    let body;
    body = JSON.stringify(se_ReceiveMessageRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_RemovePermissionCommand = async (input, context) => {
    const headers = sharedHeaders("RemovePermission");
    let body;
    body = JSON.stringify(se_RemovePermissionRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SendMessageCommand = async (input, context) => {
    const headers = sharedHeaders("SendMessage");
    let body;
    body = JSON.stringify(se_SendMessageRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SendMessageBatchCommand = async (input, context) => {
    const headers = sharedHeaders("SendMessageBatch");
    let body;
    body = JSON.stringify(se_SendMessageBatchRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SetQueueAttributesCommand = async (input, context) => {
    const headers = sharedHeaders("SetQueueAttributes");
    let body;
    body = JSON.stringify(se_SetQueueAttributesRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_StartMessageMoveTaskCommand = async (input, context) => {
    const headers = sharedHeaders("StartMessageMoveTask");
    let body;
    body = JSON.stringify(se_StartMessageMoveTaskRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_TagQueueCommand = async (input, context) => {
    const headers = sharedHeaders("TagQueue");
    let body;
    body = JSON.stringify(se_TagQueueRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UntagQueueCommand = async (input, context) => {
    const headers = sharedHeaders("UntagQueue");
    let body;
    body = JSON.stringify(se_UntagQueueRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const de_AddPermissionCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_CancelMessageMoveTaskCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_ChangeMessageVisibilityCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_ChangeMessageVisibilityBatchCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_CreateQueueCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_DeleteMessageCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_DeleteMessageBatchCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_DeleteQueueCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_GetQueueAttributesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_GetQueueUrlCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_ListDeadLetterSourceQueuesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_ListMessageMoveTasksCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_ListQueuesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_ListQueueTagsCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_PurgeQueueCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_ReceiveMessageCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_ReceiveMessageResult(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_RemovePermissionCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_SendMessageCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_SendMessageBatchCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_SetQueueAttributesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_StartMessageMoveTaskCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
export const de_TagQueueCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
export const de_UntagQueueCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    await collectBody(output.body, context);
    const response = {
        $metadata: deserializeMetadata(output),
    };
    return response;
};
const de_CommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    populateBodyWithQueryCompatibility(parsedOutput, output.headers);
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "InvalidAddress":
        case "com.amazonaws.sqs#InvalidAddress":
            throw await de_InvalidAddressRes(parsedOutput, context);
        case "InvalidSecurity":
        case "com.amazonaws.sqs#InvalidSecurity":
            throw await de_InvalidSecurityRes(parsedOutput, context);
        case "OverLimit":
        case "com.amazonaws.sqs#OverLimit":
            throw await de_OverLimitRes(parsedOutput, context);
        case "QueueDoesNotExist":
        case "com.amazonaws.sqs#QueueDoesNotExist":
            throw await de_QueueDoesNotExistRes(parsedOutput, context);
        case "RequestThrottled":
        case "com.amazonaws.sqs#RequestThrottled":
            throw await de_RequestThrottledRes(parsedOutput, context);
        case "UnsupportedOperation":
        case "com.amazonaws.sqs#UnsupportedOperation":
            throw await de_UnsupportedOperationRes(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.sqs#ResourceNotFoundException":
            throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
        case "MessageNotInflight":
        case "com.amazonaws.sqs#MessageNotInflight":
            throw await de_MessageNotInflightRes(parsedOutput, context);
        case "ReceiptHandleIsInvalid":
        case "com.amazonaws.sqs#ReceiptHandleIsInvalid":
            throw await de_ReceiptHandleIsInvalidRes(parsedOutput, context);
        case "BatchEntryIdsNotDistinct":
        case "com.amazonaws.sqs#BatchEntryIdsNotDistinct":
            throw await de_BatchEntryIdsNotDistinctRes(parsedOutput, context);
        case "EmptyBatchRequest":
        case "com.amazonaws.sqs#EmptyBatchRequest":
            throw await de_EmptyBatchRequestRes(parsedOutput, context);
        case "InvalidBatchEntryId":
        case "com.amazonaws.sqs#InvalidBatchEntryId":
            throw await de_InvalidBatchEntryIdRes(parsedOutput, context);
        case "TooManyEntriesInBatchRequest":
        case "com.amazonaws.sqs#TooManyEntriesInBatchRequest":
            throw await de_TooManyEntriesInBatchRequestRes(parsedOutput, context);
        case "InvalidAttributeName":
        case "com.amazonaws.sqs#InvalidAttributeName":
            throw await de_InvalidAttributeNameRes(parsedOutput, context);
        case "InvalidAttributeValue":
        case "com.amazonaws.sqs#InvalidAttributeValue":
            throw await de_InvalidAttributeValueRes(parsedOutput, context);
        case "QueueDeletedRecently":
        case "com.amazonaws.sqs#QueueDeletedRecently":
            throw await de_QueueDeletedRecentlyRes(parsedOutput, context);
        case "QueueNameExists":
        case "com.amazonaws.sqs#QueueNameExists":
            throw await de_QueueNameExistsRes(parsedOutput, context);
        case "InvalidIdFormat":
        case "com.amazonaws.sqs#InvalidIdFormat":
            throw await de_InvalidIdFormatRes(parsedOutput, context);
        case "PurgeQueueInProgress":
        case "com.amazonaws.sqs#PurgeQueueInProgress":
            throw await de_PurgeQueueInProgressRes(parsedOutput, context);
        case "KmsAccessDenied":
        case "com.amazonaws.sqs#KmsAccessDenied":
            throw await de_KmsAccessDeniedRes(parsedOutput, context);
        case "KmsDisabled":
        case "com.amazonaws.sqs#KmsDisabled":
            throw await de_KmsDisabledRes(parsedOutput, context);
        case "KmsInvalidKeyUsage":
        case "com.amazonaws.sqs#KmsInvalidKeyUsage":
            throw await de_KmsInvalidKeyUsageRes(parsedOutput, context);
        case "KmsInvalidState":
        case "com.amazonaws.sqs#KmsInvalidState":
            throw await de_KmsInvalidStateRes(parsedOutput, context);
        case "KmsNotFound":
        case "com.amazonaws.sqs#KmsNotFound":
            throw await de_KmsNotFoundRes(parsedOutput, context);
        case "KmsOptInRequired":
        case "com.amazonaws.sqs#KmsOptInRequired":
            throw await de_KmsOptInRequiredRes(parsedOutput, context);
        case "KmsThrottled":
        case "com.amazonaws.sqs#KmsThrottled":
            throw await de_KmsThrottledRes(parsedOutput, context);
        case "InvalidMessageContents":
        case "com.amazonaws.sqs#InvalidMessageContents":
            throw await de_InvalidMessageContentsRes(parsedOutput, context);
        case "BatchRequestTooLong":
        case "com.amazonaws.sqs#BatchRequestTooLong":
            throw await de_BatchRequestTooLongRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
const de_BatchEntryIdsNotDistinctRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new BatchEntryIdsNotDistinct({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_BatchRequestTooLongRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new BatchRequestTooLong({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_EmptyBatchRequestRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new EmptyBatchRequest({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidAddressRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidAddress({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidAttributeNameRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidAttributeName({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidAttributeValueRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidAttributeValue({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidBatchEntryIdRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidBatchEntryId({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidIdFormatRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidIdFormat({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidMessageContentsRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidMessageContents({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidSecurityRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidSecurity({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_KmsAccessDeniedRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new KmsAccessDenied({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_KmsDisabledRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new KmsDisabled({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_KmsInvalidKeyUsageRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new KmsInvalidKeyUsage({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_KmsInvalidStateRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new KmsInvalidState({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_KmsNotFoundRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new KmsNotFound({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_KmsOptInRequiredRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new KmsOptInRequired({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_KmsThrottledRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new KmsThrottled({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_MessageNotInflightRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new MessageNotInflight({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_OverLimitRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new OverLimit({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_PurgeQueueInProgressRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new PurgeQueueInProgress({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_QueueDeletedRecentlyRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new QueueDeletedRecently({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_QueueDoesNotExistRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new QueueDoesNotExist({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_QueueNameExistsRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new QueueNameExists({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ReceiptHandleIsInvalidRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ReceiptHandleIsInvalid({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_RequestThrottledRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new RequestThrottled({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ResourceNotFoundExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ResourceNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_TooManyEntriesInBatchRequestRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new TooManyEntriesInBatchRequest({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_UnsupportedOperationRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new UnsupportedOperation({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const se_ActionNameList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return _toStr(entry);
    });
};
const se_AddPermissionRequest = (input, context) => {
    return take(input, {
        AWSAccountIds: (_) => se_AWSAccountIdList(_, context),
        Actions: (_) => se_ActionNameList(_, context),
        Label: _toStr,
        QueueUrl: _toStr,
    });
};
const se_AttributeNameList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return _toStr(entry);
    });
};
const se_AWSAccountIdList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return _toStr(entry);
    });
};
const se_BinaryList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return context.base64Encoder(entry);
    });
};
const se_CancelMessageMoveTaskRequest = (input, context) => {
    return take(input, {
        TaskHandle: _toStr,
    });
};
const se_ChangeMessageVisibilityBatchRequest = (input, context) => {
    return take(input, {
        Entries: (_) => se_ChangeMessageVisibilityBatchRequestEntryList(_, context),
        QueueUrl: _toStr,
    });
};
const se_ChangeMessageVisibilityBatchRequestEntry = (input, context) => {
    return take(input, {
        Id: _toStr,
        ReceiptHandle: _toStr,
        VisibilityTimeout: _toNum,
    });
};
const se_ChangeMessageVisibilityBatchRequestEntryList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_ChangeMessageVisibilityBatchRequestEntry(entry, context);
    });
};
const se_ChangeMessageVisibilityRequest = (input, context) => {
    return take(input, {
        QueueUrl: _toStr,
        ReceiptHandle: _toStr,
        VisibilityTimeout: _toNum,
    });
};
const se_CreateQueueRequest = (input, context) => {
    return take(input, {
        Attributes: (_) => se_QueueAttributeMap(_, context),
        QueueName: _toStr,
        tags: (_) => se_TagMap(_, context),
    });
};
const se_DeleteMessageBatchRequest = (input, context) => {
    return take(input, {
        Entries: (_) => se_DeleteMessageBatchRequestEntryList(_, context),
        QueueUrl: _toStr,
    });
};
const se_DeleteMessageBatchRequestEntry = (input, context) => {
    return take(input, {
        Id: _toStr,
        ReceiptHandle: _toStr,
    });
};
const se_DeleteMessageBatchRequestEntryList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_DeleteMessageBatchRequestEntry(entry, context);
    });
};
const se_DeleteMessageRequest = (input, context) => {
    return take(input, {
        QueueUrl: _toStr,
        ReceiptHandle: _toStr,
    });
};
const se_DeleteQueueRequest = (input, context) => {
    return take(input, {
        QueueUrl: _toStr,
    });
};
const se_GetQueueAttributesRequest = (input, context) => {
    return take(input, {
        AttributeNames: (_) => se_AttributeNameList(_, context),
        QueueUrl: _toStr,
    });
};
const se_GetQueueUrlRequest = (input, context) => {
    return take(input, {
        QueueName: _toStr,
        QueueOwnerAWSAccountId: _toStr,
    });
};
const se_ListDeadLetterSourceQueuesRequest = (input, context) => {
    return take(input, {
        MaxResults: _toNum,
        NextToken: _toStr,
        QueueUrl: _toStr,
    });
};
const se_ListMessageMoveTasksRequest = (input, context) => {
    return take(input, {
        MaxResults: _toNum,
        SourceArn: _toStr,
    });
};
const se_ListQueuesRequest = (input, context) => {
    return take(input, {
        MaxResults: _toNum,
        NextToken: _toStr,
        QueueNamePrefix: _toStr,
    });
};
const se_ListQueueTagsRequest = (input, context) => {
    return take(input, {
        QueueUrl: _toStr,
    });
};
const se_MessageAttributeNameList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return _toStr(entry);
    });
};
const se_MessageAttributeValue = (input, context) => {
    return take(input, {
        BinaryListValues: (_) => se_BinaryList(_, context),
        BinaryValue: context.base64Encoder,
        DataType: _toStr,
        StringListValues: (_) => se_StringList(_, context),
        StringValue: _toStr,
    });
};
const se_MessageBodyAttributeMap = (input, context) => {
    return Object.entries(input).reduce((acc, [key, value]) => {
        if (value === null) {
            return acc;
        }
        acc[key] = se_MessageAttributeValue(value, context);
        return acc;
    }, {});
};
const se_MessageBodySystemAttributeMap = (input, context) => {
    return Object.entries(input).reduce((acc, [key, value]) => {
        if (value === null) {
            return acc;
        }
        acc[key] = se_MessageSystemAttributeValue(value, context);
        return acc;
    }, {});
};
const se_MessageSystemAttributeList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return _toStr(entry);
    });
};
const se_MessageSystemAttributeValue = (input, context) => {
    return take(input, {
        BinaryListValues: (_) => se_BinaryList(_, context),
        BinaryValue: context.base64Encoder,
        DataType: _toStr,
        StringListValues: (_) => se_StringList(_, context),
        StringValue: _toStr,
    });
};
const se_PurgeQueueRequest = (input, context) => {
    return take(input, {
        QueueUrl: _toStr,
    });
};
const se_QueueAttributeMap = (input, context) => {
    return Object.entries(input).reduce((acc, [key, value]) => {
        if (value === null) {
            return acc;
        }
        acc[key] = _toStr(value);
        return acc;
    }, {});
};
const se_ReceiveMessageRequest = (input, context) => {
    return take(input, {
        AttributeNames: (_) => se_AttributeNameList(_, context),
        MaxNumberOfMessages: _toNum,
        MessageAttributeNames: (_) => se_MessageAttributeNameList(_, context),
        MessageSystemAttributeNames: (_) => se_MessageSystemAttributeList(_, context),
        QueueUrl: _toStr,
        ReceiveRequestAttemptId: _toStr,
        VisibilityTimeout: _toNum,
        WaitTimeSeconds: _toNum,
    });
};
const se_RemovePermissionRequest = (input, context) => {
    return take(input, {
        Label: _toStr,
        QueueUrl: _toStr,
    });
};
const se_SendMessageBatchRequest = (input, context) => {
    return take(input, {
        Entries: (_) => se_SendMessageBatchRequestEntryList(_, context),
        QueueUrl: _toStr,
    });
};
const se_SendMessageBatchRequestEntry = (input, context) => {
    return take(input, {
        DelaySeconds: _toNum,
        Id: _toStr,
        MessageAttributes: (_) => se_MessageBodyAttributeMap(_, context),
        MessageBody: _toStr,
        MessageDeduplicationId: _toStr,
        MessageGroupId: _toStr,
        MessageSystemAttributes: (_) => se_MessageBodySystemAttributeMap(_, context),
    });
};
const se_SendMessageBatchRequestEntryList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_SendMessageBatchRequestEntry(entry, context);
    });
};
const se_SendMessageRequest = (input, context) => {
    return take(input, {
        DelaySeconds: _toNum,
        MessageAttributes: (_) => se_MessageBodyAttributeMap(_, context),
        MessageBody: _toStr,
        MessageDeduplicationId: _toStr,
        MessageGroupId: _toStr,
        MessageSystemAttributes: (_) => se_MessageBodySystemAttributeMap(_, context),
        QueueUrl: _toStr,
    });
};
const se_SetQueueAttributesRequest = (input, context) => {
    return take(input, {
        Attributes: (_) => se_QueueAttributeMap(_, context),
        QueueUrl: _toStr,
    });
};
const se_StartMessageMoveTaskRequest = (input, context) => {
    return take(input, {
        DestinationArn: _toStr,
        MaxNumberOfMessagesPerSecond: _toNum,
        SourceArn: _toStr,
    });
};
const se_StringList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return _toStr(entry);
    });
};
const se_TagKeyList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return _toStr(entry);
    });
};
const se_TagMap = (input, context) => {
    return Object.entries(input).reduce((acc, [key, value]) => {
        if (value === null) {
            return acc;
        }
        acc[key] = _toStr(value);
        return acc;
    }, {});
};
const se_TagQueueRequest = (input, context) => {
    return take(input, {
        QueueUrl: _toStr,
        Tags: (_) => se_TagMap(_, context),
    });
};
const se_UntagQueueRequest = (input, context) => {
    return take(input, {
        QueueUrl: _toStr,
        TagKeys: (_) => se_TagKeyList(_, context),
    });
};
const de_BinaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return context.base64Decoder(entry);
    });
    return retVal;
};
const de_Message = (output, context) => {
    return take(output, {
        Attributes: _json,
        Body: __expectString,
        MD5OfBody: __expectString,
        MD5OfMessageAttributes: __expectString,
        MessageAttributes: (_) => de_MessageBodyAttributeMap(_, context),
        MessageId: __expectString,
        ReceiptHandle: __expectString,
    });
};
const de_MessageAttributeValue = (output, context) => {
    return take(output, {
        BinaryListValues: (_) => de_BinaryList(_, context),
        BinaryValue: context.base64Decoder,
        DataType: __expectString,
        StringListValues: _json,
        StringValue: __expectString,
    });
};
const de_MessageBodyAttributeMap = (output, context) => {
    return Object.entries(output).reduce((acc, [key, value]) => {
        if (value === null) {
            return acc;
        }
        acc[key] = de_MessageAttributeValue(value, context);
        return acc;
    }, {});
};
const de_MessageList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Message(entry, context);
    });
    return retVal;
};
const de_ReceiveMessageResult = (output, context) => {
    return take(output, {
        Messages: (_) => de_MessageList(_, context),
    });
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
const throwDefaultError = withBaseException(__BaseException);
const buildHttpRpcRequest = async (context, headers, path, resolvedHostname, body) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const contents = {
        protocol,
        hostname,
        port,
        method: "POST",
        path: basePath.endsWith("/") ? basePath.slice(0, -1) + path : basePath + path,
        headers,
    };
    if (resolvedHostname !== undefined) {
        contents.hostname = resolvedHostname;
    }
    if (body !== undefined) {
        contents.body = body;
    }
    return new __HttpRequest(contents);
};
function sharedHeaders(operation) {
    return {
        "content-type": "application/x-amz-json-1.0",
        "x-amz-target": `AmazonSQS.${operation}`,
    };
}
const populateBodyWithQueryCompatibility = (parsedOutput, headers) => {
    const queryErrorHeader = headers["x-amzn-query-error"];
    if (parsedOutput.body !== undefined && queryErrorHeader != null) {
        const codeAndType = queryErrorHeader.split(";");
        parsedOutput.body.Code = codeAndType[0];
        parsedOutput.body.Type = codeAndType[1];
    }
};
