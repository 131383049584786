import { AccessionViewEntity, AccessionViewType, ItemBuilder } from '../../esl';
import { DateUtilities } from '../DateUtilities';
import { EnumUtils } from '../EnumUtils';
export class AccessionViewEntityConverter {
    static create = () => {
        return new AccessionViewEntityConverter();
    };
    toEntity = (source) => {
        return ItemBuilder.builder()
            .withString(AccessionViewEntity.FIELDS.PATIENT_ID, source.patientId)
            .withString(AccessionViewEntity.FIELDS.ACCESSION, source.accession)
            .withString(AccessionViewEntity.FIELDS.TYPE, source.type)
            .withString(AccessionViewEntity.FIELDS.EXTERNAL_LAB_ID, source.externalLabId)
            .withString(AccessionViewEntity.FIELDS.USER_ID, source.userId)
            .withString(AccessionViewEntity.FIELDS.TIMESTAMP, source.timestamp?.toISOString())
            .build();
    };
    toModel = (source) => {
        return {
            patientId: source[AccessionViewEntity.FIELDS.PATIENT_ID].S,
            accession: source[AccessionViewEntity.FIELDS.ACCESSION]?.S,
            type: EnumUtils.getByValue(AccessionViewType, source[AccessionViewEntity.FIELDS.TYPE]?.S),
            externalLabId: source[AccessionViewEntity.FIELDS.EXTERNAL_LAB_ID].S,
            userId: source[AccessionViewEntity.FIELDS.USER_ID].S,
            timestamp: DateUtilities.fromString(source[AccessionViewEntity.FIELDS.TIMESTAMP].S),
        };
    };
}
