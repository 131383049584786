import { OrderTestDtoToOrderTestConverter } from './OrderTestDtoToOrderTestConverter';
import { OrderStatusHistoryDtoToOrderStatusHistoryConverter } from './OrderStatusHistoryDtoToOrderStatusHistoryConverter';
import { LabEntityDtoToLabConverter } from './LabEntityDtoToLabConverter';
import { OrderDTOStatusEnumToOrderStatusConverter } from './OrderDTOStatusEnumToOrderStatusConverter';
import { OrderRefundDtoToOrderRefundConverter } from './OrderRefundDtoToOrderRefundConverter';
import { DateUtilities } from '../DateUtilities';
import { OrderCancellationReason } from '../../models';
export class OrderDtoToOrderConverter {
    labDtoConverter;
    orderDtoStatusConverter;
    orderStatusHistoryDtoConverter;
    orderTestDtoConverter;
    orderRefundDtoConverter;
    constructor(labDtoConverter, orderDtoStatusConverter, orderStatusHistoryDtoConverter, orderTestDtoConverter, orderRefundDtoConverter) {
        this.labDtoConverter = labDtoConverter;
        this.orderDtoStatusConverter = orderDtoStatusConverter;
        this.orderStatusHistoryDtoConverter = orderStatusHistoryDtoConverter;
        this.orderTestDtoConverter = orderTestDtoConverter;
        this.orderRefundDtoConverter = orderRefundDtoConverter;
    }
    static create = () => {
        return new OrderDtoToOrderConverter(LabEntityDtoToLabConverter.create(), OrderDTOStatusEnumToOrderStatusConverter.create(), OrderStatusHistoryDtoToOrderStatusHistoryConverter.create(), OrderTestDtoToOrderTestConverter.create(), OrderRefundDtoToOrderRefundConverter.create());
    };
    toModel = (source) => {
        return {
            id: source.id,
            createdDateTime: source.createdDateTime ? DateUtilities.fromString(source.createdDateTime) : undefined,
            orderNumber: source.orderNumber,
            isRefundable: source.isRefundable || false,
            labId: source.labId,
            lab: source.labEntity ? this.labDtoConverter.toModel(source.labEntity) : undefined,
            patientBirthdate: source.patientBirthdate ? DateUtilities.fromString(source.patientBirthdate) : undefined,
            patientEmail: source.patientEmail,
            patientFirstName: source.patientFirstName,
            patientId: source.patientId,
            patientLastName: source.patientLastName,
            status: this.orderDtoStatusConverter.toModel(source.status),
            stripePaymentIntentAmount: source.stripePaymentIntentAmount,
            stripePaymentIntentId: source.stripePaymentIntentId,
            stripePaymentIntentStatus: source.stripePaymentIntentStatus,
            totals: source.totals,
            refundTotal: source.refundTotal,
            providerName: source.providerName,
            providerNpi: source.providerNpi,
            nonRefundableFee: source.nonRefundableFee,
            fees: source.fees,
            transactionId: source.transactionId,
            cancelReason: source.cancelReason ? OrderCancellationReason[source.cancelReason] : undefined,
            cancelDateTime: source.cancelDateTime ? DateUtilities.fromString(source.cancelDateTime) : undefined,
            cancelReasonOther: source.cancelReasonOther,
            orderTests: source.orderTests ? source.orderTests.map((test) => this.orderTestDtoConverter.toModel(test)) : new Array(),
            orderStatusHistoryList: source.orderStatusHistoryList
                ? source.orderStatusHistoryList.map((statusHistory) => this.orderStatusHistoryDtoConverter.toModel(statusHistory))
                : new Array(),
            refunds: source.refunds ? source.refunds.map((refund) => this.orderRefundDtoConverter.toModel(refund)) : new Array(),
        };
    };
}
