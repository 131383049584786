import axios from 'axios';
export class MobilePatientLabResultsApi {
    apiBaseUrl;
    axios;
    static BASE_PATH = '/api/mobile/patient/results';
    BASE_URL;
    constructor(apiBaseUrl, axios) {
        this.apiBaseUrl = apiBaseUrl;
        this.axios = axios;
        this.BASE_URL = `${apiBaseUrl}${MobilePatientLabResultsApi.BASE_PATH}`;
    }
    getResults = async () => {
        return this.axios.get(`${this.BASE_URL}`);
    };
    getResultsOverview = async () => {
        return this.axios.get(`${this.BASE_URL}/overview`);
    };
    getResultSummariesByYear = async (reportYear) => {
        return this.axios.get(`${this.BASE_URL}/year/${reportYear}`);
    };
    getResultReadState = async (resultId) => {
        return this.axios.get(`${this.BASE_URL}/${resultId}/viewed`);
    };
    markResultAsUnread = async (resultId) => {
        return this.axios.post(`${this.BASE_URL}/${resultId}/unread`);
    };
}
export class MobilePatientLabResultsApiFactory {
    static create = (apiBaseUrl, axiosInstance) => {
        if (!axiosInstance) {
            axiosInstance = axios.create();
        }
        return new MobilePatientLabResultsApi(apiBaseUrl, axiosInstance);
    };
}
