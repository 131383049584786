import { BatchWriteItemCommand, } from '@aws-sdk/client-dynamodb';
import { Operation } from './Operation';
export class BatchWriteItemOperation extends Operation {
    constructor(dynamoDbDocClient) {
        super(dynamoDbDocClient, BatchWriteItemCommand, {});
    }
    withRequestItems = (RequestItems) => {
        this.spec.RequestItems = RequestItems;
        return this;
    };
    withPutRequest = (TableName, PutRequest) => {
        if (!this.spec.RequestItems) {
            this.spec.RequestItems = {};
        }
        this.spec.RequestItems[TableName]
            ? this.spec.RequestItems[TableName].push({ PutRequest })
            : (this.spec.RequestItems[TableName] = [{ PutRequest }]);
        return this;
    };
    withDeleteRequest = (TableName, DeleteRequest) => {
        if (!this.spec.RequestItems) {
            this.spec.RequestItems = {};
        }
        this.spec.RequestItems[TableName]
            ? this.spec.RequestItems[TableName].push({ DeleteRequest })
            : (this.spec.RequestItems[TableName] = [{ DeleteRequest }]);
        return this;
    };
    withReturnConsumedCapacity = (returnConsumedCapacity) => {
        this.spec.ReturnConsumedCapacity = returnConsumedCapacity;
        return this;
    };
    withReturnItemCollectionMetrics = (returnItemCollectionMetrics) => {
        this.spec.ReturnItemCollectionMetrics = returnItemCollectionMetrics;
        return this;
    };
}
