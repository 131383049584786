import { OrderDTOStatusEnum } from '@luminate/connect-api-client-ts';
import { OrderStatus } from '../../models';
export class OrderStatusToOrderDTOStatusEnumConverter {
    static create = () => {
        return new OrderStatusToOrderDTOStatusEnumConverter();
    };
    toModel = (source) => {
        switch (source) {
            case OrderStatus.OPEN:
                return OrderDTOStatusEnum.PaymentCompleted;
            case OrderStatus.REFUNDED:
                return OrderDTOStatusEnum.Refund;
            case OrderStatus.CLOSED:
                return OrderDTOStatusEnum.Fulfilled;
            case OrderStatus.EXPIRED:
                return OrderDTOStatusEnum.Expired;
            default:
                return undefined;
        }
    };
}
