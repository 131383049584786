export class ItemBuilder {
    item = {};
    static builder = () => {
        return new ItemBuilder();
    };
    with = (name, value) => {
        if (typeof value === 'string') {
            return this.withString(name, value);
        }
        else if (typeof value === 'number') {
            return this.withNumber(name, value);
        }
        else if (typeof value === 'boolean') {
            return this.withBoolean(name, value);
        }
        else if (Array.isArray(value)) {
            value.map((item, index) => {
                this.with(`${name}${index}`, item);
            });
            return this;
        }
        else {
            return this.withMap(name, value);
        }
    };
    withString = (name, value) => {
        if (value) {
            this.item[name] = { S: value };
        }
        return this;
    };
    withNumber = (name, value) => {
        if (value) {
            this.item[name] = { N: value.toString() };
        }
        return this;
    };
    withBoolean = (name, value) => {
        if (value !== undefined && value !== null) {
            this.item[name] = { BOOL: value };
        }
        return this;
    };
    withMap = (name, value) => {
        if (value) {
            this.item[name] = { M: value };
        }
        return this;
    };
    build = () => {
        return this.item;
    };
}
