export class ActivityLogConverterRegistry {
    register = {};
    static create = () => {
        return new ActivityLogConverterRegistry();
    };
    withConverter = (type, converter) => {
        this.register[type] = converter;
        return this;
    };
    getByActivityLogType = (type) => {
        return this.register[type];
    };
    getByString = (type) => {
        return this.getByActivityLogType(type);
    };
}
