import { ExternalLinkCarouselActionDtoToExternalLinkCarouselActionConverter } from './ExternalLinkCarouselActionDtoToExternalLinkCarouselActionConverter';
import { NativeNavigationCarouselActionDtoToNativeNavigationCarouselActionConverter } from './NativeNavigationCarouselActionDtoToNativeNavigationCarouselActionConverter';
export class CarouselItemActionDtoToCarouselItemActionConverter {
    externalLinkActionConverter;
    nativeNavigationActionConverter;
    constructor(externalLinkActionConverter, nativeNavigationActionConverter) {
        this.externalLinkActionConverter = externalLinkActionConverter;
        this.nativeNavigationActionConverter = nativeNavigationActionConverter;
    }
    static create = () => {
        return new CarouselItemActionDtoToCarouselItemActionConverter(ExternalLinkCarouselActionDtoToExternalLinkCarouselActionConverter.create(), NativeNavigationCarouselActionDtoToNativeNavigationCarouselActionConverter.create());
    };
    instanceOfExternalLinkCarouselActionDto = (object) => {
        return 'url' in object;
    };
    toModel = (source) => {
        if (this.instanceOfExternalLinkCarouselActionDto(source)) {
            return this.externalLinkActionConverter.toModel(source);
        }
        else {
            return this.nativeNavigationActionConverter.toModel(source);
        }
    };
}
