import assert from 'assert';
import { ConditionExpressionBuilder } from './ConditionExpressionBuilder';
import { ExpressionAttributeNamesBuilder } from './ExpressionAttributeNamesBuilder';
import { ExpressionAttributeValuesBuilder } from './ExpressionAttributeValuesBuilder';
import { IndexOperation } from './IndexOperation';
export class FilterExpressionOperation extends IndexOperation {
    static WITH_FILTER_ASSERTION_MSG = 'withFilter can only be specified if a filter had not already been set.  See andFilter or orFilter to extend an existing filter.';
    static AND_FILTER_ASSERTION_MSG = 'andFilter can only be specified if a filter has already been set.  See withFilter to set the initial filter expression.';
    static OR_FILTER_ASSERTION_MSG = 'orFilter can only be specified if a filter has already been set.  See withFilter to set the initial filter expression.';
    constructor(dynamoDbDocClient, commandType, spec) {
        super(dynamoDbDocClient, commandType, spec);
    }
    withFilterExpression = (conditionExpression) => {
        this.spec.FilterExpression = conditionExpression;
        return this;
    };
    withFilter = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        const conditionExpressionBuilder = ConditionExpressionBuilder.builder();
        if (Array.isArray(value)) {
            conditionExpressionBuilder.whereIn(attributeKeyName, value, attributeValueNameOverride);
        }
        else {
            conditionExpressionBuilder.withCondition(attributeKeyName, comparator, attributeValueNameOverride);
        }
        const conditionExpression = conditionExpressionBuilder.build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        assert(!this.spec.FilterExpression, FilterExpressionOperation.WITH_FILTER_ASSERTION_MSG);
        this.spec.FilterExpression = conditionExpression;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    andFilter = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        assert(this.spec.FilterExpression, FilterExpressionOperation.AND_FILTER_ASSERTION_MSG);
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        this.spec.FilterExpression = `${this.spec.FilterExpression} AND ${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    orFilter = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        assert(this.spec.FilterExpression, FilterExpressionOperation.OR_FILTER_ASSERTION_MSG);
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        this.spec.FilterExpression = `${this.spec.FilterExpression} OR ${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
}
