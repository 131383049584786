export class EventService {
    emitter;
    constructor(emitter) {
        this.emitter = emitter;
        if (emitter === undefined) {
            this.emitter = window;
        }
        else {
            this.emitter = emitter;
        }
    }
    emit = (event) => {
        const theEvent = event.data ? new CustomEvent(event.name, { detail: event.data }) : new Event(event.name);
        //@ts-ignore
        return this.emitter.dispatchEvent(theEvent);
    };
    addEventListener = (eventName, callback) => {
        //@ts-ignore
        this.emitter.addEventListener(eventName, callback);
    };
    removeEventListener = (eventName, callback) => {
        //@ts-ignore
        this.emitter.removeEventListener(eventName, callback);
    };
}
