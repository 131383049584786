import { ActivityLogEntity, ActivityLogRepository, ActivityLogType } from '../esl';
import { ActivityLogConverterRegistry, AuthenticationCompletedActivityLogEntityConverter } from './converter';
export class ActivityLogService {
    repository;
    converters;
    constructor(repository, converters) {
        this.repository = repository;
        this.repository = repository;
        this.converters = converters;
    }
    static create = (repository, converters) => {
        return new ActivityLogService(repository || ActivityLogRepository.create(), converters ||
            new ActivityLogConverterRegistry().withConverter(ActivityLogType.AUTHENTICATION_COMPLETED, new AuthenticationCompletedActivityLogEntityConverter()));
    };
    fromItem = (item, logType) => {
        const itemKey = logType ? logType : item[ActivityLogEntity.FIELDS.ACTIVITY_KEY]?.S;
        const converter = this.converters.getByString(itemKey);
        return converter && converter.toModel(item);
    };
    toItem = (item) => {
        const converter = this.converters.getByActivityLogType(item.activityLogType);
        return converter && converter.toEntity(item);
    };
    save = (activityLog) => {
        return this.repository.save(this.toItem(activityLog));
    };
    getActivityLogEntitiesByPatientIdAndActivityKey = async (patientId, key) => {
        const queryCommandOutput = await this.repository.getActivityLogEntitiesByPatientIdAndActivityKey(patientId, key);
        return (queryCommandOutput.Items?.map((item) => this.fromItem(item))
            .filter((item) => item !== undefined)
            .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()) || []);
    };
    getActivityLogEntitiesByPatientIdAndLabExternalIdAndActivityKey = async (patientId, labExternalId, activityLogType) => {
        const queryCommandOutput = await this.repository.getActivityLogEntitiesByPatientIdAndLabExternalIdAndActivityKey(patientId, labExternalId, activityLogType);
        return (queryCommandOutput.Items?.map((item) => this.fromItem(item, activityLogType))
            .filter((item) => item !== undefined)
            .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()) || []);
    };
    getActivityLogEntityById = async (logId) => {
        const queryCommandOutput = await this.repository.getActivityLogEntityById(logId);
        return queryCommandOutput.Items && queryCommandOutput.Items[0] && this.fromItem(queryCommandOutput.Items[0]);
    };
    getLatestActivityLogByPatientIdAndLabExternalIdAndActivityKey = async (patientId, labExternalId, key) => {
        return (await this.getActivityLogEntitiesByPatientIdAndLabExternalIdAndActivityKey(patientId, labExternalId, key))[0];
    };
}
