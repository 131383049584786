export class ExternalLinkCarouselActionDtoToExternalLinkCarouselActionConverter {
    static create = () => {
        return new ExternalLinkCarouselActionDtoToExternalLinkCarouselActionConverter();
    };
    toModel = (source) => {
        return {
            label: source.label,
            url: source.url,
        };
    };
}
