import { CategoryPublicControllerApi } from '@luminate/connect-api-client-ts';
import { Pagination } from '../models';
import { CategoryDtoToCategoryConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class CategoryService {
    categoryApi;
    categoryDtoConverter;
    constructor(categoryApi, categoryDtoConverter) {
        this.categoryApi = categoryApi;
        this.categoryDtoConverter = categoryDtoConverter;
    }
    static create = (apiBaseUrl, categoryDtoConverter) => {
        return new CategoryService(new CategoryPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), categoryDtoConverter ? categoryDtoConverter : CategoryDtoToCategoryConverter.create());
    };
    /**
     * Retrieve a list of categories by lab.
     * @param {number} labId Unique identifier of the lab from which the categories will be retrieved.
     * @returns {Promise<Array<Category>>} Lab category collection.
     */
    listCategories = async (labId) => {
        const response = await this.categoryApi.getCategoriesListUsingGET(labId);
        return response.data ? response.data.map((category) => this.categoryDtoConverter.toModel(category)) : new Array();
    };
    /**
     * Retrieve a list of featured categories by lab.
     * @param {number} labId Unique identifier of the lab from which the categories will be retrieved.
     * @param {number} page Page to be retrieved.
     * @param {number} pageSize Page size to be retrieved.
     * @return {Promise<Array<Category>>} Lab featured category collection.
     */
    listFeaturedCategories = async (labId, page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE) => {
        const response = await this.categoryApi.getFeaturedCategoriesListUsingGET(labId, page, pageSize);
        return response.data ? response.data.map((category) => this.categoryDtoConverter.toModel(category)) : new Array();
    };
}
