import axios, { isAxiosError } from 'axios';
import { AuthenticationException, CaptchaException, LuminateRuntimeException } from '../models';
export class AuthenticationApi {
    apiBaseUrl;
    axios;
    logger;
    static CORE_APP_LOGOUT_PATH = '/j_spring_security_logout';
    static AUTH_PATH = '/api/v1/auth';
    static FORGOT_USERNAME_PATH = '/api/v1/user/username-reminder';
    static FORGOT_PASSWORD_PATH = '/api/v1/user/password-reset?username=';
    static CREATE_PATIENT_PATH = '/api/v1/user/signup';
    static CHECK_USERNAME = '/api/v1/user/username-check';
    static CHECK_PASSWORD = '/api/v1/user/password-check';
    static CREDENTIALS_INVALID_MSG = 'Credentials are invalid';
    static CAPTCHA_RESPONSE_HEADER = 'g-recaptcha-response';
    static CAPTCHA_ERROR = 'captcha';
    static CAPTCHA_EXCEPTION_MSG = 'Captcha is required.';
    constructor(apiBaseUrl, axios, logger) {
        this.apiBaseUrl = apiBaseUrl;
        this.axios = axios;
        this.logger = logger;
    }
    static create = (apiBaseUrl, axiosInstance, logger) => {
        if (!axiosInstance) {
            axiosInstance = axios.create();
        }
        return new AuthenticationApi(apiBaseUrl, axiosInstance, logger || console);
    };
    authenticate = async (userName, password, captcha) => {
        try {
            const captchaConfig = captcha
                ? {
                    headers: {
                        [`${AuthenticationApi.CAPTCHA_RESPONSE_HEADER}`]: captcha,
                    },
                }
                : undefined;
            return await this.axios.post(`${this.apiBaseUrl}${AuthenticationApi.AUTH_PATH}`, {
                username: userName,
                password: password,
            }, captchaConfig);
        }
        catch (ex) {
            if (isAxiosError(ex)) {
                if (ex.response?.data?.error && ex.response.data.error.includes(AuthenticationApi.CAPTCHA_ERROR)) {
                    throw new CaptchaException(AuthenticationApi.CAPTCHA_EXCEPTION_MSG, ex);
                }
            }
            throw new AuthenticationException(AuthenticationApi.CREDENTIALS_INVALID_MSG, ex);
        }
    };
    /**
     * Submit a request to retrieve an email with a reminder of the account's username.
     * @param {string} email Email address associated with the account if it exists.
     */
    forgotUsername = async (email) => {
        return await this.axios.post(`${this.apiBaseUrl}${AuthenticationApi.FORGOT_USERNAME_PATH}`, { email });
    };
    /**
     * Submit a request to retrieve an email with instructions for resetting an account password.
     * @param {string} userName Username for the account to have the email sent.
     */
    forgotPassword = async (userName) => {
        return await this.axios.get(`${this.apiBaseUrl}${AuthenticationApi.FORGOT_PASSWORD_PATH}${encodeURIComponent(userName)}`);
    };
    /**
     * Submit to create a new Luminate Health Patient.
     * @param {SignupRequest} signupRequest Details used to create a new patient user.
     */
    signup = async (signupRequest) => {
        this.logger.info('Submitting patient signup', signupRequest);
        try {
            return await this.axios.post(`${this.apiBaseUrl}${AuthenticationApi.CREATE_PATIENT_PATH}`, signupRequest);
        }
        catch (ex) {
            this.logger.error('Unable to process patient signup request', ex);
            throw ex;
        }
    };
    /**
     * Submit to check on proposed username ahead of attempting to create account.
     * @param {string} username Username to be validated.
     * @return {Promise<AxiosResponse>} Response of request to the server.
     */
    validNewUsername = async (username) => {
        try {
            const response = await this.axios.post(`${this.apiBaseUrl}${AuthenticationApi.CHECK_USERNAME}`, { username });
            return response.data;
        }
        catch (ex) {
            if (isAxiosError(ex)) {
                return ex.response?.data;
            }
            else {
                throw new LuminateRuntimeException('Unknown error determining username validity.', ex);
            }
        }
    };
    validatePassword = async (password) => {
        try {
            const response = await this.axios.post(`${this.apiBaseUrl}${AuthenticationApi.CHECK_PASSWORD}`, {
                password,
            });
            return response.data;
        }
        catch (ex) {
            if (isAxiosError(ex)) {
                return ex.response?.data;
            }
            else {
                throw new LuminateRuntimeException('Unknown error determining password validity.', ex);
            }
        }
    };
    signout = async () => {
        return await this.axios.post(`${this.apiBaseUrl}${AuthenticationApi.CORE_APP_LOGOUT_PATH}`);
    };
}
/*
 * @deprecated: @see public static create function in the AuthenticationApi class
 */
export class AuthenticationApiFactory {
    static create = (apiBaseUrl, axiosInstance, logger) => {
        if (!axiosInstance) {
            axiosInstance = axios.create();
        }
        return new AuthenticationApi(apiBaseUrl, axiosInstance, logger || console);
    };
}
