export var PatientEventNotificationType;
(function (PatientEventNotificationType) {
    PatientEventNotificationType["PRENATAL_HEALTH_EMAIL"] = "PRENATAL_HEALTH_EMAIL";
    PatientEventNotificationType["SIGNUP"] = "SIGNUP";
    PatientEventNotificationType["IDV_BYPASS"] = "IDV_BYPASS";
    PatientEventNotificationType["DAT_ORDER_EXPIRATION_WARNING_EMAIL"] = "DAT_ORDER_EXPIRATION_WARNING_EMAIL";
    PatientEventNotificationType["DAT_ORDER_EXPIRATION_EMAIL"] = "DAT_ORDER_EXPIRATION_EMAIL";
    PatientEventNotificationType["GUARDIAN_ACCOUNT_SETUP"] = "GUARDIAN_ACCOUNT_SETUP";
    PatientEventNotificationType["GUARDIAN_ACCOUNT_UPDATE"] = "GUARDIAN_ACCOUNT_UPDATE";
    PatientEventNotificationType["OBX_GENERATE"] = "OBX_GENERATE";
    PatientEventNotificationType["LAB_RESULT_NOTIFICATION_SAMPLE_COLLECTION"] = "LAB_RESULT_NOTIFICATION_SAMPLE_COLLECTION";
    PatientEventNotificationType["LAB_RESULT_NOTIFICATION_NEW_RESULT"] = "LAB_RESULT_NOTIFICATION_NEW_RESULT";
})(PatientEventNotificationType || (PatientEventNotificationType = {}));
