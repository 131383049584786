import { DatComponentControllerApi } from '@luminate/connect-api-client-ts/src/api';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
import { DatComponentConverter } from './converter';
export class DatComponentService {
    api;
    converter;
    constructor(api, converter) {
        this.api = api;
        this.converter = converter;
    }
    static create = (apiBaseUrl) => {
        return new DatComponentService(new DatComponentControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), DatComponentConverter.create());
    };
    getDatComponents = async (componentNames) => {
        const response = await this.api.getDatComponentsUsingGET(componentNames);
        const responseData = response.data;
        const map = new Map();
        Object.entries(responseData).map(([componentName, dto]) => {
            map.set(componentName, this.converter.toModel(dto));
        });
        return map;
    };
}
