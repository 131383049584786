import { GetQueueUrlCommand, SendMessageCommand, SQSClient, } from '@aws-sdk/client-sqs';
export class SqsService {
    sqsClient;
    queueUrlCache = {};
    constructor(sqsClient) {
        /**
         * Configure for Localstack if running locally
         */
        const clientOptions = {
            endpoint: process.env.LOCAL_AWS_ENDPOINT || undefined,
            region: process.env.AWS_DEFAULT_REGION || 'us-east-1',
        };
        this.sqsClient = sqsClient || new SQSClient(clientOptions);
    }
    getUrl = async (queueName) => {
        if (Object.keys(this.queueUrlCache).includes(queueName)) {
            return this.queueUrlCache[queueName];
        }
        else {
            const command = new GetQueueUrlCommand({
                QueueName: queueName,
            });
            const result = await this.sqsClient.send(command);
            this.queueUrlCache[queueName] = result.QueueUrl;
            return result.QueueUrl;
        }
    };
    send = async (queueName, message, groupId, deduplicationId) => {
        const queueUrl = await this.getUrl(queueName);
        const command = new SendMessageCommand({
            MessageBody: JSON.stringify(message),
            QueueUrl: queueUrl,
            MessageGroupId: groupId,
            MessageDeduplicationId: deduplicationId,
        });
        return await this.sqsClient.send(command);
    };
}
