export class NumberToCurrencyConverter {
    static create = () => {
        return new NumberToCurrencyConverter();
    };
    toModel = (source) => {
        const amount = source ? source : 0;
        const fixedAmount = (Math.abs(amount) / 100).toFixed(2);
        return amount < 0 ? `—$${fixedAmount}` : `$${fixedAmount}`;
    };
}
