export class InvitePatientResponseDtoToInvitePatientResponseConverter {
    static create = () => {
        return new InvitePatientResponseDtoToInvitePatientResponseConverter();
    };
    toModel = (source) => {
        return {
            patientId: source.patientId,
            userId: source.userId,
            firstName: source.firstName,
            middleName: source.middleName,
            lastName: source.lastName,
            email: source.email,
            userName: source.userName,
            dob: source.dob,
            gender: source.gender,
        };
    };
}
