import { DatComponentContentConverter } from './DatComponentContentConverter';
export class DatComponentConverter {
    datComponentContentConverter;
    constructor(datComponentContentConverter) {
        this.datComponentContentConverter = datComponentContentConverter;
    }
    static create = () => {
        return new DatComponentConverter(DatComponentContentConverter.create());
    };
    toModel = (source) => {
        return {
            enabled: source.enabled,
            content: source.content ? this.datComponentContentConverter.toModel(source.content) : undefined,
            error: source.error
        };
    };
}
