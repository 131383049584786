import { Client as __Client } from "@smithy/smithy-client";
export { __Client };
export class DynamoDBDocumentClient extends __Client {
    constructor(client, translateConfig) {
        super(client.config);
        this.config = client.config;
        this.config.translateConfig = translateConfig;
        this.middlewareStack = client.middlewareStack;
    }
    static from(client, translateConfig) {
        return new DynamoDBDocumentClient(client, translateConfig);
    }
    destroy() {
    }
}
