export class AdminAppConfigDTOToAdminAppConfigConverter {
    static create = () => {
        return new AdminAppConfigDTOToAdminAppConfigConverter();
    };
    toModel = (source) => {
        return {
            genders: source.genders || {},
            patientInviteEnabled: source.patientInviteEnabled || false,
        };
    };
}
