export var PhoneType;
(function (PhoneType) {
    PhoneType["MOBILE"] = "MOBILE";
    PhoneType["HOME"] = "HOME";
})(PhoneType || (PhoneType = {}));
export var RelationshipType;
(function (RelationshipType) {
    RelationshipType["SELF"] = "SELF";
    RelationshipType["PARENT"] = "PARENT";
    RelationshipType["LEGAL_GUARDIAN"] = "LEGAL_GUARDIAN";
    RelationshipType["PERSONAL_REPRESENTATIVE"] = "PERSONAL_REPRESENTATIVE";
})(RelationshipType || (RelationshipType = {}));
export const RelationshipTypes = [
    {
        label: 'Self',
        value: RelationshipType.SELF,
    },
    {
        label: 'Parent',
        value: RelationshipType.PARENT,
    },
    {
        label: 'Legal Guardian',
        value: RelationshipType.LEGAL_GUARDIAN,
    },
    {
        label: 'Personal Representative/POA',
        value: RelationshipType.PERSONAL_REPRESENTATIVE,
    },
];
export const PhoneTypes = [
    {
        label: 'Mobile',
        value: PhoneType.MOBILE,
    },
    {
        label: 'Home',
        value: PhoneType.HOME,
    },
];
