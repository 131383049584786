import { HttpStatusCode } from 'axios';
import { AuthenticationException, LuminateRuntimeException, NotFoundException, UnAuthorizedException } from '../models';
import { AccessionViewType, LabResultsReviewedTypes, MobilePatientLabResultsApiFactory } from '../esl';
import { LabResultSummaryDtoToLabResultSummaryConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
import { AccessionViewsService } from './AccessionViewsService';
import { DateUtilities } from './DateUtilities';
export class LabResultService {
    mobilePatientLabResultsApi;
    resultSummaryConverter;
    accessionViewService;
    static RESULTS_OVERVIEW_ERROR_MSG = `Unable to retrieve Lab Result Year Overview`;
    static API_URL_REQUIRED_MSG = 'An API URL is required.';
    static TOKEN_REQUIRED_MSG = 'An authorization token is required.';
    constructor(mobilePatientLabResultsApi, resultSummaryConverter, accessionViewService) {
        this.mobilePatientLabResultsApi = mobilePatientLabResultsApi;
        this.resultSummaryConverter = resultSummaryConverter;
        this.accessionViewService = accessionViewService;
    }
    static create = (apiBaseUrl, authToken, mobilePatientLabResultsApi, resultSummaryConverter, accessionViewService) => {
        if (!apiBaseUrl) {
            throw new LuminateRuntimeException(LabResultService.API_URL_REQUIRED_MSG);
        }
        if (!authToken) {
            throw new LuminateRuntimeException(LabResultService.TOKEN_REQUIRED_MSG);
        }
        return new LabResultService(mobilePatientLabResultsApi || MobilePatientLabResultsApiFactory.create(apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), resultSummaryConverter || LabResultSummaryDtoToLabResultSummaryConverter.create(), accessionViewService || AccessionViewsService.create());
    };
    getLabResultsLastReviewedTimestamp = async (patientId, externalLabId) => {
        const accessionViews = await this.accessionViewService.getAccessionViewsByPatientAndExternalLabIdWithTypes(patientId, externalLabId, LabResultsReviewedTypes);
        return accessionViews?.at(0)?.timestamp || DateUtilities.minDate();
    };
    getNewLabResultsReleasedCount = async (patientId, externalLabId) => {
        const lastReviewedTimestamp = await this.getLabResultsLastReviewedTimestamp(patientId, externalLabId);
        const resultsSince = await this.accessionViewService.getAccessionViewsByPatientAndExternalLabIdAndTypeAfterTimestamp(patientId, externalLabId, AccessionViewType.RELEASE, lastReviewedTimestamp);
        return resultsSince?.length || 0;
    };
    getLabResultsOverview = async () => {
        try {
            const response = await this.mobilePatientLabResultsApi.getResultsOverview();
            return response.data;
        }
        catch (ex) {
            throw new LuminateRuntimeException(LabResultService.RESULTS_OVERVIEW_ERROR_MSG, ex);
        }
    };
    getResultSummariesByYear = async (reportYear) => {
        try {
            const response = await this.mobilePatientLabResultsApi.getResultSummariesByYear(reportYear);
            return response.data ? response.data.map((summary) => this.resultSummaryConverter.toModel(summary)) : [];
        }
        catch (ex) {
            throw new LuminateRuntimeException(`Unable to retrieve lab results for year: ${reportYear}`, ex);
        }
    };
    getResultReadState = async (resultId) => {
        const response = await this.mobilePatientLabResultsApi.getResultReadState(resultId);
        if (response.status === HttpStatusCode.NotFound) {
            throw new NotFoundException(`Result: ${resultId} not found.`);
        }
        if (response.status === HttpStatusCode.Forbidden) {
            throw new UnAuthorizedException(`Access to result: ${resultId} is not authorized.`);
        }
        if (response.status === HttpStatusCode.Unauthorized) {
            throw new AuthenticationException(`Access to result: ${resultId} is denied.`);
        }
        if (response.status !== HttpStatusCode.Ok) {
            throw new LuminateRuntimeException(`Unknown error retrieving read state for result: ${resultId}.`);
        }
        return response.data;
    };
    markResultAsUnread = async (resultId) => {
        const response = await this.mobilePatientLabResultsApi.markResultAsUnread(resultId);
        if (response.status === HttpStatusCode.NotFound) {
            throw new NotFoundException(`Result: ${resultId} not found.`);
        }
        if (response.status === HttpStatusCode.Forbidden) {
            throw new UnAuthorizedException(`Access to result: ${resultId} is not authorized.`);
        }
        if (response.status === HttpStatusCode.Unauthorized) {
            throw new AuthenticationException(`Access to result: ${resultId} is denied.`);
        }
        if (response.status !== HttpStatusCode.Ok) {
            throw new LuminateRuntimeException(`Unknown error marking result: ${resultId} as unread.`);
        }
    };
}
