import { ScanCommand as __ScanCommand } from "@aws-sdk/client-dynamodb";
import { Command as $Command } from "@smithy/smithy-client";
import { DynamoDBDocumentClientCommand } from "../baseCommand/DynamoDBDocumentClientCommand";
import { ALL_MEMBERS, ALL_VALUES } from "../commands/utils";
export { DynamoDBDocumentClientCommand, $Command };
export class ScanCommand extends DynamoDBDocumentClientCommand {
    constructor(input) {
        super();
        this.input = input;
        this.inputKeyNodes = {
            ScanFilter: {
                "*": {
                    AttributeValueList: ALL_MEMBERS,
                },
            },
            ExclusiveStartKey: ALL_VALUES,
            ExpressionAttributeValues: ALL_VALUES,
        };
        this.outputKeyNodes = {
            Items: {
                "*": ALL_VALUES,
            },
            LastEvaluatedKey: ALL_VALUES,
        };
        this.clientCommand = new __ScanCommand(this.input);
        this.middlewareStack = this.clientCommand.middlewareStack;
    }
    resolveMiddleware(clientStack, configuration, options) {
        this.addMarshallingMiddleware(configuration);
        const stack = clientStack.concat(this.middlewareStack);
        const handler = this.clientCommand.resolveMiddleware(stack, configuration, options);
        return async () => handler(this.clientCommand);
    }
}
