export var ComparisonOperator;
(function (ComparisonOperator) {
    ComparisonOperator["Equals"] = "=";
    ComparisonOperator["GreaterThanOrEquals"] = ">=";
    ComparisonOperator["GreaterThan"] = ">";
    ComparisonOperator["LessThanOrEquals"] = "<=";
    ComparisonOperator["LessThan"] = "<";
    ComparisonOperator["DoesNotEqual"] = "<>";
    ComparisonOperator["In"] = "IN";
})(ComparisonOperator || (ComparisonOperator = {}));
