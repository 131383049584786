export class MobileSessionDtoToMobileSessionConverter {
    static create = () => {
        return new MobileSessionDtoToMobileSessionConverter();
    };
    toModel = (source) => {
        return {
            externalLabLocationsUrl: source.externalLabLocationUrl,
            darkTheme: source.darkTheme ? JSON.parse(source.darkTheme) : undefined,
            lightTheme: source.lightTheme ? JSON.parse(source.lightTheme) : undefined,
            contactUsUrl: source.contactUsUrl,
            termsOfServiceUrl: source.termsOfServiceUrl,
            privacyPolicyUrl: source.privacyPolicyUrl,
            sessionTimeoutPeriod: source.sessionTimeoutPeriod,
            readUnreadEnabled: source.readUnreadEnabled || false,
            signUpRelationshipEnable: source.signUpRelationshipEnable || false,
            signUpRelationshipErrorMessage: source.signUpRelationshipErrorMessage,
            faqUrl: source.faqUrl,
            supportUrl: source.supportUrl,
            genders: source.genders || {},
        };
    };
}
