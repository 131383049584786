import { JwtUtils } from './JwtUtils';
import { ROLE_IMPERSONATOR, ROLE_LAB_USER_EDIT_PATIENT, ROLE_LUMINATE_ADMIN, ROLE_LAB_USER_UNLINK } from './UserRoles';
import { labConstants } from './LabConstants';
export const getAuthDataLabId = (jwtToken) => {
    const authData = JwtUtils.toAuthData(jwtToken);
    return authData;
};
export const SecurityService = {
    isAdmin: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return !!authData?.roles?.find((role) => role === ROLE_LUMINATE_ADMIN);
    },
    canImpersonate: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return !!authData?.roles?.find((role) => role === ROLE_IMPERSONATOR);
    },
    canEditPatient: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return !!authData?.roles?.find((role) => role === ROLE_LAB_USER_EDIT_PATIENT);
    },
    canUnlink: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return !!authData?.roles?.find((role) => role === ROLE_LAB_USER_UNLINK);
    },
    isSonicUser: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return authData?.labId ? labConstants.isSonic(authData.labId) : false;
    },
    isBioRefUser: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return authData?.labId ? labConstants.isBioRef(authData.labId) : false;
    },
    isTricoreUser: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return authData?.labId ? labConstants.isTricore(authData.labId) : false;
    },
    isAlvernoUser: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return authData?.labId ? labConstants.isAlverno(authData.labId) : false;
    },
    isDLSUser: (jwtToken) => {
        const authData = getAuthDataLabId(jwtToken);
        return authData?.labId ? labConstants.isDLS(authData.labId) : false;
    },
};
