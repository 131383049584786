import { UpdateItemCommand, } from '@aws-sdk/client-dynamodb';
import { ConditionExpressionOperation } from './ConditionExpressionOperation';
import { ComparisonOperator } from './ComparisonOperator';
import { ConditionExpressionBuilder } from './ConditionExpressionBuilder';
import { ExpressionAttributeNamesBuilder } from './ExpressionAttributeNamesBuilder';
import { ExpressionAttributeValuesBuilder } from './ExpressionAttributeValuesBuilder';
import assert from 'assert';
export class UpdateItemOperation extends ConditionExpressionOperation {
    static WITH_CONDITION_ASSERTION_MSG = 'withUpdate can only be specified if an update expression had not already been set.  See andUpdate or orUpdate to extend an existing update expression.';
    static AND_CONDITION_ASSERTION_MSG = 'andUpdate can only be specified if an update condition has already been set.  See withUpdate to set the initial update expression.';
    static UPDATE_SET_COMMAND = 'set';
    static UPDATE_ITEM_SEPARATOR = ', ';
    constructor(dynamoDbDocClient) {
        super(dynamoDbDocClient, UpdateItemCommand, {});
    }
    withKey = (key) => {
        this.spec.Key = key;
        return this;
    };
    withReturnValues = (returnValues) => {
        this.spec.ReturnValues = returnValues;
        return this;
    };
    withReturnConsumedCapacity = (returnConsumedCapacity) => {
        this.spec.ReturnConsumedCapacity = returnConsumedCapacity;
        return this;
    };
    withReturnItemCollectionMetrics = (returnItemCollectionMetrics) => {
        this.spec.ReturnItemCollectionMetrics = returnItemCollectionMetrics;
        return this;
    };
    withUpdateExpression = (updateExpression) => {
        this.spec.UpdateExpression = updateExpression;
        return this;
    };
    withUpdate = (attributeKeyName, value, attributeValueNameOverride) => {
        const conditionExpression = ConditionExpressionBuilder.builder()
            .withCondition(attributeKeyName, ComparisonOperator.Equals, attributeValueNameOverride)
            .build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        assert(!this.spec.UpdateExpression, UpdateItemOperation.WITH_CONDITION_ASSERTION_MSG);
        this.spec.UpdateExpression = `${UpdateItemOperation.UPDATE_SET_COMMAND} ${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    andUpdate = (attributeKeyName, value, attributeValueNameOverride) => {
        assert(this.spec.UpdateExpression, UpdateItemOperation.AND_CONDITION_ASSERTION_MSG);
        const conditionExpression = ConditionExpressionBuilder.builder()
            .withCondition(attributeKeyName, ComparisonOperator.Equals, attributeValueNameOverride)
            .build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        this.spec.UpdateExpression = `${this.spec.UpdateExpression}${UpdateItemOperation.UPDATE_ITEM_SEPARATOR}${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    withReturnValuesOnConditionCheckFailure = (returnValuesOnConditionCheckFailure) => {
        this.spec.ReturnValuesOnConditionCheckFailure = returnValuesOnConditionCheckFailure;
        return this;
    };
}
