export class OrderTestDtoToOrderTestConverter {
    static create = () => {
        return new OrderTestDtoToOrderTestConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            code: source.code,
            testId: source.testId,
            longDescription: source.longDescription,
            shortDescription: source.shortDescription,
            turnAroundTime: source.turnAroundTime,
            labId: source.labId,
            name: source.name,
            price: source.price,
            quantity: source.quantity,
        };
    };
}
