import { OrderPatientControllerApi } from '@luminate/connect-api-client-ts';
import { Pagination } from '../models';
import { CancelOrderResponseDTOToCancelOrderResponseConverter, OrderDtoToOrderConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class OrderPatientService {
    orderPatientApi;
    orderDtoConverter;
    cancelOrderResponseConverter;
    static FILE_RESPONSE_TYPE = { responseType: 'blob' };
    constructor(orderPatientApi, orderDtoConverter, cancelOrderResponseConverter) {
        this.orderPatientApi = orderPatientApi;
        this.orderDtoConverter = orderDtoConverter;
        this.cancelOrderResponseConverter = cancelOrderResponseConverter;
    }
    /**
     * Create a new Order Patient Service instance.
     * @returns {OrderPatientService} New service instance.
     */
    static create = (apiBaseUrl, authToken) => {
        return new OrderPatientService(new OrderPatientControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), OrderDtoToOrderConverter.create(), CancelOrderResponseDTOToCancelOrderResponseConverter.create());
    };
    /**
     * Record that the patient has obtained a refund for a transaction.
     * @param {string} intentId Unique identifier for the payment intent which is being refunded.
     * @param {OrderCancellationReason} reason Reason indicator for the order cancellation.
     * @param {string} additionalInfo
     * @returns {Promise<Order>} The order information for the refund.
     */
    refundPayment = async (intentId, reason, additionalInfo) => {
        const response = await this.orderPatientApi.refundPaymentUsingPOST({
            intentId,
            reason,
            additionalInfo,
        });
        return this.cancelOrderResponseConverter.toModel(response.data);
    };
    /**
     * Retrieve the details for an order.
     * @param {number} orderId Unique identifier for the order to be retrieved.
     * @returns {Promise<Order>} The order information.
     */
    getOrderDetails = async (orderId) => {
        const response = await this.orderPatientApi.getOrderDetailsUsingGET1(orderId);
        return this.orderDtoConverter.toModel(response.data);
    };
    /**
     * Retrieve a paginated list of orders for the currently authenticated patient.
     * @param {number} [page] The page of orders to be retrieved.
     * @param {number} [pageSize] The size of the page to be retrieved.
     * @param {string} [filter] Partial Order Number filter.
     * @returns {Promise<Array<Order>>} The orders for the given page.
     */
    getOrdersListByPatientId = async (page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE, filter) => {
        const response = await this.orderPatientApi.getOrdersListByPatientIdUsingGET(filter, page, pageSize);
        return response.data.map((order) => this.orderDtoConverter.toModel(order));
    };
    /**
     * Download an Order Confirmation PDF.
     * @param {number} orderId Unique identifier of the order to be downloaded.
     * @returns {Promise<Blob>} Blob representing the PDF content.
     */
    downloadOrder = async (orderId) => {
        const response = await this.orderPatientApi.downloadOrderPdfUsingGET1(orderId, OrderPatientService.FILE_RESPONSE_TYPE);
        return response.data;
    };
}
