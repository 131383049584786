export class ContactUsPhoneConfigDtoToContactUsPhoneConfigConverter {
    static create = () => {
        return new ContactUsPhoneConfigDtoToContactUsPhoneConfigConverter();
    };
    toModel = (source) => {
        return {
            label: source.label,
            number: source.number,
        };
    };
}
