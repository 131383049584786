import { PutItemCommand, } from '@aws-sdk/client-dynamodb';
import { ConditionExpressionOperation } from './ConditionExpressionOperation';
export class PutOperation extends ConditionExpressionOperation {
    constructor(dynamoDbDocClient) {
        super(dynamoDbDocClient, PutItemCommand, {});
    }
    withItem = (item) => {
        this.spec.Item = item;
        return this;
    };
    withReturnValues = (returnValue) => {
        this.spec.ReturnValues = returnValue;
        return this;
    };
    withReturnConsumedCapacity = (returnConsumedCapacity) => {
        this.spec.ReturnConsumedCapacity = returnConsumedCapacity;
        return this;
    };
    withReturnItemCollectionMetrics = (returnItemCollectionMetrics) => {
        this.spec.ReturnItemCollectionMetrics = returnItemCollectionMetrics;
        return this;
    };
    withReturnValuesOnConditionCheckFailure = (returnValuesOnConditionCheckFailure) => {
        this.spec.ReturnValuesOnConditionCheckFailure = returnValuesOnConditionCheckFailure;
        return this;
    };
}
