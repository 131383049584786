export class LabResultSummaryDtoToLabResultSummaryConverter {
    static create = () => {
        return new LabResultSummaryDtoToLabResultSummaryConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            read: source.read,
            provider: source.provider,
            accession: source.accession,
            date: source.date,
        };
    };
}
