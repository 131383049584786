import assert from 'assert';
import { ConditionExpressionBuilder } from './ConditionExpressionBuilder';
import { ExpressionAttributeNamesBuilder } from './ExpressionAttributeNamesBuilder';
import { ExpressionAttributeValuesBuilder } from './ExpressionAttributeValuesBuilder';
import { FilterExpressionOperation } from './FilterExpressionOperation';
export class KeyConditionExpressionOperation extends FilterExpressionOperation {
    static WITH_KEY_CONDITION_ASSERTION_MSG = 'withKeyCondition can only be specified if a key condition had not already been set.  See andKeyCondition or orKeyCondition to extend an existing key condition.';
    static AND_KEY_CONDITION_ASSERTION_MSG = 'andKeyCondition can only be specified if a key condition has already been set.  See withKeyCondition to set the initial key condition expression.';
    static OR_KEY_CONDITION_ASSERTION_MSG = 'orKeyCondition can only be specified if a key condition has already been set.  See withKeyCondition to set the initial key condition expression.';
    constructor(dynamoDbDocClient, commandType, spec) {
        super(dynamoDbDocClient, commandType, spec);
    }
    withKeyConditionExpression = (conditionExpression) => {
        this.spec.KeyConditionExpression = conditionExpression;
        return this;
    };
    withKeyCondition = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        assert(!this.spec.KeyConditionExpression, KeyConditionExpressionOperation.WITH_KEY_CONDITION_ASSERTION_MSG);
        this.spec.KeyConditionExpression = conditionExpression;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    andKeyCondition = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        assert(this.spec.KeyConditionExpression, KeyConditionExpressionOperation.AND_KEY_CONDITION_ASSERTION_MSG);
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        this.spec.KeyConditionExpression = `${this.spec.KeyConditionExpression} AND ${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
    orKeyCondition = (attributeKeyName, comparator, value, attributeValueNameOverride) => {
        assert(this.spec.KeyConditionExpression, KeyConditionExpressionOperation.OR_KEY_CONDITION_ASSERTION_MSG);
        const conditionExpression = ConditionExpressionBuilder.builder().withCondition(attributeKeyName, comparator, attributeValueNameOverride).build();
        const expressionNames = ExpressionAttributeNamesBuilder.builder().withName(attributeKeyName, attributeValueNameOverride).build();
        const expressionValues = ExpressionAttributeValuesBuilder.builder().with(attributeKeyName, value, attributeValueNameOverride).build();
        this.spec.KeyConditionExpression = `${this.spec.KeyConditionExpression} OR ${conditionExpression}`;
        this.addExpressionNames(expressionNames);
        this.addExpressionValues(expressionValues);
        return this;
    };
}
