export const ROLE_LAB_USER_PATIENT_SEARCH = 'ROLE_LAB_USER_PATIENT_SEARCH';
export const ROLE_IMPERSONATOR = 'ROLE_IMPERSONATOR';
export const ROLE_LAB_USER_EDIT_PATIENT = 'ROLE_LAB_USER_EDIT_PATIENT';
export const ROLE_LAB_USER_COMPLIANCE = 'ROLE_LAB_USER_COMPLIANCE';
export const ROLE_LAB_USER_PRACTICE_ADMIN = 'ROLE_LAB_USER_PRACTICE_ADMIN';
export const ROLE_LAB_USER_IDV = 'ROLE_LAB_USER_IDV';
export const ROLE_LAB_USER_MERGE = 'ROLE_LAB_USER_MERGE';
export const ROLE_LAB_USER_ORDER_LOOKUP = 'ROLE_LAB_USER_ORDER_LOOKUP';
export const ROLE_LAB_USER = 'ROLE_LAB_USER';
export const ROLE_API_ADMIN_CLIENT = 'ROLE_API_ADMIN_CLIENT';
export const ROLE_ADMIN_APP_LAB_READ = 'ROLE_ADMIN_APP_LAB_READ';
export const ROLE_LUMINATE_ADMIN = 'ROLE_LUMINATE_ADMIN';
export const ROLE_LAB_USER_UNLINK = 'ROLE_LAB_USER_UNLINK';
export const ROLE_PATIENT_INVITE = 'ROLE_PATIENT_INVITE';
