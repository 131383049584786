export class ExpressionAttributeNamesBuilder {
    attributeNames = {};
    static DEFAULT_PREFIX = '#';
    keyPrefix = ExpressionAttributeNamesBuilder.DEFAULT_PREFIX;
    static builder = () => {
        return new ExpressionAttributeNamesBuilder();
    };
    withKeyPrefix = (keyPrefix) => {
        this.keyPrefix = keyPrefix;
        return this;
    };
    withName = (keyName, valueName) => {
        this.attributeNames[`${this.keyPrefix}${keyName}`] = valueName ? valueName : keyName;
        return this;
    };
    build = () => {
        return this.attributeNames;
    };
}
