export class DatServiceAreasContentConverter {
    static create = () => {
        return new DatServiceAreasContentConverter();
    };
    toModel = (source) => {
        return {
            header: source.header,
            locations: source.locations
        };
    };
}
