import { OrderDtoToOrderConverter } from './OrderDtoToOrderConverter';
export class CancelOrderResponseDTOToCancelOrderResponseConverter {
    orderDtoToOrderConverter;
    constructor(orderDtoToOrderConverter) {
        this.orderDtoToOrderConverter = orderDtoToOrderConverter;
    }
    static create = (orderDtoToOrderConverter) => {
        if (!orderDtoToOrderConverter) {
            orderDtoToOrderConverter = OrderDtoToOrderConverter.create();
        }
        return new CancelOrderResponseDTOToCancelOrderResponseConverter(orderDtoToOrderConverter);
    };
    toModel(source) {
        return {
            order: source.order ? this.orderDtoToOrderConverter.toModel(source.order) : undefined,
            refundId: source.refundId,
        };
    }
}
