import * as RA from 'ramda-adjunct';
import moment from 'moment';
import { decode } from 'base-64';
import { DateUtilities } from './DateUtilities';
export const SEPARATOR_PERIOD = '.';
export const INVALID_JWT_VALUE = 'INVALID';
export class JwtUtils {
    static isTokenExpired = (jwtToken) => {
        return DateUtilities.toMilliseconds(JwtUtils.toAuthData(jwtToken)?.exp || 0) <= moment().valueOf();
    };
    static toAuthData = (jwtToken) => {
        let authData = undefined;
        if (jwtToken && RA.isNotEmpty(jwtToken) && jwtToken !== INVALID_JWT_VALUE) {
            const convertedToken = JSON.parse(decode(jwtToken.split(SEPARATOR_PERIOD)[1]).toString());
            if (DateUtilities.toMilliseconds(convertedToken.exp) > moment().valueOf()) {
                authData = convertedToken;
                authData.encodedJwt = jwtToken;
                authData.exp = DateUtilities.toMilliseconds(authData.exp);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                authData.labId = convertedToken.labId ? JSON.parse(convertedToken.labId) : [];
            }
        }
        return authData;
    };
}
