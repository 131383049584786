import { v4 as uuid } from 'uuid';
export class LuminateRuntimeException extends Error {
    exceptionId;
    status;
    cause;
    static RUNTIME_ERROR_CODE = 500;
    static RUNTIME_ERROR_NAME = 'LuminateRuntimeException';
    constructor(message, cause, stack, name, status) {
        super(message, { cause });
        this.exceptionId = uuid();
        this.name = name || LuminateRuntimeException.RUNTIME_ERROR_NAME;
        this.status = status || LuminateRuntimeException.RUNTIME_ERROR_CODE;
        this.cause = cause;
        this.stack = stack;
    }
}
