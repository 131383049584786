import moment from 'moment';
export const SHORT_DATE_SLASH_FORMAT = 'MM/DD/YYYY';
export const NANO_SECOND = 1000000;
export const MILLISECOND = 1000;
export class DateUtilities {
    static minDate = () => {
        return new Date('1-1-1970');
    };
    static toMilliseconds = (value) => {
        return value * MILLISECOND;
    };
    static millisecondsToSeconds = (value) => {
        return Math.floor(value / MILLISECOND);
    };
    static nanoSecondToMilliSecond = (nano) => {
        return Math.round(nano / NANO_SECOND);
    };
    static isValid = (dateText, format) => {
        if (!dateText)
            return false;
        return moment(dateText, format).isValid();
    };
    static fromString = (dateText, format) => {
        if (!dateText)
            return;
        const result = moment(dateText, format);
        if (!result.isValid())
            return;
        return result.toDate();
    };
    static isInThePast = (value) => {
        return value.getTime() >= Date.now();
    };
    static fromLocalDateTime = (date) => {
        const year = date.year ? date.year.toString() : '0000';
        const month = date.monthValue ? date.monthValue.toString().padStart(2, '0') : '01';
        const day = date.dayOfMonth ? date.dayOfMonth.toString().padStart(2, '0') : '01';
        const hour = date.hour ? date.hour.toString().padStart(2, '0') : '00';
        const minute = date.minute ? date.minute.toString().padStart(2, '0') : '00';
        const second = date.second ? date.second.toString().padStart(2, '0') : '00';
        const millisecond = date.nano ? DateUtilities.nanoSecondToMilliSecond(date.nano).toString().padStart(3, '0') : '000';
        const stringValue = `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;
        return new Date(stringValue);
    };
    static formatAsShortDate = (date) => {
        return moment(date).format(SHORT_DATE_SLASH_FORMAT);
    };
    static formatYYYYMMDD = (date) => {
        return moment(date).format('YYYY-MM-DD');
    };
    static formatYYYYMMDDHHMM = (date) => {
        return moment(date).format('YYYY-MM-DD HH:mm');
    };
    static twoDigitNextYear = (fromDate) => {
        return DateUtilities.twoDigitYear(DateUtilities.nextYear(fromDate ? fromDate : new Date()));
    };
    static twoDigitYear = (date) => {
        return date.getFullYear().toString().substring(2, 4);
    };
    static nextYear = (fromDate = new Date()) => {
        const dateNextYear = new Date(fromDate.getTime());
        dateNextYear.setFullYear(dateNextYear.getFullYear() + 1);
        return dateNextYear;
    };
    static subtract = (fromDate, amount, unit) => {
        return moment(fromDate).subtract(amount, unit).toDate();
    };
    static subtractYears = (years, fromDate = new Date()) => {
        const start = new Date(fromDate.getTime());
        start.setFullYear(start.getFullYear() - years);
        return start;
    };
    static yearsSince = (start, end = Date.now()) => {
        if (!start) {
            return 0;
        }
        const pastDate = moment(start);
        const currDate = moment(end);
        return currDate.diff(pastDate, 'years');
    };
    static secondsSince = (start, end = Date.now()) => {
        if (!start) {
            return 0;
        }
        const pastDate = moment(start);
        const currDate = moment(end);
        return currDate.diff(pastDate, 'seconds');
    };
    static millisecondsSince = (start, end = Date.now()) => {
        if (!start) {
            return 0;
        }
        const pastDate = moment(start);
        const currDate = moment(end);
        return currDate.diff(pastDate, 'milliseconds');
    };
}
