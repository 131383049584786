import { OrderAdminControllerApi } from '@luminate/connect-api-client-ts';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
import { Pagination } from '../models';
import { OrderDtoToOrderConverter, OrderStatusToOrderDTOStatusEnumConverter, WheelFollowUpDetailsDtoToWheelFollowUpDetailsConverter } from './converter';
export class OrderAdminService {
    orderAdminApi;
    orderDtoConverter;
    orderStatusConverter;
    wheelFollowUpDetailsConverter;
    static FILE_RESPONSE_TYPE = { responseType: 'blob' };
    constructor(orderAdminApi, orderDtoConverter, orderStatusConverter, wheelFollowUpDetailsConverter) {
        this.orderAdminApi = orderAdminApi;
        this.orderDtoConverter = orderDtoConverter;
        this.orderStatusConverter = orderStatusConverter;
        this.wheelFollowUpDetailsConverter = wheelFollowUpDetailsConverter;
    }
    /**
     * Create a new Order Patient Service instance.
     * @returns {OrderPatientService} New service instance.
     */
    static create = (apiBaseUrl, authToken, orderDtoConverter, orderStatusConverter, wheelFollowUpDetailsConverter) => {
        return new OrderAdminService(new OrderAdminControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), orderDtoConverter ? orderDtoConverter : OrderDtoToOrderConverter.create(), orderStatusConverter ? orderStatusConverter : OrderStatusToOrderDTOStatusEnumConverter.create(), wheelFollowUpDetailsConverter ? wheelFollowUpDetailsConverter : WheelFollowUpDetailsDtoToWheelFollowUpDetailsConverter.create());
    };
    getOrderById = async (orderId) => {
        const response = await this.orderAdminApi.getOrderDetailsUsingGET(orderId);
        return this.orderDtoConverter.toModel(response.data);
    };
    getOrdersByFilter = async (firstName, lastName, orderNumber, email, dateOfBirth, phoneNumber, status, dateFrom, dateTo, page = Pagination.DEFAULT_PAGE, pageSize = Pagination.DEFAULT_PAGE_SIZE) => {
        const response = await this.orderAdminApi.getSearchOrdersListUsingPOST({
            patientFirstName: firstName,
            patientLastName: lastName,
            orderNumber,
            status: status ? this.orderStatusConverter.toModel(status) : undefined,
            patientEmail: email,
            patientPhone: phoneNumber,
            patientDob: dateOfBirth,
            dateFrom: dateFrom,
            dateTo: dateTo,
        }, page, pageSize);
        return response.data ? response.data.map((orderDto) => this.orderDtoConverter.toModel(orderDto)) : [];
    };
    refundPayment = async (orderId, amount, reason, additionalInfo) => {
        const response = await this.orderAdminApi.issueRefundUsingPOST(orderId, {
            reason,
            refundAmount: amount,
            other: additionalInfo,
        });
        return response.data.refundId;
    };
    fulfill = async (orderId) => {
        const response = await this.orderAdminApi.fulfillOrderUsingPOST(orderId);
        return this.orderDtoConverter.toModel(response.data);
    };
    unFulfill = async (orderId) => {
        const response = await this.orderAdminApi.unFulfillOrderUsingPOST(orderId);
        return this.orderDtoConverter.toModel(response.data);
    };
    downloadPdf = async (orderId) => {
        const response = await this.orderAdminApi.downloadOrderPdfUsingGET(orderId, OrderAdminService.FILE_RESPONSE_TYPE);
        return response.data;
    };
    getFollowUpDetails = async (orderId) => {
        const response = await this.orderAdminApi.getOrderFollowUpDetailsUsingGET(orderId);
        return this.wheelFollowUpDetailsConverter.toModel(response.data);
    };
}
