import { ScanCommand } from '@aws-sdk/client-dynamodb';
import { FilterExpressionOperation } from './FilterExpressionOperation';
export class ScanOperation extends FilterExpressionOperation {
    constructor(dynamoDbDocClient) {
        super(dynamoDbDocClient, ScanCommand, {});
    }
    withLimit = (limit) => {
        this.spec.Limit = limit;
        return this;
    };
    withSelect = (select) => {
        this.spec.Select = select;
        return this;
    };
    withExclusiveStartKey = (exclusiveStartKey) => {
        this.spec.ExclusiveStartKey = exclusiveStartKey;
        return this;
    };
    withReturnConsumedCapacity = (returnConsumedCapacity) => {
        this.spec.ReturnConsumedCapacity = returnConsumedCapacity;
        return this;
    };
    withTotalSegments = (totalSegments) => {
        this.spec.TotalSegments = totalSegments;
        return this;
    };
    withSegment = (segment) => {
        this.spec.Segment = segment;
        return this;
    };
    withProjectionExpression = (projectionExpression) => {
        this.spec.ProjectionExpression = projectionExpression;
        return this;
    };
    withConsistentRead = (consistentRead) => {
        this.spec.ConsistentRead = consistentRead;
        return this;
    };
}
