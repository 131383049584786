import { FaqPublicControllerApi } from '@luminate/connect-api-client-ts';
import { FaqCategoryDtoToFaqCategoryConverter, FaqItemDtoToFaqConverter, FaqCollectionDtoToFaqCollectionConverter } from './converter';
import { FaqApiV2 } from '../esl';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class FaqService {
    faqApi;
    faqV2Api;
    faqCategoryConverter;
    faqConverter;
    faqCollectionConverter;
    constructor(faqApi, faqV2Api, faqCategoryConverter, faqConverter, faqCollectionConverter) {
        this.faqApi = faqApi;
        this.faqV2Api = faqV2Api;
        this.faqCategoryConverter = faqCategoryConverter;
        this.faqConverter = faqConverter;
        this.faqCollectionConverter = faqCollectionConverter;
    }
    static create = (apiBaseUrl, apiV2BaseUrl) => {
        return new FaqService(new FaqPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), FaqApiV2.create(apiV2BaseUrl), FaqCategoryDtoToFaqCategoryConverter.create(), FaqItemDtoToFaqConverter.create(), FaqCollectionDtoToFaqCollectionConverter.create());
    };
    getFaqCategories = async (labId) => {
        const response = await this.faqApi.getCategoriesListUsingGET1(labId);
        return response.data ? response.data.map((category) => this.faqCategoryConverter.toModel(category)) : [];
    };
    getCategoryFaqs = async (category) => {
        const response = await this.faqApi.getFaqsListUsingGET(category);
        return response.data ? response.data.map((faq) => this.faqConverter.toModel(faq)) : [];
    };
    getFaqsByContext = async (appName, labId) => {
        const response = await this.faqV2Api.getFaqsByContext(appName, labId);
        return response.data ? response.data.map((faqCollection) => this.faqCollectionConverter.toModel(faqCollection)) : [];
    };
}
