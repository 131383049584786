export class PushDeviceRegistrationResponseToPushRegistryConverter {
    static create = () => {
        return new PushDeviceRegistrationResponseToPushRegistryConverter();
    };
    toModel = (source) => {
        return {
            token: source.token,
            userId: source.userId,
            patientId: source.patientId,
            enabled: source.enabled,
            platform: source.platform,
            registrationDate: source.registrationDate,
        };
    };
}
