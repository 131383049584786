import { AdminAppEvent, VueAdminAppEvent } from './AdminAppEvents';
export const PATIENT_SEARCH_RECALL_EVENT_NAME = 'patient-search-recall';
export class PatientSearchRecallEvent extends VueAdminAppEvent {
    constructor() {
        super({ eventName: PATIENT_SEARCH_RECALL_EVENT_NAME });
    }
    static isInstanceOf = (object) => {
        return AdminAppEvent.isInstanceOf(object, PATIENT_SEARCH_RECALL_EVENT_NAME);
    };
}
