/* tslint:disable */
/* eslint-disable */
/**
 * Luminate Health admin backend REST API
 * API description for Luminate Health admin backend REST services, authorized with JWT.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: corina@luminatehealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from './base';
export const CancelOrderRequestDTOReasonEnum = {
    Expired: 'EXPIRED',
    NoLongerNeeded: 'NO_LONGER_NEEDED',
    Other: 'OTHER',
    UnableToFast: 'UNABLE_TO_FAST',
    UnableToPerform: 'UNABLE_TO_PERFORM',
    WrongTest: 'WRONG_TEST'
};
export const ModelAndViewStatusEnum = {
    Accepted: 'ACCEPTED',
    AlreadyReported: 'ALREADY_REPORTED',
    BadGateway: 'BAD_GATEWAY',
    BadRequest: 'BAD_REQUEST',
    BandwidthLimitExceeded: 'BANDWIDTH_LIMIT_EXCEEDED',
    Checkpoint: 'CHECKPOINT',
    Conflict: 'CONFLICT',
    Continue: 'CONTINUE',
    Created: 'CREATED',
    DestinationLocked: 'DESTINATION_LOCKED',
    ExpectationFailed: 'EXPECTATION_FAILED',
    FailedDependency: 'FAILED_DEPENDENCY',
    Forbidden: 'FORBIDDEN',
    Found: 'FOUND',
    GatewayTimeout: 'GATEWAY_TIMEOUT',
    Gone: 'GONE',
    HttpVersionNotSupported: 'HTTP_VERSION_NOT_SUPPORTED',
    ImUsed: 'IM_USED',
    InsufficientSpaceOnResource: 'INSUFFICIENT_SPACE_ON_RESOURCE',
    InsufficientStorage: 'INSUFFICIENT_STORAGE',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    IAmATeapot: 'I_AM_A_TEAPOT',
    LengthRequired: 'LENGTH_REQUIRED',
    Locked: 'LOCKED',
    LoopDetected: 'LOOP_DETECTED',
    MethodFailure: 'METHOD_FAILURE',
    MethodNotAllowed: 'METHOD_NOT_ALLOWED',
    MovedPermanently: 'MOVED_PERMANENTLY',
    MovedTemporarily: 'MOVED_TEMPORARILY',
    MultipleChoices: 'MULTIPLE_CHOICES',
    MultiStatus: 'MULTI_STATUS',
    NetworkAuthenticationRequired: 'NETWORK_AUTHENTICATION_REQUIRED',
    NonAuthoritativeInformation: 'NON_AUTHORITATIVE_INFORMATION',
    NotAcceptable: 'NOT_ACCEPTABLE',
    NotExtended: 'NOT_EXTENDED',
    NotFound: 'NOT_FOUND',
    NotImplemented: 'NOT_IMPLEMENTED',
    NotModified: 'NOT_MODIFIED',
    NoContent: 'NO_CONTENT',
    Ok: 'OK',
    PartialContent: 'PARTIAL_CONTENT',
    PayloadTooLarge: 'PAYLOAD_TOO_LARGE',
    PaymentRequired: 'PAYMENT_REQUIRED',
    PermanentRedirect: 'PERMANENT_REDIRECT',
    PreconditionFailed: 'PRECONDITION_FAILED',
    PreconditionRequired: 'PRECONDITION_REQUIRED',
    Processing: 'PROCESSING',
    ProxyAuthenticationRequired: 'PROXY_AUTHENTICATION_REQUIRED',
    RequestedRangeNotSatisfiable: 'REQUESTED_RANGE_NOT_SATISFIABLE',
    RequestEntityTooLarge: 'REQUEST_ENTITY_TOO_LARGE',
    RequestHeaderFieldsTooLarge: 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    RequestTimeout: 'REQUEST_TIMEOUT',
    RequestUriTooLong: 'REQUEST_URI_TOO_LONG',
    ResetContent: 'RESET_CONTENT',
    SeeOther: 'SEE_OTHER',
    ServiceUnavailable: 'SERVICE_UNAVAILABLE',
    SwitchingProtocols: 'SWITCHING_PROTOCOLS',
    TemporaryRedirect: 'TEMPORARY_REDIRECT',
    TooEarly: 'TOO_EARLY',
    TooManyRequests: 'TOO_MANY_REQUESTS',
    Unauthorized: 'UNAUTHORIZED',
    UnavailableForLegalReasons: 'UNAVAILABLE_FOR_LEGAL_REASONS',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    UnsupportedMediaType: 'UNSUPPORTED_MEDIA_TYPE',
    UpgradeRequired: 'UPGRADE_REQUIRED',
    UriTooLong: 'URI_TOO_LONG',
    UseProxy: 'USE_PROXY',
    VariantAlsoNegotiates: 'VARIANT_ALSO_NEGOTIATES'
};
export const OrderCancelReasonEnum = {
    Expired: 'EXPIRED',
    NoLongerNeeded: 'NO_LONGER_NEEDED',
    Other: 'OTHER',
    UnableToFast: 'UNABLE_TO_FAST',
    UnableToPerform: 'UNABLE_TO_PERFORM',
    WrongTest: 'WRONG_TEST'
};
export const OrderStatusEnum = {
    Expired: 'EXPIRED',
    Fulfilled: 'FULFILLED',
    PaymentCompleted: 'PAYMENT_COMPLETED',
    Refund: 'REFUND'
};
export const OrderDTOCancelReasonEnum = {
    Expired: 'EXPIRED',
    NoLongerNeeded: 'NO_LONGER_NEEDED',
    Other: 'OTHER',
    UnableToFast: 'UNABLE_TO_FAST',
    UnableToPerform: 'UNABLE_TO_PERFORM',
    WrongTest: 'WRONG_TEST'
};
export const OrderDTOStatusEnum = {
    Expired: 'EXPIRED',
    Fulfilled: 'FULFILLED',
    PaymentCompleted: 'PAYMENT_COMPLETED',
    Refund: 'REFUND'
};
export const OrderRefundRequestDTOReasonEnum = {
    Expired: 'EXPIRED',
    NoLongerNeeded: 'NO_LONGER_NEEDED',
    Other: 'OTHER',
    UnableToFast: 'UNABLE_TO_FAST',
    UnableToPerform: 'UNABLE_TO_PERFORM',
    WrongTest: 'WRONG_TEST'
};
export const OrderSearchDTOStatusEnum = {
    Expired: 'EXPIRED',
    Fulfilled: 'FULFILLED',
    PaymentCompleted: 'PAYMENT_COMPLETED',
    Refund: 'REFUND'
};
export const OrderStatusHistoryDTOStatusEnum = {
    Expired: 'EXPIRED',
    Fulfilled: 'FULFILLED',
    PaymentCompleted: 'PAYMENT_COMPLETED',
    Refund: 'REFUND'
};
export const OrderableTestBundleDTOCollectionTypeEnum = {
    Bundle: 'BUNDLE',
    Panel: 'PANEL'
};
export const OrderableTestBundleDTOGenderEnum = {
    F: 'F',
    M: 'M'
};
export const OrderableTestBundleDTOSampleTypeEnum = {
    Blood: 'BLOOD',
    BloodAndUrine: 'BLOOD_AND_URINE',
    Saliva: 'SALIVA',
    Stool: 'STOOL',
    Urine: 'URINE'
};
export const OrderableTestDTOCollectionTypeEnum = {
    Bundle: 'BUNDLE',
    Panel: 'PANEL'
};
export const OrderableTestDTOGenderEnum = {
    F: 'F',
    M: 'M'
};
export const OrderableTestDTOSampleTypeEnum = {
    Blood: 'BLOOD',
    BloodAndUrine: 'BLOOD_AND_URINE',
    Saliva: 'SALIVA',
    Stool: 'STOOL',
    Urine: 'URINE'
};
export const ResponseEntityStatusCodeEnum = {
    Accepted: 'ACCEPTED',
    AlreadyReported: 'ALREADY_REPORTED',
    BadGateway: 'BAD_GATEWAY',
    BadRequest: 'BAD_REQUEST',
    BandwidthLimitExceeded: 'BANDWIDTH_LIMIT_EXCEEDED',
    Checkpoint: 'CHECKPOINT',
    Conflict: 'CONFLICT',
    Continue: 'CONTINUE',
    Created: 'CREATED',
    DestinationLocked: 'DESTINATION_LOCKED',
    ExpectationFailed: 'EXPECTATION_FAILED',
    FailedDependency: 'FAILED_DEPENDENCY',
    Forbidden: 'FORBIDDEN',
    Found: 'FOUND',
    GatewayTimeout: 'GATEWAY_TIMEOUT',
    Gone: 'GONE',
    HttpVersionNotSupported: 'HTTP_VERSION_NOT_SUPPORTED',
    ImUsed: 'IM_USED',
    InsufficientSpaceOnResource: 'INSUFFICIENT_SPACE_ON_RESOURCE',
    InsufficientStorage: 'INSUFFICIENT_STORAGE',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    IAmATeapot: 'I_AM_A_TEAPOT',
    LengthRequired: 'LENGTH_REQUIRED',
    Locked: 'LOCKED',
    LoopDetected: 'LOOP_DETECTED',
    MethodFailure: 'METHOD_FAILURE',
    MethodNotAllowed: 'METHOD_NOT_ALLOWED',
    MovedPermanently: 'MOVED_PERMANENTLY',
    MovedTemporarily: 'MOVED_TEMPORARILY',
    MultipleChoices: 'MULTIPLE_CHOICES',
    MultiStatus: 'MULTI_STATUS',
    NetworkAuthenticationRequired: 'NETWORK_AUTHENTICATION_REQUIRED',
    NonAuthoritativeInformation: 'NON_AUTHORITATIVE_INFORMATION',
    NotAcceptable: 'NOT_ACCEPTABLE',
    NotExtended: 'NOT_EXTENDED',
    NotFound: 'NOT_FOUND',
    NotImplemented: 'NOT_IMPLEMENTED',
    NotModified: 'NOT_MODIFIED',
    NoContent: 'NO_CONTENT',
    Ok: 'OK',
    PartialContent: 'PARTIAL_CONTENT',
    PayloadTooLarge: 'PAYLOAD_TOO_LARGE',
    PaymentRequired: 'PAYMENT_REQUIRED',
    PermanentRedirect: 'PERMANENT_REDIRECT',
    PreconditionFailed: 'PRECONDITION_FAILED',
    PreconditionRequired: 'PRECONDITION_REQUIRED',
    Processing: 'PROCESSING',
    ProxyAuthenticationRequired: 'PROXY_AUTHENTICATION_REQUIRED',
    RequestedRangeNotSatisfiable: 'REQUESTED_RANGE_NOT_SATISFIABLE',
    RequestEntityTooLarge: 'REQUEST_ENTITY_TOO_LARGE',
    RequestHeaderFieldsTooLarge: 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    RequestTimeout: 'REQUEST_TIMEOUT',
    RequestUriTooLong: 'REQUEST_URI_TOO_LONG',
    ResetContent: 'RESET_CONTENT',
    SeeOther: 'SEE_OTHER',
    ServiceUnavailable: 'SERVICE_UNAVAILABLE',
    SwitchingProtocols: 'SWITCHING_PROTOCOLS',
    TemporaryRedirect: 'TEMPORARY_REDIRECT',
    TooEarly: 'TOO_EARLY',
    TooManyRequests: 'TOO_MANY_REQUESTS',
    Unauthorized: 'UNAUTHORIZED',
    UnavailableForLegalReasons: 'UNAVAILABLE_FOR_LEGAL_REASONS',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    UnsupportedMediaType: 'UNSUPPORTED_MEDIA_TYPE',
    UpgradeRequired: 'UPGRADE_REQUIRED',
    UriTooLong: 'URI_TOO_LONG',
    UseProxy: 'USE_PROXY',
    VariantAlsoNegotiates: 'VARIANT_ALSO_NEGOTIATES'
};
export const WheelConsultDispositionEnum = {
    AutoReferredOut: 'AUTO_REFERRED_OUT',
    Diagnosed: 'DIAGNOSED',
    InsufficientPatientId: 'INSUFFICIENT_PATIENT_ID',
    LabFollowUpComplete: 'LAB_FOLLOW_UP_COMPLETE',
    LabFollowUpNeedsAttention: 'LAB_FOLLOW_UP_NEEDS_ATTENTION',
    LabOrderApproved: 'LAB_ORDER_APPROVED',
    LabOrderRejected: 'LAB_ORDER_REJECTED',
    NullValue: 'NULL_VALUE',
    OtherCancellation: 'OTHER_CANCELLATION',
    PatientCancellation: 'PATIENT_CANCELLATION',
    PatienNoShow: 'PATIEN_NO_SHOW',
    ReferredOut: 'REFERRED_OUT'
};
export const WheelConsultStatusEnum = {
    CancelationInProgress: 'CANCELATION_IN_PROGRESS',
    Canceled: 'CANCELED',
    Error: 'ERROR',
    Finished: 'FINISHED',
    InProgress: 'IN_PROGRESS',
    Planned: 'PLANNED'
};
export const WheelConsultDtoDispositionEnum = {
    AutoReferredOut: 'AUTO_REFERRED_OUT',
    Diagnosed: 'DIAGNOSED',
    InsufficientPatientId: 'INSUFFICIENT_PATIENT_ID',
    LabFollowUpComplete: 'LAB_FOLLOW_UP_COMPLETE',
    LabFollowUpNeedsAttention: 'LAB_FOLLOW_UP_NEEDS_ATTENTION',
    LabOrderApproved: 'LAB_ORDER_APPROVED',
    LabOrderRejected: 'LAB_ORDER_REJECTED',
    NullValue: 'NULL_VALUE',
    OtherCancellation: 'OTHER_CANCELLATION',
    PatientCancellation: 'PATIENT_CANCELLATION',
    PatienNoShow: 'PATIEN_NO_SHOW',
    ReferredOut: 'REFERRED_OUT'
};
export const WheelConsultDtoStatusEnum = {
    CancelationInProgress: 'CANCELATION_IN_PROGRESS',
    Canceled: 'CANCELED',
    Error: 'ERROR',
    Finished: 'FINISHED',
    InProgress: 'IN_PROGRESS',
    Planned: 'PLANNED'
};
/**
 * AcknowledgmentPublicControllerApi - axios parameter creator
 * @export
 */
export const AcknowledgmentPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List of DAT acknowledgements by white label lab context.
         * @summary getAcknowledgmentsByWhiteLabelDomain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcknowledgmentsByWhiteLabelDomainUsingGET: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/public/acknowledgments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AcknowledgmentPublicControllerApi - functional programming interface
 * @export
 */
export const AcknowledgmentPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = AcknowledgmentPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * List of DAT acknowledgements by white label lab context.
         * @summary getAcknowledgmentsByWhiteLabelDomain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcknowledgmentsByWhiteLabelDomainUsingGET(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAcknowledgmentsByWhiteLabelDomainUsingGET(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AcknowledgmentPublicControllerApi - factory interface
 * @export
 */
export const AcknowledgmentPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AcknowledgmentPublicControllerApiFp(configuration);
    return {
        /**
         * List of DAT acknowledgements by white label lab context.
         * @summary getAcknowledgmentsByWhiteLabelDomain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcknowledgmentsByWhiteLabelDomainUsingGET(options) {
            return localVarFp.getAcknowledgmentsByWhiteLabelDomainUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AcknowledgmentPublicControllerApi - object-oriented interface
 * @export
 * @class AcknowledgmentPublicControllerApi
 * @extends {BaseAPI}
 */
export class AcknowledgmentPublicControllerApi extends BaseAPI {
    /**
     * List of DAT acknowledgements by white label lab context.
     * @summary getAcknowledgmentsByWhiteLabelDomain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcknowledgmentPublicControllerApi
     */
    getAcknowledgmentsByWhiteLabelDomainUsingGET(options) {
        return AcknowledgmentPublicControllerApiFp(this.configuration).getAcknowledgmentsByWhiteLabelDomainUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BasicErrorControllerApi - axios parameter creator
 * @export
 */
export const BasicErrorControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingDELETE: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingGET: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingHEAD: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'HEAD' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingOPTIONS: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'OPTIONS' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPATCH: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPOST: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPUT: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BasicErrorControllerApi - functional programming interface
 * @export
 */
export const BasicErrorControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BasicErrorControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingDELETE(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.errorUsingDELETE(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingGET(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.errorUsingGET(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingHEAD(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.errorUsingHEAD(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingOPTIONS(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.errorUsingOPTIONS(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPATCH(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.errorUsingPATCH(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPOST(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.errorUsingPOST(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPUT(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.errorUsingPUT(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * BasicErrorControllerApi - factory interface
 * @export
 */
export const BasicErrorControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BasicErrorControllerApiFp(configuration);
    return {
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingDELETE(options) {
            return localVarFp.errorUsingDELETE(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingGET(options) {
            return localVarFp.errorUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingHEAD(options) {
            return localVarFp.errorUsingHEAD(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingOPTIONS(options) {
            return localVarFp.errorUsingOPTIONS(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPATCH(options) {
            return localVarFp.errorUsingPATCH(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPOST(options) {
            return localVarFp.errorUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPUT(options) {
            return localVarFp.errorUsingPUT(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BasicErrorControllerApi - object-oriented interface
 * @export
 * @class BasicErrorControllerApi
 * @extends {BaseAPI}
 */
export class BasicErrorControllerApi extends BaseAPI {
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    errorUsingDELETE(options) {
        return BasicErrorControllerApiFp(this.configuration).errorUsingDELETE(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    errorUsingGET(options) {
        return BasicErrorControllerApiFp(this.configuration).errorUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    errorUsingHEAD(options) {
        return BasicErrorControllerApiFp(this.configuration).errorUsingHEAD(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    errorUsingOPTIONS(options) {
        return BasicErrorControllerApiFp(this.configuration).errorUsingOPTIONS(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    errorUsingPATCH(options) {
        return BasicErrorControllerApiFp(this.configuration).errorUsingPATCH(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    errorUsingPOST(options) {
        return BasicErrorControllerApiFp(this.configuration).errorUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    errorUsingPUT(options) {
        return BasicErrorControllerApiFp(this.configuration).errorUsingPUT(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * CategoryAdminControllerApi - axios parameter creator
 * @export
 */
export const CategoryAdminControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create new lab test category
         * @summary createCategory
         * @param {CategoryDTO} categoryDTO categoryDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategoryUsingPOST: (categoryDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryDTO' is not null or undefined
            assertParamExists('createCategoryUsingPOST', 'categoryDTO', categoryDTO);
            const localVarPath = `/connect/api/dat/admin/category/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(categoryDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update lab test category
         * @summary deleteCategory
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryUsingPOST: (categoryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('deleteCategoryUsingPOST', 'categoryId', categoryId);
            const localVarPath = `/connect/api/dat/admin/category/{categoryId}/delete`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update lab test category
         * @summary updateCategory
         * @param {CategoryDTO} categoryDTO categoryDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryUsingPOST: (categoryDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryDTO' is not null or undefined
            assertParamExists('updateCategoryUsingPOST', 'categoryDTO', categoryDTO);
            const localVarPath = `/connect/api/dat/admin/category/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(categoryDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CategoryAdminControllerApi - functional programming interface
 * @export
 */
export const CategoryAdminControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = CategoryAdminControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Create new lab test category
         * @summary createCategory
         * @param {CategoryDTO} categoryDTO categoryDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategoryUsingPOST(categoryDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createCategoryUsingPOST(categoryDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Update lab test category
         * @summary deleteCategory
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryUsingPOST(categoryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteCategoryUsingPOST(categoryId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Update lab test category
         * @summary updateCategory
         * @param {CategoryDTO} categoryDTO categoryDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryUsingPOST(categoryDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateCategoryUsingPOST(categoryDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * CategoryAdminControllerApi - factory interface
 * @export
 */
export const CategoryAdminControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = CategoryAdminControllerApiFp(configuration);
    return {
        /**
         * Create new lab test category
         * @summary createCategory
         * @param {CategoryDTO} categoryDTO categoryDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategoryUsingPOST(categoryDTO, options) {
            return localVarFp.createCategoryUsingPOST(categoryDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update lab test category
         * @summary deleteCategory
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryUsingPOST(categoryId, options) {
            return localVarFp.deleteCategoryUsingPOST(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update lab test category
         * @summary updateCategory
         * @param {CategoryDTO} categoryDTO categoryDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryUsingPOST(categoryDTO, options) {
            return localVarFp.updateCategoryUsingPOST(categoryDTO, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CategoryAdminControllerApi - object-oriented interface
 * @export
 * @class CategoryAdminControllerApi
 * @extends {BaseAPI}
 */
export class CategoryAdminControllerApi extends BaseAPI {
    /**
     * Create new lab test category
     * @summary createCategory
     * @param {CategoryDTO} categoryDTO categoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryAdminControllerApi
     */
    createCategoryUsingPOST(categoryDTO, options) {
        return CategoryAdminControllerApiFp(this.configuration).createCategoryUsingPOST(categoryDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update lab test category
     * @summary deleteCategory
     * @param {number} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryAdminControllerApi
     */
    deleteCategoryUsingPOST(categoryId, options) {
        return CategoryAdminControllerApiFp(this.configuration).deleteCategoryUsingPOST(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update lab test category
     * @summary updateCategory
     * @param {CategoryDTO} categoryDTO categoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryAdminControllerApi
     */
    updateCategoryUsingPOST(categoryDTO, options) {
        return CategoryAdminControllerApiFp(this.configuration).updateCategoryUsingPOST(categoryDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * CategoryPublicControllerApi - axios parameter creator
 * @export
 */
export const CategoryPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * View list of categories by lab id
         * @summary getCategoriesList
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesListUsingGET: (labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getCategoriesListUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/category/{labId}/list`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of featured categories by lab id
         * @summary getFeaturedCategoriesList
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedCategoriesListUsingGET: (labId, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getFeaturedCategoriesListUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/category/{labId}/list/featured`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CategoryPublicControllerApi - functional programming interface
 * @export
 */
export const CategoryPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = CategoryPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * View list of categories by lab id
         * @summary getCategoriesList
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesListUsingGET(labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCategoriesListUsingGET(labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of featured categories by lab id
         * @summary getFeaturedCategoriesList
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedCategoriesListUsingGET(labId, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFeaturedCategoriesListUsingGET(labId, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * CategoryPublicControllerApi - factory interface
 * @export
 */
export const CategoryPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = CategoryPublicControllerApiFp(configuration);
    return {
        /**
         * View list of categories by lab id
         * @summary getCategoriesList
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesListUsingGET(labId, options) {
            return localVarFp.getCategoriesListUsingGET(labId, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of featured categories by lab id
         * @summary getFeaturedCategoriesList
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedCategoriesListUsingGET(labId, page, size, options) {
            return localVarFp.getFeaturedCategoriesListUsingGET(labId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CategoryPublicControllerApi - object-oriented interface
 * @export
 * @class CategoryPublicControllerApi
 * @extends {BaseAPI}
 */
export class CategoryPublicControllerApi extends BaseAPI {
    /**
     * View list of categories by lab id
     * @summary getCategoriesList
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPublicControllerApi
     */
    getCategoriesListUsingGET(labId, options) {
        return CategoryPublicControllerApiFp(this.configuration).getCategoriesListUsingGET(labId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of featured categories by lab id
     * @summary getFeaturedCategoriesList
     * @param {number} labId labId
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPublicControllerApi
     */
    getFeaturedCategoriesListUsingGET(labId, page, size, options) {
        return CategoryPublicControllerApiFp(this.configuration).getFeaturedCategoriesListUsingGET(labId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * ConfigControllerApi - axios parameter creator
 * @export
 */
export const ConfigControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieve Admin Application Configuration
         * @summary getApplicationConfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationConfigurationUsingGET: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/admin/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve Admin Application Configuration By Lab
         * @summary getApplicationConfiguration
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationConfigurationUsingGET1: (labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getApplicationConfigurationUsingGET1', 'labId', labId);
            const localVarPath = `/connect/api/admin/config/{labId}`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ConfigControllerApi - functional programming interface
 * @export
 */
export const ConfigControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = ConfigControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieve Admin Application Configuration
         * @summary getApplicationConfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationConfigurationUsingGET(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getApplicationConfigurationUsingGET(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve Admin Application Configuration By Lab
         * @summary getApplicationConfiguration
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationConfigurationUsingGET1(labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getApplicationConfigurationUsingGET1(labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * ConfigControllerApi - factory interface
 * @export
 */
export const ConfigControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ConfigControllerApiFp(configuration);
    return {
        /**
         * Retrieve Admin Application Configuration
         * @summary getApplicationConfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationConfigurationUsingGET(options) {
            return localVarFp.getApplicationConfigurationUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Admin Application Configuration By Lab
         * @summary getApplicationConfiguration
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationConfigurationUsingGET1(labId, options) {
            return localVarFp.getApplicationConfigurationUsingGET1(labId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ConfigControllerApi - object-oriented interface
 * @export
 * @class ConfigControllerApi
 * @extends {BaseAPI}
 */
export class ConfigControllerApi extends BaseAPI {
    /**
     * Retrieve Admin Application Configuration
     * @summary getApplicationConfiguration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigControllerApi
     */
    getApplicationConfigurationUsingGET(options) {
        return ConfigControllerApiFp(this.configuration).getApplicationConfigurationUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve Admin Application Configuration By Lab
     * @summary getApplicationConfiguration
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigControllerApi
     */
    getApplicationConfigurationUsingGET1(labId, options) {
        return ConfigControllerApiFp(this.configuration).getApplicationConfigurationUsingGET1(labId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DatComponentControllerApi - axios parameter creator
 * @export
 */
export const DatComponentControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * DAT Components JSON content.
         * @summary getDatComponents
         * @param {Array<'DAT_CANCELLATION_POLICY' | 'DAT_EXPIRATION_POLICY' | 'DAT_ORDER_CHECK_EMAIL' | 'DAT_PREPARING_FOR_YOUR_TESTS' | 'DAT_PURCHASING_CONDITIONS' | 'DAT_RECEIVING_RESULTS' | 'DAT_SCHEDULING_TESTS_CART' | 'DAT_SCHEDULING_TESTS_PURCHASING'>} componentNames componentNames
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatComponentsUsingGET: (componentNames, language, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'componentNames' is not null or undefined
            assertParamExists('getDatComponentsUsingGET', 'componentNames', componentNames);
            const localVarPath = `/connect/api/dat/public/components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (componentNames) {
                localVarQueryParameter['componentNames'] = componentNames;
            }
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DatComponentControllerApi - functional programming interface
 * @export
 */
export const DatComponentControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = DatComponentControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * DAT Components JSON content.
         * @summary getDatComponents
         * @param {Array<'DAT_CANCELLATION_POLICY' | 'DAT_EXPIRATION_POLICY' | 'DAT_ORDER_CHECK_EMAIL' | 'DAT_PREPARING_FOR_YOUR_TESTS' | 'DAT_PURCHASING_CONDITIONS' | 'DAT_RECEIVING_RESULTS' | 'DAT_SCHEDULING_TESTS_CART' | 'DAT_SCHEDULING_TESTS_PURCHASING'>} componentNames componentNames
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatComponentsUsingGET(componentNames, language, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDatComponentsUsingGET(componentNames, language, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * DatComponentControllerApi - factory interface
 * @export
 */
export const DatComponentControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DatComponentControllerApiFp(configuration);
    return {
        /**
         * DAT Components JSON content.
         * @summary getDatComponents
         * @param {Array<'DAT_CANCELLATION_POLICY' | 'DAT_EXPIRATION_POLICY' | 'DAT_ORDER_CHECK_EMAIL' | 'DAT_PREPARING_FOR_YOUR_TESTS' | 'DAT_PURCHASING_CONDITIONS' | 'DAT_RECEIVING_RESULTS' | 'DAT_SCHEDULING_TESTS_CART' | 'DAT_SCHEDULING_TESTS_PURCHASING'>} componentNames componentNames
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatComponentsUsingGET(componentNames, language, options) {
            return localVarFp.getDatComponentsUsingGET(componentNames, language, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DatComponentControllerApi - object-oriented interface
 * @export
 * @class DatComponentControllerApi
 * @extends {BaseAPI}
 */
export class DatComponentControllerApi extends BaseAPI {
    /**
     * DAT Components JSON content.
     * @summary getDatComponents
     * @param {Array<'DAT_CANCELLATION_POLICY' | 'DAT_EXPIRATION_POLICY' | 'DAT_ORDER_CHECK_EMAIL' | 'DAT_PREPARING_FOR_YOUR_TESTS' | 'DAT_PURCHASING_CONDITIONS' | 'DAT_RECEIVING_RESULTS' | 'DAT_SCHEDULING_TESTS_CART' | 'DAT_SCHEDULING_TESTS_PURCHASING'>} componentNames componentNames
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatComponentControllerApi
     */
    getDatComponentsUsingGET(componentNames, language, options) {
        return DatComponentControllerApiFp(this.configuration).getDatComponentsUsingGET(componentNames, language, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DatFastingPublicControllerApi - axios parameter creator
 * @export
 */
export const DatFastingPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * DAT Fasting JSON content.
         * @summary getFastingContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFastingContentByWhiteLabelDomainUsingGET: (language, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/public/fasting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DatFastingPublicControllerApi - functional programming interface
 * @export
 */
export const DatFastingPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = DatFastingPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * DAT Fasting JSON content.
         * @summary getFastingContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFastingContentByWhiteLabelDomainUsingGET(language, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFastingContentByWhiteLabelDomainUsingGET(language, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * DatFastingPublicControllerApi - factory interface
 * @export
 */
export const DatFastingPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DatFastingPublicControllerApiFp(configuration);
    return {
        /**
         * DAT Fasting JSON content.
         * @summary getFastingContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFastingContentByWhiteLabelDomainUsingGET(language, options) {
            return localVarFp.getFastingContentByWhiteLabelDomainUsingGET(language, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DatFastingPublicControllerApi - object-oriented interface
 * @export
 * @class DatFastingPublicControllerApi
 * @extends {BaseAPI}
 */
export class DatFastingPublicControllerApi extends BaseAPI {
    /**
     * DAT Fasting JSON content.
     * @summary getFastingContentByWhiteLabelDomain
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatFastingPublicControllerApi
     */
    getFastingContentByWhiteLabelDomainUsingGET(language, options) {
        return DatFastingPublicControllerApiFp(this.configuration).getFastingContentByWhiteLabelDomainUsingGET(language, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DatHowItWorksPublicControllerApi - axios parameter creator
 * @export
 */
export const DatHowItWorksPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * DAT How it Works JSON content.
         * @summary getHowItWorksContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHowItWorksContentByWhiteLabelDomainUsingGET: (language, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/public/how-it-works`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DatHowItWorksPublicControllerApi - functional programming interface
 * @export
 */
export const DatHowItWorksPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = DatHowItWorksPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * DAT How it Works JSON content.
         * @summary getHowItWorksContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHowItWorksContentByWhiteLabelDomainUsingGET(language, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getHowItWorksContentByWhiteLabelDomainUsingGET(language, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * DatHowItWorksPublicControllerApi - factory interface
 * @export
 */
export const DatHowItWorksPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DatHowItWorksPublicControllerApiFp(configuration);
    return {
        /**
         * DAT How it Works JSON content.
         * @summary getHowItWorksContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHowItWorksContentByWhiteLabelDomainUsingGET(language, options) {
            return localVarFp.getHowItWorksContentByWhiteLabelDomainUsingGET(language, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DatHowItWorksPublicControllerApi - object-oriented interface
 * @export
 * @class DatHowItWorksPublicControllerApi
 * @extends {BaseAPI}
 */
export class DatHowItWorksPublicControllerApi extends BaseAPI {
    /**
     * DAT How it Works JSON content.
     * @summary getHowItWorksContentByWhiteLabelDomain
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatHowItWorksPublicControllerApi
     */
    getHowItWorksContentByWhiteLabelDomainUsingGET(language, options) {
        return DatHowItWorksPublicControllerApiFp(this.configuration).getHowItWorksContentByWhiteLabelDomainUsingGET(language, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DatServiceAreasPublicControllerApi - axios parameter creator
 * @export
 */
export const DatServiceAreasPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * DAT Service Areas JSON content.
         * @summary getDatServiceAreasContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatServiceAreasContentByWhiteLabelDomainUsingGET: (language, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/public/service-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DatServiceAreasPublicControllerApi - functional programming interface
 * @export
 */
export const DatServiceAreasPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = DatServiceAreasPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * DAT Service Areas JSON content.
         * @summary getDatServiceAreasContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatServiceAreasContentByWhiteLabelDomainUsingGET(language, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDatServiceAreasContentByWhiteLabelDomainUsingGET(language, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * DatServiceAreasPublicControllerApi - factory interface
 * @export
 */
export const DatServiceAreasPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DatServiceAreasPublicControllerApiFp(configuration);
    return {
        /**
         * DAT Service Areas JSON content.
         * @summary getDatServiceAreasContentByWhiteLabelDomain
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatServiceAreasContentByWhiteLabelDomainUsingGET(language, options) {
            return localVarFp.getDatServiceAreasContentByWhiteLabelDomainUsingGET(language, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DatServiceAreasPublicControllerApi - object-oriented interface
 * @export
 * @class DatServiceAreasPublicControllerApi
 * @extends {BaseAPI}
 */
export class DatServiceAreasPublicControllerApi extends BaseAPI {
    /**
     * DAT Service Areas JSON content.
     * @summary getDatServiceAreasContentByWhiteLabelDomain
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatServiceAreasPublicControllerApi
     */
    getDatServiceAreasContentByWhiteLabelDomainUsingGET(language, options) {
        return DatServiceAreasPublicControllerApiFp(this.configuration).getDatServiceAreasContentByWhiteLabelDomainUsingGET(language, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * FaqPublicControllerApi - axios parameter creator
 * @export
 */
export const FaqPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * View list of categories by lab id
         * @summary getCategoriesList
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesListUsingGET1: (labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getCategoriesListUsingGET1', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/faq/{labId}/categories`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of faqs by category id
         * @summary getFaqsList
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqsListUsingGET: (categoryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getFaqsListUsingGET', 'categoryId', categoryId);
            const localVarPath = `/connect/api/dat/public/faq/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * FaqPublicControllerApi - functional programming interface
 * @export
 */
export const FaqPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = FaqPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * View list of categories by lab id
         * @summary getCategoriesList
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesListUsingGET1(labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCategoriesListUsingGET1(labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of faqs by category id
         * @summary getFaqsList
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqsListUsingGET(categoryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFaqsListUsingGET(categoryId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * FaqPublicControllerApi - factory interface
 * @export
 */
export const FaqPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = FaqPublicControllerApiFp(configuration);
    return {
        /**
         * View list of categories by lab id
         * @summary getCategoriesList
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesListUsingGET1(labId, options) {
            return localVarFp.getCategoriesListUsingGET1(labId, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of faqs by category id
         * @summary getFaqsList
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqsListUsingGET(categoryId, options) {
            return localVarFp.getFaqsListUsingGET(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * FaqPublicControllerApi - object-oriented interface
 * @export
 * @class FaqPublicControllerApi
 * @extends {BaseAPI}
 */
export class FaqPublicControllerApi extends BaseAPI {
    /**
     * View list of categories by lab id
     * @summary getCategoriesList
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqPublicControllerApi
     */
    getCategoriesListUsingGET1(labId, options) {
        return FaqPublicControllerApiFp(this.configuration).getCategoriesListUsingGET1(labId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of faqs by category id
     * @summary getFaqsList
     * @param {number} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqPublicControllerApi
     */
    getFaqsListUsingGET(categoryId, options) {
        return FaqPublicControllerApiFp(this.configuration).getFaqsListUsingGET(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * IndividualTestAdminControllerApi - axios parameter creator
 * @export
 */
export const IndividualTestAdminControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create new lab individual test
         * @summary createIndividualTest
         * @param {IndividualTestDTO} individualTestDTO individualTestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividualTestUsingPOST: (individualTestDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'individualTestDTO' is not null or undefined
            assertParamExists('createIndividualTestUsingPOST', 'individualTestDTO', individualTestDTO);
            const localVarPath = `/connect/api/dat/admin/individual-test/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(individualTestDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of individual tests by lab id
         * @summary listIndividualTest
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIndividualTestUsingGET: (page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/admin/individual-test/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update lab individual test
         * @summary updateIndividualTest
         * @param {IndividualTestDTO} individualTestDTO individualTestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualTestUsingPOST: (individualTestDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'individualTestDTO' is not null or undefined
            assertParamExists('updateIndividualTestUsingPOST', 'individualTestDTO', individualTestDTO);
            const localVarPath = `/connect/api/dat/admin/individual-test/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(individualTestDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * IndividualTestAdminControllerApi - functional programming interface
 * @export
 */
export const IndividualTestAdminControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = IndividualTestAdminControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Create new lab individual test
         * @summary createIndividualTest
         * @param {IndividualTestDTO} individualTestDTO individualTestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividualTestUsingPOST(individualTestDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createIndividualTestUsingPOST(individualTestDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of individual tests by lab id
         * @summary listIndividualTest
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIndividualTestUsingGET(page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listIndividualTestUsingGET(page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Update lab individual test
         * @summary updateIndividualTest
         * @param {IndividualTestDTO} individualTestDTO individualTestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualTestUsingPOST(individualTestDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateIndividualTestUsingPOST(individualTestDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * IndividualTestAdminControllerApi - factory interface
 * @export
 */
export const IndividualTestAdminControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = IndividualTestAdminControllerApiFp(configuration);
    return {
        /**
         * Create new lab individual test
         * @summary createIndividualTest
         * @param {IndividualTestDTO} individualTestDTO individualTestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividualTestUsingPOST(individualTestDTO, options) {
            return localVarFp.createIndividualTestUsingPOST(individualTestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of individual tests by lab id
         * @summary listIndividualTest
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIndividualTestUsingGET(page, size, options) {
            return localVarFp.listIndividualTestUsingGET(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Update lab individual test
         * @summary updateIndividualTest
         * @param {IndividualTestDTO} individualTestDTO individualTestDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualTestUsingPOST(individualTestDTO, options) {
            return localVarFp.updateIndividualTestUsingPOST(individualTestDTO, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * IndividualTestAdminControllerApi - object-oriented interface
 * @export
 * @class IndividualTestAdminControllerApi
 * @extends {BaseAPI}
 */
export class IndividualTestAdminControllerApi extends BaseAPI {
    /**
     * Create new lab individual test
     * @summary createIndividualTest
     * @param {IndividualTestDTO} individualTestDTO individualTestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualTestAdminControllerApi
     */
    createIndividualTestUsingPOST(individualTestDTO, options) {
        return IndividualTestAdminControllerApiFp(this.configuration).createIndividualTestUsingPOST(individualTestDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of individual tests by lab id
     * @summary listIndividualTest
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualTestAdminControllerApi
     */
    listIndividualTestUsingGET(page, size, options) {
        return IndividualTestAdminControllerApiFp(this.configuration).listIndividualTestUsingGET(page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update lab individual test
     * @summary updateIndividualTest
     * @param {IndividualTestDTO} individualTestDTO individualTestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualTestAdminControllerApi
     */
    updateIndividualTestUsingPOST(individualTestDTO, options) {
        return IndividualTestAdminControllerApiFp(this.configuration).updateIndividualTestUsingPOST(individualTestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * LabEntityAdminControllerApi - axios parameter creator
 * @export
 */
export const LabEntityAdminControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary getLabs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabsUsingGET: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/admin/labs/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * LabEntityAdminControllerApi - functional programming interface
 * @export
 */
export const LabEntityAdminControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = LabEntityAdminControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary getLabs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabsUsingGET(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLabsUsingGET(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * LabEntityAdminControllerApi - factory interface
 * @export
 */
export const LabEntityAdminControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = LabEntityAdminControllerApiFp(configuration);
    return {
        /**
         *
         * @summary getLabs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabsUsingGET(options) {
            return localVarFp.getLabsUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * LabEntityAdminControllerApi - object-oriented interface
 * @export
 * @class LabEntityAdminControllerApi
 * @extends {BaseAPI}
 */
export class LabEntityAdminControllerApi extends BaseAPI {
    /**
     *
     * @summary getLabs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabEntityAdminControllerApi
     */
    getLabsUsingGET(options) {
        return LabEntityAdminControllerApiFp(this.configuration).getLabsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * LabLocationAdminControllerApi - axios parameter creator
 * @export
 */
export const LabLocationAdminControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add additional hour listing(s) for a fulfilment location for a lab (as administrator)
         * @summary addHoursToLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {Array<string>} hours hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHoursToLabLocationUsingPOST: (labId, locationId, hours, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('addHoursToLabLocationUsingPOST', 'labId', labId);
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('addHoursToLabLocationUsingPOST', 'locationId', locationId);
            // verify required parameter 'hours' is not null or undefined
            assertParamExists('addHoursToLabLocationUsingPOST', 'hours', hours);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations/{locationId}/hours`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(hours, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Create a new fulfilment location listing for a lab (as administrator)
         * @summary createLabLocation
         * @param {number} labId labId
         * @param {CreateLabLocationRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabLocationUsingPOST: (labId, request, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('createLabLocationUsingPOST', 'labId', labId);
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createLabLocationUsingPOST', 'request', request);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get hour listing(s) for a fulfilment location for a lab (as administrator)
         * @summary getHoursForLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoursForLabLocationUsingGET: (labId, locationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getHoursForLabLocationUsingGET', 'labId', labId);
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getHoursForLabLocationUsingGET', 'locationId', locationId);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations/{locationId}/hours`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List all fulfilment locations for a lab (as administrator)
         * @summary getLabLocations
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabLocationsUsingGET: (labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getLabLocationsUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Remove an hour listing for a fulfilment location for a lab (as administrator)
         * @summary removeHoursListingForLabLocation
         * @param {number} hourId hourId
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeHoursListingForLabLocationUsingDELETE: (hourId, labId, locationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'hourId' is not null or undefined
            assertParamExists('removeHoursListingForLabLocationUsingDELETE', 'hourId', hourId);
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('removeHoursListingForLabLocationUsingDELETE', 'labId', labId);
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('removeHoursListingForLabLocationUsingDELETE', 'locationId', locationId);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations/{locationId}/hours/{hourId}`
                .replace(`{${"hourId"}}`, encodeURIComponent(String(hourId)))
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Remove a fulfilment location listing for a lab (as administrator)
         * @summary removeLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLabLocationUsingDELETE: (labId, locationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('removeLabLocationUsingDELETE', 'labId', labId);
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('removeLabLocationUsingDELETE', 'locationId', locationId);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations/{locationId}`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update an hour listing for a fulfilment location for a lab (as administrator)
         * @summary updateHoursListingForLabLocation
         * @param {number} hourId hourId
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {string} hours hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoursListingForLabLocationUsingPUT: (hourId, labId, locationId, hours, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'hourId' is not null or undefined
            assertParamExists('updateHoursListingForLabLocationUsingPUT', 'hourId', hourId);
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('updateHoursListingForLabLocationUsingPUT', 'labId', labId);
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('updateHoursListingForLabLocationUsingPUT', 'locationId', locationId);
            // verify required parameter 'hours' is not null or undefined
            assertParamExists('updateHoursListingForLabLocationUsingPUT', 'hours', hours);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations/{locationId}/hours/{hourId}`
                .replace(`{${"hourId"}}`, encodeURIComponent(String(hourId)))
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(hours, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update the details for a fulfilment location for a lab (as administrator)
         * @summary updateLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {UpdateLabLocationRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabLocationUsingPUT: (labId, locationId, request, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('updateLabLocationUsingPUT', 'labId', labId);
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('updateLabLocationUsingPUT', 'locationId', locationId);
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateLabLocationUsingPUT', 'request', request);
            const localVarPath = `/connect/api/dat/admin/labs/{labId}/locations/{locationId}`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * LabLocationAdminControllerApi - functional programming interface
 * @export
 */
export const LabLocationAdminControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = LabLocationAdminControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Add additional hour listing(s) for a fulfilment location for a lab (as administrator)
         * @summary addHoursToLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {Array<string>} hours hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHoursToLabLocationUsingPOST(labId, locationId, hours, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addHoursToLabLocationUsingPOST(labId, locationId, hours, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Create a new fulfilment location listing for a lab (as administrator)
         * @summary createLabLocation
         * @param {number} labId labId
         * @param {CreateLabLocationRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabLocationUsingPOST(labId, request, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createLabLocationUsingPOST(labId, request, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Get hour listing(s) for a fulfilment location for a lab (as administrator)
         * @summary getHoursForLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoursForLabLocationUsingGET(labId, locationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getHoursForLabLocationUsingGET(labId, locationId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * List all fulfilment locations for a lab (as administrator)
         * @summary getLabLocations
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabLocationsUsingGET(labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLabLocationsUsingGET(labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Remove an hour listing for a fulfilment location for a lab (as administrator)
         * @summary removeHoursListingForLabLocation
         * @param {number} hourId hourId
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeHoursListingForLabLocationUsingDELETE(hourId, labId, locationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeHoursListingForLabLocationUsingDELETE(hourId, labId, locationId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Remove a fulfilment location listing for a lab (as administrator)
         * @summary removeLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLabLocationUsingDELETE(labId, locationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeLabLocationUsingDELETE(labId, locationId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Update an hour listing for a fulfilment location for a lab (as administrator)
         * @summary updateHoursListingForLabLocation
         * @param {number} hourId hourId
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {string} hours hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoursListingForLabLocationUsingPUT(hourId, labId, locationId, hours, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateHoursListingForLabLocationUsingPUT(hourId, labId, locationId, hours, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Update the details for a fulfilment location for a lab (as administrator)
         * @summary updateLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {UpdateLabLocationRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabLocationUsingPUT(labId, locationId, request, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateLabLocationUsingPUT(labId, locationId, request, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * LabLocationAdminControllerApi - factory interface
 * @export
 */
export const LabLocationAdminControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = LabLocationAdminControllerApiFp(configuration);
    return {
        /**
         * Add additional hour listing(s) for a fulfilment location for a lab (as administrator)
         * @summary addHoursToLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {Array<string>} hours hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHoursToLabLocationUsingPOST(labId, locationId, hours, options) {
            return localVarFp.addHoursToLabLocationUsingPOST(labId, locationId, hours, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new fulfilment location listing for a lab (as administrator)
         * @summary createLabLocation
         * @param {number} labId labId
         * @param {CreateLabLocationRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabLocationUsingPOST(labId, request, options) {
            return localVarFp.createLabLocationUsingPOST(labId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * Get hour listing(s) for a fulfilment location for a lab (as administrator)
         * @summary getHoursForLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoursForLabLocationUsingGET(labId, locationId, options) {
            return localVarFp.getHoursForLabLocationUsingGET(labId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all fulfilment locations for a lab (as administrator)
         * @summary getLabLocations
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabLocationsUsingGET(labId, options) {
            return localVarFp.getLabLocationsUsingGET(labId, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove an hour listing for a fulfilment location for a lab (as administrator)
         * @summary removeHoursListingForLabLocation
         * @param {number} hourId hourId
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeHoursListingForLabLocationUsingDELETE(hourId, labId, locationId, options) {
            return localVarFp.removeHoursListingForLabLocationUsingDELETE(hourId, labId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a fulfilment location listing for a lab (as administrator)
         * @summary removeLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLabLocationUsingDELETE(labId, locationId, options) {
            return localVarFp.removeLabLocationUsingDELETE(labId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an hour listing for a fulfilment location for a lab (as administrator)
         * @summary updateHoursListingForLabLocation
         * @param {number} hourId hourId
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {string} hours hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoursListingForLabLocationUsingPUT(hourId, labId, locationId, hours, options) {
            return localVarFp.updateHoursListingForLabLocationUsingPUT(hourId, labId, locationId, hours, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details for a fulfilment location for a lab (as administrator)
         * @summary updateLabLocation
         * @param {number} labId labId
         * @param {number} locationId locationId
         * @param {UpdateLabLocationRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabLocationUsingPUT(labId, locationId, request, options) {
            return localVarFp.updateLabLocationUsingPUT(labId, locationId, request, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * LabLocationAdminControllerApi - object-oriented interface
 * @export
 * @class LabLocationAdminControllerApi
 * @extends {BaseAPI}
 */
export class LabLocationAdminControllerApi extends BaseAPI {
    /**
     * Add additional hour listing(s) for a fulfilment location for a lab (as administrator)
     * @summary addHoursToLabLocation
     * @param {number} labId labId
     * @param {number} locationId locationId
     * @param {Array<string>} hours hours
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    addHoursToLabLocationUsingPOST(labId, locationId, hours, options) {
        return LabLocationAdminControllerApiFp(this.configuration).addHoursToLabLocationUsingPOST(labId, locationId, hours, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create a new fulfilment location listing for a lab (as administrator)
     * @summary createLabLocation
     * @param {number} labId labId
     * @param {CreateLabLocationRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    createLabLocationUsingPOST(labId, request, options) {
        return LabLocationAdminControllerApiFp(this.configuration).createLabLocationUsingPOST(labId, request, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get hour listing(s) for a fulfilment location for a lab (as administrator)
     * @summary getHoursForLabLocation
     * @param {number} labId labId
     * @param {number} locationId locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    getHoursForLabLocationUsingGET(labId, locationId, options) {
        return LabLocationAdminControllerApiFp(this.configuration).getHoursForLabLocationUsingGET(labId, locationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List all fulfilment locations for a lab (as administrator)
     * @summary getLabLocations
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    getLabLocationsUsingGET(labId, options) {
        return LabLocationAdminControllerApiFp(this.configuration).getLabLocationsUsingGET(labId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Remove an hour listing for a fulfilment location for a lab (as administrator)
     * @summary removeHoursListingForLabLocation
     * @param {number} hourId hourId
     * @param {number} labId labId
     * @param {number} locationId locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    removeHoursListingForLabLocationUsingDELETE(hourId, labId, locationId, options) {
        return LabLocationAdminControllerApiFp(this.configuration).removeHoursListingForLabLocationUsingDELETE(hourId, labId, locationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Remove a fulfilment location listing for a lab (as administrator)
     * @summary removeLabLocation
     * @param {number} labId labId
     * @param {number} locationId locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    removeLabLocationUsingDELETE(labId, locationId, options) {
        return LabLocationAdminControllerApiFp(this.configuration).removeLabLocationUsingDELETE(labId, locationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update an hour listing for a fulfilment location for a lab (as administrator)
     * @summary updateHoursListingForLabLocation
     * @param {number} hourId hourId
     * @param {number} labId labId
     * @param {number} locationId locationId
     * @param {string} hours hours
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    updateHoursListingForLabLocationUsingPUT(hourId, labId, locationId, hours, options) {
        return LabLocationAdminControllerApiFp(this.configuration).updateHoursListingForLabLocationUsingPUT(hourId, labId, locationId, hours, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details for a fulfilment location for a lab (as administrator)
     * @summary updateLabLocation
     * @param {number} labId labId
     * @param {number} locationId locationId
     * @param {UpdateLabLocationRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationAdminControllerApi
     */
    updateLabLocationUsingPUT(labId, locationId, request, options) {
        return LabLocationAdminControllerApiFp(this.configuration).updateLabLocationUsingPUT(labId, locationId, request, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * LabLocationPublicControllerApi - axios parameter creator
 * @export
 */
export const LabLocationPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List all fulfilment locations for a lab (as patient)
         * @summary getLabLocations
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabLocationsUsingGET1: (labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getLabLocationsUsingGET1', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/labs/{labId}/locations`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * LabLocationPublicControllerApi - functional programming interface
 * @export
 */
export const LabLocationPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = LabLocationPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * List all fulfilment locations for a lab (as patient)
         * @summary getLabLocations
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabLocationsUsingGET1(labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLabLocationsUsingGET1(labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * LabLocationPublicControllerApi - factory interface
 * @export
 */
export const LabLocationPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = LabLocationPublicControllerApiFp(configuration);
    return {
        /**
         * List all fulfilment locations for a lab (as patient)
         * @summary getLabLocations
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabLocationsUsingGET1(labId, options) {
            return localVarFp.getLabLocationsUsingGET1(labId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * LabLocationPublicControllerApi - object-oriented interface
 * @export
 * @class LabLocationPublicControllerApi
 * @extends {BaseAPI}
 */
export class LabLocationPublicControllerApi extends BaseAPI {
    /**
     * List all fulfilment locations for a lab (as patient)
     * @summary getLabLocations
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabLocationPublicControllerApi
     */
    getLabLocationsUsingGET1(labId, options) {
        return LabLocationPublicControllerApiFp(this.configuration).getLabLocationsUsingGET1(labId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * MetricsControllerApi - axios parameter creator
 * @export
 */
export const MetricsControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all daily metrics data for all labs for a specific date
         * @param {string} date date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByDateUsingGET: (date, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAllDailyMetricsByDateUsingGET', 'date', date);
            const localVarPath = `/connect/api/metrics/daily/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get daily metrics data for a specific lab id and date
         * @param {string} date date
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByLabAndDateUsingGET: (date, labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAllDailyMetricsByLabAndDateUsingGET', 'date', date);
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getAllDailyMetricsByLabAndDateUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/metrics/daily/lab/{labId}`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get daily metrics data for a specific lab id between two days
         * @param {string} dateFrom dateFrom
         * @param {string} dateTo dateTo
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByLabBetweenDatesUsingGET: (dateFrom, dateTo, labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getAllDailyMetricsByLabBetweenDatesUsingGET', 'dateFrom', dateFrom);
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getAllDailyMetricsByLabBetweenDatesUsingGET', 'dateTo', dateTo);
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getAllDailyMetricsByLabBetweenDatesUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/metrics/daily/lab/{labId}/between-dates`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * MetricsControllerApi - functional programming interface
 * @export
 */
export const MetricsControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = MetricsControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get all daily metrics data for all labs for a specific date
         * @param {string} date date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByDateUsingGET(date, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllDailyMetricsByDateUsingGET(date, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get daily metrics data for a specific lab id and date
         * @param {string} date date
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByLabAndDateUsingGET(date, labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllDailyMetricsByLabAndDateUsingGET(date, labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get daily metrics data for a specific lab id between two days
         * @param {string} dateFrom dateFrom
         * @param {string} dateTo dateTo
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByLabBetweenDatesUsingGET(dateFrom, dateTo, labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllDailyMetricsByLabBetweenDatesUsingGET(dateFrom, dateTo, labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * MetricsControllerApi - factory interface
 * @export
 */
export const MetricsControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = MetricsControllerApiFp(configuration);
    return {
        /**
         *
         * @summary Get all daily metrics data for all labs for a specific date
         * @param {string} date date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByDateUsingGET(date, options) {
            return localVarFp.getAllDailyMetricsByDateUsingGET(date, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get daily metrics data for a specific lab id and date
         * @param {string} date date
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByLabAndDateUsingGET(date, labId, options) {
            return localVarFp.getAllDailyMetricsByLabAndDateUsingGET(date, labId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get daily metrics data for a specific lab id between two days
         * @param {string} dateFrom dateFrom
         * @param {string} dateTo dateTo
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDailyMetricsByLabBetweenDatesUsingGET(dateFrom, dateTo, labId, options) {
            return localVarFp.getAllDailyMetricsByLabBetweenDatesUsingGET(dateFrom, dateTo, labId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * MetricsControllerApi - object-oriented interface
 * @export
 * @class MetricsControllerApi
 * @extends {BaseAPI}
 */
export class MetricsControllerApi extends BaseAPI {
    /**
     *
     * @summary Get all daily metrics data for all labs for a specific date
     * @param {string} date date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    getAllDailyMetricsByDateUsingGET(date, options) {
        return MetricsControllerApiFp(this.configuration).getAllDailyMetricsByDateUsingGET(date, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get daily metrics data for a specific lab id and date
     * @param {string} date date
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    getAllDailyMetricsByLabAndDateUsingGET(date, labId, options) {
        return MetricsControllerApiFp(this.configuration).getAllDailyMetricsByLabAndDateUsingGET(date, labId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get daily metrics data for a specific lab id between two days
     * @param {string} dateFrom dateFrom
     * @param {string} dateTo dateTo
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    getAllDailyMetricsByLabBetweenDatesUsingGET(dateFrom, dateTo, labId, options) {
        return MetricsControllerApiFp(this.configuration).getAllDailyMetricsByLabBetweenDatesUsingGET(dateFrom, dateTo, labId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * MobileSessionPublicControllerApi - axios parameter creator
 * @export
 */
export const MobileSessionPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary getMobileSessionContext
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileSessionContextUsingGET: (labId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getMobileSessionContextUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/mobile/public/session/{labId}`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * MobileSessionPublicControllerApi - functional programming interface
 * @export
 */
export const MobileSessionPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = MobileSessionPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary getMobileSessionContext
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileSessionContextUsingGET(labId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMobileSessionContextUsingGET(labId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * MobileSessionPublicControllerApi - factory interface
 * @export
 */
export const MobileSessionPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = MobileSessionPublicControllerApiFp(configuration);
    return {
        /**
         *
         * @summary getMobileSessionContext
         * @param {number} labId labId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileSessionContextUsingGET(labId, options) {
            return localVarFp.getMobileSessionContextUsingGET(labId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * MobileSessionPublicControllerApi - object-oriented interface
 * @export
 * @class MobileSessionPublicControllerApi
 * @extends {BaseAPI}
 */
export class MobileSessionPublicControllerApi extends BaseAPI {
    /**
     *
     * @summary getMobileSessionContext
     * @param {number} labId labId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileSessionPublicControllerApi
     */
    getMobileSessionContextUsingGET(labId, options) {
        return MobileSessionPublicControllerApiFp(this.configuration).getMobileSessionContextUsingGET(labId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OperationHandlerApi - axios parameter creator
 * @export
 */
export const OperationHandlerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/actuator/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/actuator/health/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OperationHandlerApi - functional programming interface
 * @export
 */
export const OperationHandlerApiFp = function (configuration) {
    const localVarAxiosParamCreator = OperationHandlerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.handleUsingGET(body, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.handleUsingGET1(body, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * OperationHandlerApi - factory interface
 * @export
 */
export const OperationHandlerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OperationHandlerApiFp(configuration);
    return {
        /**
         *
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET(body, options) {
            return localVarFp.handleUsingGET(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1(body, options) {
            return localVarFp.handleUsingGET1(body, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OperationHandlerApi - object-oriented interface
 * @export
 * @class OperationHandlerApi
 * @extends {BaseAPI}
 */
export class OperationHandlerApi extends BaseAPI {
    /**
     *
     * @summary handle
     * @param {{ [key: string]: string; }} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    handleUsingGET(body, options) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary handle
     * @param {{ [key: string]: string; }} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    handleUsingGET1(body, options) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET1(body, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OrderAdminControllerApi - axios parameter creator
 * @export
 */
export const OrderAdminControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Download order details pdf
         * @summary downloadOrderPdf
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrderPdfUsingGET: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOrderPdfUsingGET', 'id', id);
            const localVarPath = `/connect/api/dat/admin/order/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Mark Order as Fulfilled
         * @summary fulfillOrder
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillOrderUsingPOST: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fulfillOrderUsingPOST', 'id', id);
            const localVarPath = `/connect/api/dat/admin/order/{id}/fulfill`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View details of an order (as lab admin)
         * @summary getOrderDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetailsUsingGET: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderDetailsUsingGET', 'id', id);
            const localVarPath = `/connect/api/dat/admin/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary getOrderFollowUpDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderFollowUpDetailsUsingGET: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderFollowUpDetailsUsingGET', 'id', id);
            const localVarPath = `/connect/api/dat/admin/order/{id}/follow-up`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View status change details of an order (as lab admin)
         * @summary getOrderStatusHistory
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatusHistoryUsingGET: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderStatusHistoryUsingGET', 'id', id);
            const localVarPath = `/connect/api/dat/admin/order/{id}/status/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View orders list by lab id (as lab admin)
         * @summary getOrdersListByLabId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListByLabIdUsingGET: (labId, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getOrdersListByLabIdUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/admin/order/list/lab/{labId}`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View orders list (as admin)
         * @summary getOrdersList
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListUsingGET: (page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/admin/order/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Search orders by labId, patientFirstName, patientLastName, createdDate and status (as lab admin)
         * @summary getSearchOrdersList
         * @param {OrderSearchDTO} orderSearchDTO orderSearchDTO
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchOrdersListUsingPOST: (orderSearchDTO, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderSearchDTO' is not null or undefined
            assertParamExists('getSearchOrdersListUsingPOST', 'orderSearchDTO', orderSearchDTO);
            const localVarPath = `/connect/api/dat/admin/order/list/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(orderSearchDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Issue a refund for an order (as lab admin)
         * @summary issueRefund
         * @param {number} id id
         * @param {OrderRefundRequestDTO} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueRefundUsingPOST: (id, request, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issueRefundUsingPOST', 'id', id);
            // verify required parameter 'request' is not null or undefined
            assertParamExists('issueRefundUsingPOST', 'request', request);
            const localVarPath = `/connect/api/dat/admin/order/{id}/refund`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Mark Order as Un-Fulfilled
         * @summary unFulfillOrder
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unFulfillOrderUsingPOST: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unFulfillOrderUsingPOST', 'id', id);
            const localVarPath = `/connect/api/dat/admin/order/{id}/unFulfill`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OrderAdminControllerApi - functional programming interface
 * @export
 */
export const OrderAdminControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = OrderAdminControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Download order details pdf
         * @summary downloadOrderPdf
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrderPdfUsingGET(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadOrderPdfUsingGET(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Mark Order as Fulfilled
         * @summary fulfillOrder
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillOrderUsingPOST(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fulfillOrderUsingPOST(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View details of an order (as lab admin)
         * @summary getOrderDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetailsUsingGET(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrderDetailsUsingGET(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary getOrderFollowUpDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderFollowUpDetailsUsingGET(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrderFollowUpDetailsUsingGET(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View status change details of an order (as lab admin)
         * @summary getOrderStatusHistory
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatusHistoryUsingGET(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrderStatusHistoryUsingGET(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View orders list by lab id (as lab admin)
         * @summary getOrdersListByLabId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListByLabIdUsingGET(labId, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrdersListByLabIdUsingGET(labId, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View orders list (as admin)
         * @summary getOrdersList
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListUsingGET(page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrdersListUsingGET(page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Search orders by labId, patientFirstName, patientLastName, createdDate and status (as lab admin)
         * @summary getSearchOrdersList
         * @param {OrderSearchDTO} orderSearchDTO orderSearchDTO
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchOrdersListUsingPOST(orderSearchDTO, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSearchOrdersListUsingPOST(orderSearchDTO, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Issue a refund for an order (as lab admin)
         * @summary issueRefund
         * @param {number} id id
         * @param {OrderRefundRequestDTO} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueRefundUsingPOST(id, request, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.issueRefundUsingPOST(id, request, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Mark Order as Un-Fulfilled
         * @summary unFulfillOrder
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unFulfillOrderUsingPOST(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unFulfillOrderUsingPOST(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * OrderAdminControllerApi - factory interface
 * @export
 */
export const OrderAdminControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OrderAdminControllerApiFp(configuration);
    return {
        /**
         * Download order details pdf
         * @summary downloadOrderPdf
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrderPdfUsingGET(id, options) {
            return localVarFp.downloadOrderPdfUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark Order as Fulfilled
         * @summary fulfillOrder
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillOrderUsingPOST(id, options) {
            return localVarFp.fulfillOrderUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View details of an order (as lab admin)
         * @summary getOrderDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetailsUsingGET(id, options) {
            return localVarFp.getOrderDetailsUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary getOrderFollowUpDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderFollowUpDetailsUsingGET(id, options) {
            return localVarFp.getOrderFollowUpDetailsUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View status change details of an order (as lab admin)
         * @summary getOrderStatusHistory
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatusHistoryUsingGET(id, options) {
            return localVarFp.getOrderStatusHistoryUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View orders list by lab id (as lab admin)
         * @summary getOrdersListByLabId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListByLabIdUsingGET(labId, page, size, options) {
            return localVarFp.getOrdersListByLabIdUsingGET(labId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * View orders list (as admin)
         * @summary getOrdersList
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListUsingGET(page, size, options) {
            return localVarFp.getOrdersListUsingGET(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Search orders by labId, patientFirstName, patientLastName, createdDate and status (as lab admin)
         * @summary getSearchOrdersList
         * @param {OrderSearchDTO} orderSearchDTO orderSearchDTO
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchOrdersListUsingPOST(orderSearchDTO, page, size, options) {
            return localVarFp.getSearchOrdersListUsingPOST(orderSearchDTO, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Issue a refund for an order (as lab admin)
         * @summary issueRefund
         * @param {number} id id
         * @param {OrderRefundRequestDTO} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueRefundUsingPOST(id, request, options) {
            return localVarFp.issueRefundUsingPOST(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark Order as Un-Fulfilled
         * @summary unFulfillOrder
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unFulfillOrderUsingPOST(id, options) {
            return localVarFp.unFulfillOrderUsingPOST(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OrderAdminControllerApi - object-oriented interface
 * @export
 * @class OrderAdminControllerApi
 * @extends {BaseAPI}
 */
export class OrderAdminControllerApi extends BaseAPI {
    /**
     * Download order details pdf
     * @summary downloadOrderPdf
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    downloadOrderPdfUsingGET(id, options) {
        return OrderAdminControllerApiFp(this.configuration).downloadOrderPdfUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Mark Order as Fulfilled
     * @summary fulfillOrder
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    fulfillOrderUsingPOST(id, options) {
        return OrderAdminControllerApiFp(this.configuration).fulfillOrderUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View details of an order (as lab admin)
     * @summary getOrderDetails
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    getOrderDetailsUsingGET(id, options) {
        return OrderAdminControllerApiFp(this.configuration).getOrderDetailsUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary getOrderFollowUpDetails
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    getOrderFollowUpDetailsUsingGET(id, options) {
        return OrderAdminControllerApiFp(this.configuration).getOrderFollowUpDetailsUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View status change details of an order (as lab admin)
     * @summary getOrderStatusHistory
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    getOrderStatusHistoryUsingGET(id, options) {
        return OrderAdminControllerApiFp(this.configuration).getOrderStatusHistoryUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View orders list by lab id (as lab admin)
     * @summary getOrdersListByLabId
     * @param {number} labId labId
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    getOrdersListByLabIdUsingGET(labId, page, size, options) {
        return OrderAdminControllerApiFp(this.configuration).getOrdersListByLabIdUsingGET(labId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View orders list (as admin)
     * @summary getOrdersList
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    getOrdersListUsingGET(page, size, options) {
        return OrderAdminControllerApiFp(this.configuration).getOrdersListUsingGET(page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Search orders by labId, patientFirstName, patientLastName, createdDate and status (as lab admin)
     * @summary getSearchOrdersList
     * @param {OrderSearchDTO} orderSearchDTO orderSearchDTO
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    getSearchOrdersListUsingPOST(orderSearchDTO, page, size, options) {
        return OrderAdminControllerApiFp(this.configuration).getSearchOrdersListUsingPOST(orderSearchDTO, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Issue a refund for an order (as lab admin)
     * @summary issueRefund
     * @param {number} id id
     * @param {OrderRefundRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    issueRefundUsingPOST(id, request, options) {
        return OrderAdminControllerApiFp(this.configuration).issueRefundUsingPOST(id, request, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Mark Order as Un-Fulfilled
     * @summary unFulfillOrder
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAdminControllerApi
     */
    unFulfillOrderUsingPOST(id, options) {
        return OrderAdminControllerApiFp(this.configuration).unFulfillOrderUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OrderInternalControllerApi - axios parameter creator
 * @export
 */
export const OrderInternalControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Order expiration status update
         * @summary expireOrder
         * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireOrderUsingPOST: (orderOpsByNumberDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderOpsByNumberDTO' is not null or undefined
            assertParamExists('expireOrderUsingPOST', 'orderOpsByNumberDTO', orderOpsByNumberDTO);
            const localVarPath = `/connect/api/dat/internal/order/expire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(orderOpsByNumberDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Order fulfillment status update
         * @summary fulfillOrder
         * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillOrderUsingPOST1: (orderOpsByNumberDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderOpsByNumberDTO' is not null or undefined
            assertParamExists('fulfillOrderUsingPOST1', 'orderOpsByNumberDTO', orderOpsByNumberDTO);
            const localVarPath = `/connect/api/dat/internal/order/fulfill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(orderOpsByNumberDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OrderInternalControllerApi - functional programming interface
 * @export
 */
export const OrderInternalControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = OrderInternalControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Order expiration status update
         * @summary expireOrder
         * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireOrderUsingPOST(orderOpsByNumberDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.expireOrderUsingPOST(orderOpsByNumberDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Order fulfillment status update
         * @summary fulfillOrder
         * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillOrderUsingPOST1(orderOpsByNumberDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fulfillOrderUsingPOST1(orderOpsByNumberDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * OrderInternalControllerApi - factory interface
 * @export
 */
export const OrderInternalControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OrderInternalControllerApiFp(configuration);
    return {
        /**
         * Order expiration status update
         * @summary expireOrder
         * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireOrderUsingPOST(orderOpsByNumberDTO, options) {
            return localVarFp.expireOrderUsingPOST(orderOpsByNumberDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Order fulfillment status update
         * @summary fulfillOrder
         * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillOrderUsingPOST1(orderOpsByNumberDTO, options) {
            return localVarFp.fulfillOrderUsingPOST1(orderOpsByNumberDTO, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OrderInternalControllerApi - object-oriented interface
 * @export
 * @class OrderInternalControllerApi
 * @extends {BaseAPI}
 */
export class OrderInternalControllerApi extends BaseAPI {
    /**
     * Order expiration status update
     * @summary expireOrder
     * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderInternalControllerApi
     */
    expireOrderUsingPOST(orderOpsByNumberDTO, options) {
        return OrderInternalControllerApiFp(this.configuration).expireOrderUsingPOST(orderOpsByNumberDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Order fulfillment status update
     * @summary fulfillOrder
     * @param {OrderOpsByNumberDTO} orderOpsByNumberDTO orderOpsByNumberDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderInternalControllerApi
     */
    fulfillOrderUsingPOST1(orderOpsByNumberDTO, options) {
        return OrderInternalControllerApiFp(this.configuration).fulfillOrderUsingPOST1(orderOpsByNumberDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OrderPatientControllerApi - axios parameter creator
 * @export
 */
export const OrderPatientControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Download order details pdf
         * @summary downloadOrderPdf
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrderPdfUsingGET1: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOrderPdfUsingGET1', 'id', id);
            const localVarPath = `/connect/api/dat/patient/order/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View details of an order (as patient)
         * @summary getOrderDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetailsUsingGET1: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderDetailsUsingGET1', 'id', id);
            const localVarPath = `/connect/api/dat/patient/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View orders list by patient id as patient
         * @summary getOrdersListByPatientId
         * @param {string} [filter] filter
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListByPatientIdUsingGET: (filter, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/patient/order/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Make payment for order from backend [TEST-ONLY ENDPOINT]
         * @summary makePayment
         * @param {string} intentId intentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePaymentUsingPOST: (intentId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'intentId' is not null or undefined
            assertParamExists('makePaymentUsingPOST', 'intentId', intentId);
            const localVarPath = `/connect/api/dat/patient/order/make-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (intentId !== undefined) {
                localVarQueryParameter['intentId'] = intentId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Refund payment for order from backend by stripe intent id
         * @summary refundPayment
         * @param {CancelOrderRequestDTO} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPaymentUsingPOST: (request, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('refundPaymentUsingPOST', 'request', request);
            const localVarPath = `/connect/api/dat/patient/order/payment/refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OrderPatientControllerApi - functional programming interface
 * @export
 */
export const OrderPatientControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = OrderPatientControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Download order details pdf
         * @summary downloadOrderPdf
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrderPdfUsingGET1(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadOrderPdfUsingGET1(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View details of an order (as patient)
         * @summary getOrderDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetailsUsingGET1(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrderDetailsUsingGET1(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View orders list by patient id as patient
         * @summary getOrdersListByPatientId
         * @param {string} [filter] filter
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListByPatientIdUsingGET(filter, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrdersListByPatientIdUsingGET(filter, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Make payment for order from backend [TEST-ONLY ENDPOINT]
         * @summary makePayment
         * @param {string} intentId intentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePaymentUsingPOST(intentId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.makePaymentUsingPOST(intentId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Refund payment for order from backend by stripe intent id
         * @summary refundPayment
         * @param {CancelOrderRequestDTO} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPaymentUsingPOST(request, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.refundPaymentUsingPOST(request, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * OrderPatientControllerApi - factory interface
 * @export
 */
export const OrderPatientControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OrderPatientControllerApiFp(configuration);
    return {
        /**
         * Download order details pdf
         * @summary downloadOrderPdf
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrderPdfUsingGET1(id, options) {
            return localVarFp.downloadOrderPdfUsingGET1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View details of an order (as patient)
         * @summary getOrderDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetailsUsingGET1(id, options) {
            return localVarFp.getOrderDetailsUsingGET1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View orders list by patient id as patient
         * @summary getOrdersListByPatientId
         * @param {string} [filter] filter
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersListByPatientIdUsingGET(filter, page, size, options) {
            return localVarFp.getOrdersListByPatientIdUsingGET(filter, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Make payment for order from backend [TEST-ONLY ENDPOINT]
         * @summary makePayment
         * @param {string} intentId intentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePaymentUsingPOST(intentId, options) {
            return localVarFp.makePaymentUsingPOST(intentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Refund payment for order from backend by stripe intent id
         * @summary refundPayment
         * @param {CancelOrderRequestDTO} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPaymentUsingPOST(request, options) {
            return localVarFp.refundPaymentUsingPOST(request, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OrderPatientControllerApi - object-oriented interface
 * @export
 * @class OrderPatientControllerApi
 * @extends {BaseAPI}
 */
export class OrderPatientControllerApi extends BaseAPI {
    /**
     * Download order details pdf
     * @summary downloadOrderPdf
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPatientControllerApi
     */
    downloadOrderPdfUsingGET1(id, options) {
        return OrderPatientControllerApiFp(this.configuration).downloadOrderPdfUsingGET1(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View details of an order (as patient)
     * @summary getOrderDetails
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPatientControllerApi
     */
    getOrderDetailsUsingGET1(id, options) {
        return OrderPatientControllerApiFp(this.configuration).getOrderDetailsUsingGET1(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View orders list by patient id as patient
     * @summary getOrdersListByPatientId
     * @param {string} [filter] filter
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPatientControllerApi
     */
    getOrdersListByPatientIdUsingGET(filter, page, size, options) {
        return OrderPatientControllerApiFp(this.configuration).getOrdersListByPatientIdUsingGET(filter, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Make payment for order from backend [TEST-ONLY ENDPOINT]
     * @summary makePayment
     * @param {string} intentId intentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPatientControllerApi
     */
    makePaymentUsingPOST(intentId, options) {
        return OrderPatientControllerApiFp(this.configuration).makePaymentUsingPOST(intentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Refund payment for order from backend by stripe intent id
     * @summary refundPayment
     * @param {CancelOrderRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPatientControllerApi
     */
    refundPaymentUsingPOST(request, options) {
        return OrderPatientControllerApiFp(this.configuration).refundPaymentUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OrderableTestAdminControllerApi - axios parameter creator
 * @export
 */
export const OrderableTestAdminControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create new orderable test bundle
         * @summary createOrderableBundle
         * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderableBundleUsingPOST: (orderableTestBundleDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderableTestBundleDTO' is not null or undefined
            assertParamExists('createOrderableBundleUsingPOST', 'orderableTestBundleDTO', orderableTestBundleDTO);
            const localVarPath = `/connect/api/dat/admin/orderable-test/bundle/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(orderableTestBundleDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Create new orderable test panel
         * @summary createOrderablePanel
         * @param {OrderableTestDTO} orderableTestDto orderableTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderablePanelUsingPOST: (orderableTestDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderableTestDto' is not null or undefined
            assertParamExists('createOrderablePanelUsingPOST', 'orderableTestDto', orderableTestDto);
            const localVarPath = `/connect/api/dat/admin/orderable-test/panel/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(orderableTestDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of all tests by lab id
         * @summary listAllOrderableTests
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrderableTestsUsingGET: (labId, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('listAllOrderableTestsUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/admin/orderable-test/{labId}/list/all`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update orderable test bundle
         * @summary updateOrderableBundle
         * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderableBundleUsingPOST: (orderableTestBundleDTO, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderableTestBundleDTO' is not null or undefined
            assertParamExists('updateOrderableBundleUsingPOST', 'orderableTestBundleDTO', orderableTestBundleDTO);
            const localVarPath = `/connect/api/dat/admin/orderable-test/bundle/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(orderableTestBundleDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update orderable test panel
         * @summary updateOrderablePanel
         * @param {OrderableTestDTO} orderableTestDto orderableTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderablePanelUsingPOST: (orderableTestDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderableTestDto' is not null or undefined
            assertParamExists('updateOrderablePanelUsingPOST', 'orderableTestDto', orderableTestDto);
            const localVarPath = `/connect/api/dat/admin/orderable-test/panel/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(orderableTestDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OrderableTestAdminControllerApi - functional programming interface
 * @export
 */
export const OrderableTestAdminControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = OrderableTestAdminControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Create new orderable test bundle
         * @summary createOrderableBundle
         * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderableBundleUsingPOST(orderableTestBundleDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOrderableBundleUsingPOST(orderableTestBundleDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Create new orderable test panel
         * @summary createOrderablePanel
         * @param {OrderableTestDTO} orderableTestDto orderableTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderablePanelUsingPOST(orderableTestDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOrderablePanelUsingPOST(orderableTestDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of all tests by lab id
         * @summary listAllOrderableTests
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrderableTestsUsingGET(labId, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listAllOrderableTestsUsingGET(labId, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Update orderable test bundle
         * @summary updateOrderableBundle
         * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderableBundleUsingPOST(orderableTestBundleDTO, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateOrderableBundleUsingPOST(orderableTestBundleDTO, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Update orderable test panel
         * @summary updateOrderablePanel
         * @param {OrderableTestDTO} orderableTestDto orderableTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderablePanelUsingPOST(orderableTestDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateOrderablePanelUsingPOST(orderableTestDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * OrderableTestAdminControllerApi - factory interface
 * @export
 */
export const OrderableTestAdminControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OrderableTestAdminControllerApiFp(configuration);
    return {
        /**
         * Create new orderable test bundle
         * @summary createOrderableBundle
         * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderableBundleUsingPOST(orderableTestBundleDTO, options) {
            return localVarFp.createOrderableBundleUsingPOST(orderableTestBundleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new orderable test panel
         * @summary createOrderablePanel
         * @param {OrderableTestDTO} orderableTestDto orderableTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderablePanelUsingPOST(orderableTestDto, options) {
            return localVarFp.createOrderablePanelUsingPOST(orderableTestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of all tests by lab id
         * @summary listAllOrderableTests
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrderableTestsUsingGET(labId, page, size, options) {
            return localVarFp.listAllOrderableTestsUsingGET(labId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Update orderable test bundle
         * @summary updateOrderableBundle
         * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderableBundleUsingPOST(orderableTestBundleDTO, options) {
            return localVarFp.updateOrderableBundleUsingPOST(orderableTestBundleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update orderable test panel
         * @summary updateOrderablePanel
         * @param {OrderableTestDTO} orderableTestDto orderableTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderablePanelUsingPOST(orderableTestDto, options) {
            return localVarFp.updateOrderablePanelUsingPOST(orderableTestDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OrderableTestAdminControllerApi - object-oriented interface
 * @export
 * @class OrderableTestAdminControllerApi
 * @extends {BaseAPI}
 */
export class OrderableTestAdminControllerApi extends BaseAPI {
    /**
     * Create new orderable test bundle
     * @summary createOrderableBundle
     * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestAdminControllerApi
     */
    createOrderableBundleUsingPOST(orderableTestBundleDTO, options) {
        return OrderableTestAdminControllerApiFp(this.configuration).createOrderableBundleUsingPOST(orderableTestBundleDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create new orderable test panel
     * @summary createOrderablePanel
     * @param {OrderableTestDTO} orderableTestDto orderableTestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestAdminControllerApi
     */
    createOrderablePanelUsingPOST(orderableTestDto, options) {
        return OrderableTestAdminControllerApiFp(this.configuration).createOrderablePanelUsingPOST(orderableTestDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of all tests by lab id
     * @summary listAllOrderableTests
     * @param {number} labId labId
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestAdminControllerApi
     */
    listAllOrderableTestsUsingGET(labId, page, size, options) {
        return OrderableTestAdminControllerApiFp(this.configuration).listAllOrderableTestsUsingGET(labId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update orderable test bundle
     * @summary updateOrderableBundle
     * @param {OrderableTestBundleDTO} orderableTestBundleDTO orderableTestBundleDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestAdminControllerApi
     */
    updateOrderableBundleUsingPOST(orderableTestBundleDTO, options) {
        return OrderableTestAdminControllerApiFp(this.configuration).updateOrderableBundleUsingPOST(orderableTestBundleDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update orderable test panel
     * @summary updateOrderablePanel
     * @param {OrderableTestDTO} orderableTestDto orderableTestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestAdminControllerApi
     */
    updateOrderablePanelUsingPOST(orderableTestDto, options) {
        return OrderableTestAdminControllerApiFp(this.configuration).updateOrderablePanelUsingPOST(orderableTestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OrderableTestPublicControllerApi - axios parameter creator
 * @export
 */
export const OrderableTestPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * View list of visible featured tests by category and lab id
         * @summary getFeaturedVisibleOrderableTestsByCategoryList
         * @param {number} categoryId categoryId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedVisibleOrderableTestsByCategoryListUsingGET: (categoryId, labId, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getFeaturedVisibleOrderableTestsByCategoryListUsingGET', 'categoryId', categoryId);
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getFeaturedVisibleOrderableTestsByCategoryListUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/orderable-test/{labId}/list/category/{categoryId}/featured`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get orderable test details
         * @summary getOrderableTestDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderableTestDetailsUsingGET: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderableTestDetailsUsingGET', 'id', id);
            const localVarPath = `/connect/api/dat/public/orderable-test/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of featured visible tests by lab id
         * @summary getVisibleFeaturedOrderableTestsList
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleFeaturedOrderableTestsListUsingGET: (labId, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getVisibleFeaturedOrderableTestsListUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/orderable-test/{labId}/list/featured`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of visible tests by category and lab id
         * @summary getVisibleOrderableTestsByCategoryList
         * @param {number} categoryId categoryId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsByCategoryListUsingGET: (categoryId, labId, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getVisibleOrderableTestsByCategoryListUsingGET', 'categoryId', categoryId);
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getVisibleOrderableTestsByCategoryListUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/orderable-test/{labId}/list/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of visible tests by lab id searched by name
         * @summary getVisibleOrderableTestsByName
         * @param {number} labId labId
         * @param {string} search search
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsByNameUsingGET: (labId, search, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getVisibleOrderableTestsByNameUsingGET', 'labId', labId);
            // verify required parameter 'search' is not null or undefined
            assertParamExists('getVisibleOrderableTestsByNameUsingGET', 'search', search);
            const localVarPath = `/connect/api/dat/public/orderable-test/{labId}/list/name-search`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View list of visible tests by lab id
         * @summary getVisibleOrderableTestsList
         * @param {number} labId labId
         * @param {'BUNDLE' | 'PANEL'} [collectionType] collectionType
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsListUsingGET: (labId, collectionType, page, size, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getVisibleOrderableTestsListUsingGET', 'labId', labId);
            const localVarPath = `/connect/api/dat/public/orderable-test/{labId}/list`
                .replace(`{${"labId"}}`, encodeURIComponent(String(labId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (collectionType !== undefined) {
                localVarQueryParameter['collectionType'] = collectionType;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OrderableTestPublicControllerApi - functional programming interface
 * @export
 */
export const OrderableTestPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = OrderableTestPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * View list of visible featured tests by category and lab id
         * @summary getFeaturedVisibleOrderableTestsByCategoryList
         * @param {number} categoryId categoryId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFeaturedVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Get orderable test details
         * @summary getOrderableTestDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderableTestDetailsUsingGET(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOrderableTestDetailsUsingGET(id, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of featured visible tests by lab id
         * @summary getVisibleFeaturedOrderableTestsList
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleFeaturedOrderableTestsListUsingGET(labId, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getVisibleFeaturedOrderableTestsListUsingGET(labId, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of visible tests by category and lab id
         * @summary getVisibleOrderableTestsByCategoryList
         * @param {number} categoryId categoryId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of visible tests by lab id searched by name
         * @summary getVisibleOrderableTestsByName
         * @param {number} labId labId
         * @param {string} search search
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsByNameUsingGET(labId, search, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getVisibleOrderableTestsByNameUsingGET(labId, search, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View list of visible tests by lab id
         * @summary getVisibleOrderableTestsList
         * @param {number} labId labId
         * @param {'BUNDLE' | 'PANEL'} [collectionType] collectionType
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsListUsingGET(labId, collectionType, page, size, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getVisibleOrderableTestsListUsingGET(labId, collectionType, page, size, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * OrderableTestPublicControllerApi - factory interface
 * @export
 */
export const OrderableTestPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OrderableTestPublicControllerApiFp(configuration);
    return {
        /**
         * View list of visible featured tests by category and lab id
         * @summary getFeaturedVisibleOrderableTestsByCategoryList
         * @param {number} categoryId categoryId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options) {
            return localVarFp.getFeaturedVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Get orderable test details
         * @summary getOrderableTestDetails
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderableTestDetailsUsingGET(id, options) {
            return localVarFp.getOrderableTestDetailsUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of featured visible tests by lab id
         * @summary getVisibleFeaturedOrderableTestsList
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleFeaturedOrderableTestsListUsingGET(labId, page, size, options) {
            return localVarFp.getVisibleFeaturedOrderableTestsListUsingGET(labId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of visible tests by category and lab id
         * @summary getVisibleOrderableTestsByCategoryList
         * @param {number} categoryId categoryId
         * @param {number} labId labId
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options) {
            return localVarFp.getVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of visible tests by lab id searched by name
         * @summary getVisibleOrderableTestsByName
         * @param {number} labId labId
         * @param {string} search search
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsByNameUsingGET(labId, search, page, size, options) {
            return localVarFp.getVisibleOrderableTestsByNameUsingGET(labId, search, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * View list of visible tests by lab id
         * @summary getVisibleOrderableTestsList
         * @param {number} labId labId
         * @param {'BUNDLE' | 'PANEL'} [collectionType] collectionType
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOrderableTestsListUsingGET(labId, collectionType, page, size, options) {
            return localVarFp.getVisibleOrderableTestsListUsingGET(labId, collectionType, page, size, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OrderableTestPublicControllerApi - object-oriented interface
 * @export
 * @class OrderableTestPublicControllerApi
 * @extends {BaseAPI}
 */
export class OrderableTestPublicControllerApi extends BaseAPI {
    /**
     * View list of visible featured tests by category and lab id
     * @summary getFeaturedVisibleOrderableTestsByCategoryList
     * @param {number} categoryId categoryId
     * @param {number} labId labId
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestPublicControllerApi
     */
    getFeaturedVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options) {
        return OrderableTestPublicControllerApiFp(this.configuration).getFeaturedVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get orderable test details
     * @summary getOrderableTestDetails
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestPublicControllerApi
     */
    getOrderableTestDetailsUsingGET(id, options) {
        return OrderableTestPublicControllerApiFp(this.configuration).getOrderableTestDetailsUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of featured visible tests by lab id
     * @summary getVisibleFeaturedOrderableTestsList
     * @param {number} labId labId
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestPublicControllerApi
     */
    getVisibleFeaturedOrderableTestsListUsingGET(labId, page, size, options) {
        return OrderableTestPublicControllerApiFp(this.configuration).getVisibleFeaturedOrderableTestsListUsingGET(labId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of visible tests by category and lab id
     * @summary getVisibleOrderableTestsByCategoryList
     * @param {number} categoryId categoryId
     * @param {number} labId labId
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestPublicControllerApi
     */
    getVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options) {
        return OrderableTestPublicControllerApiFp(this.configuration).getVisibleOrderableTestsByCategoryListUsingGET(categoryId, labId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of visible tests by lab id searched by name
     * @summary getVisibleOrderableTestsByName
     * @param {number} labId labId
     * @param {string} search search
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestPublicControllerApi
     */
    getVisibleOrderableTestsByNameUsingGET(labId, search, page, size, options) {
        return OrderableTestPublicControllerApiFp(this.configuration).getVisibleOrderableTestsByNameUsingGET(labId, search, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View list of visible tests by lab id
     * @summary getVisibleOrderableTestsList
     * @param {number} labId labId
     * @param {'BUNDLE' | 'PANEL'} [collectionType] collectionType
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderableTestPublicControllerApi
     */
    getVisibleOrderableTestsListUsingGET(labId, collectionType, page, size, options) {
        return OrderableTestPublicControllerApiFp(this.configuration).getVisibleOrderableTestsListUsingGET(labId, collectionType, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SessionAdminControllerApi - axios parameter creator
 * @export
 */
export const SessionAdminControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary flushLabConfig
         * @param {Array<number>} labIds labIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flushLabConfigUsingPOST: (labIds, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'labIds' is not null or undefined
            assertParamExists('flushLabConfigUsingPOST', 'labIds', labIds);
            const localVarPath = `/connect/api/dat/admin/session/config/flush`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(labIds, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SessionAdminControllerApi - functional programming interface
 * @export
 */
export const SessionAdminControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = SessionAdminControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary flushLabConfig
         * @param {Array<number>} labIds labIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flushLabConfigUsingPOST(labIds, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.flushLabConfigUsingPOST(labIds, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * SessionAdminControllerApi - factory interface
 * @export
 */
export const SessionAdminControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SessionAdminControllerApiFp(configuration);
    return {
        /**
         *
         * @summary flushLabConfig
         * @param {Array<number>} labIds labIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flushLabConfigUsingPOST(labIds, options) {
            return localVarFp.flushLabConfigUsingPOST(labIds, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SessionAdminControllerApi - object-oriented interface
 * @export
 * @class SessionAdminControllerApi
 * @extends {BaseAPI}
 */
export class SessionAdminControllerApi extends BaseAPI {
    /**
     *
     * @summary flushLabConfig
     * @param {Array<number>} labIds labIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionAdminControllerApi
     */
    flushLabConfigUsingPOST(labIds, options) {
        return SessionAdminControllerApiFp(this.configuration).flushLabConfigUsingPOST(labIds, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SessionPublicControllerApi - axios parameter creator
 * @export
 */
export const SessionPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary getSessionContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionContextUsingGET: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/public/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SessionPublicControllerApi - functional programming interface
 * @export
 */
export const SessionPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = SessionPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary getSessionContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionContextUsingGET(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSessionContextUsingGET(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * SessionPublicControllerApi - factory interface
 * @export
 */
export const SessionPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SessionPublicControllerApiFp(configuration);
    return {
        /**
         *
         * @summary getSessionContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionContextUsingGET(options) {
            return localVarFp.getSessionContextUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SessionPublicControllerApi - object-oriented interface
 * @export
 * @class SessionPublicControllerApi
 * @extends {BaseAPI}
 */
export class SessionPublicControllerApi extends BaseAPI {
    /**
     *
     * @summary getSessionContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionPublicControllerApi
     */
    getSessionContextUsingGET(options) {
        return SessionPublicControllerApiFp(this.configuration).getSessionContextUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * ShoppingCartPatientControllerApi - axios parameter creator
 * @export
 */
export const ShoppingCartPatientControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Assign shopping cart to user
         * @summary assignShoppingCartToUser
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignShoppingCartToUserUsingPOST: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('assignShoppingCartToUserUsingPOST', 'transactionId', transactionId);
            const localVarPath = `/connect/api/dat/patient/cart/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Create or update a payment intent
         * @summary createOrUpdatePaymentIntent
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePaymentIntentUsingPOST: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('createOrUpdatePaymentIntentUsingPOST', 'transactionId', transactionId);
            const localVarPath = `/connect/api/dat/patient/cart/payment/intent/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve most recent cart for user
         * @summary getMostRecentCartForUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMostRecentCartForUserUsingGET: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/dat/patient/cart/recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Perform pre-checkout validation to ensure the tests in a cart can be ordered by the patient.
         * @summary pereformPreCheckoutValidation
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pereformPreCheckoutValidationUsingGET: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('pereformPreCheckoutValidationUsingGET', 'transactionId', transactionId);
            const localVarPath = `/connect/api/dat/patient/cart/checkout/validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ShoppingCartPatientControllerApi - functional programming interface
 * @export
 */
export const ShoppingCartPatientControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = ShoppingCartPatientControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Assign shopping cart to user
         * @summary assignShoppingCartToUser
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignShoppingCartToUserUsingPOST(transactionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.assignShoppingCartToUserUsingPOST(transactionId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Create or update a payment intent
         * @summary createOrUpdatePaymentIntent
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePaymentIntentUsingPOST(transactionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOrUpdatePaymentIntentUsingPOST(transactionId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve most recent cart for user
         * @summary getMostRecentCartForUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMostRecentCartForUserUsingGET(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMostRecentCartForUserUsingGET(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Perform pre-checkout validation to ensure the tests in a cart can be ordered by the patient.
         * @summary pereformPreCheckoutValidation
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pereformPreCheckoutValidationUsingGET(transactionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.pereformPreCheckoutValidationUsingGET(transactionId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * ShoppingCartPatientControllerApi - factory interface
 * @export
 */
export const ShoppingCartPatientControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ShoppingCartPatientControllerApiFp(configuration);
    return {
        /**
         * Assign shopping cart to user
         * @summary assignShoppingCartToUser
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignShoppingCartToUserUsingPOST(transactionId, options) {
            return localVarFp.assignShoppingCartToUserUsingPOST(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update a payment intent
         * @summary createOrUpdatePaymentIntent
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePaymentIntentUsingPOST(transactionId, options) {
            return localVarFp.createOrUpdatePaymentIntentUsingPOST(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve most recent cart for user
         * @summary getMostRecentCartForUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMostRecentCartForUserUsingGET(options) {
            return localVarFp.getMostRecentCartForUserUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * Perform pre-checkout validation to ensure the tests in a cart can be ordered by the patient.
         * @summary pereformPreCheckoutValidation
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pereformPreCheckoutValidationUsingGET(transactionId, options) {
            return localVarFp.pereformPreCheckoutValidationUsingGET(transactionId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ShoppingCartPatientControllerApi - object-oriented interface
 * @export
 * @class ShoppingCartPatientControllerApi
 * @extends {BaseAPI}
 */
export class ShoppingCartPatientControllerApi extends BaseAPI {
    /**
     * Assign shopping cart to user
     * @summary assignShoppingCartToUser
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartPatientControllerApi
     */
    assignShoppingCartToUserUsingPOST(transactionId, options) {
        return ShoppingCartPatientControllerApiFp(this.configuration).assignShoppingCartToUserUsingPOST(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create or update a payment intent
     * @summary createOrUpdatePaymentIntent
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartPatientControllerApi
     */
    createOrUpdatePaymentIntentUsingPOST(transactionId, options) {
        return ShoppingCartPatientControllerApiFp(this.configuration).createOrUpdatePaymentIntentUsingPOST(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve most recent cart for user
     * @summary getMostRecentCartForUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartPatientControllerApi
     */
    getMostRecentCartForUserUsingGET(options) {
        return ShoppingCartPatientControllerApiFp(this.configuration).getMostRecentCartForUserUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Perform pre-checkout validation to ensure the tests in a cart can be ordered by the patient.
     * @summary pereformPreCheckoutValidation
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartPatientControllerApi
     */
    pereformPreCheckoutValidationUsingGET(transactionId, options) {
        return ShoppingCartPatientControllerApiFp(this.configuration).pereformPreCheckoutValidationUsingGET(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * ShoppingCartPublicControllerApi - axios parameter creator
 * @export
 */
export const ShoppingCartPublicControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add test to shopping cart
         * @summary addTestToCart
         * @param {CartTestDTO} cartTestDto cartTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestToCartUsingPOST: (cartTestDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'cartTestDto' is not null or undefined
            assertParamExists('addTestToCartUsingPOST', 'cartTestDto', cartTestDto);
            const localVarPath = `/connect/api/dat/public/cart/test/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(cartTestDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete test from shopping cart
         * @summary deleteTestFromCart
         * @param {CartTestDTO} cartTestDto cartTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestFromCartUsingPOST: (cartTestDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'cartTestDto' is not null or undefined
            assertParamExists('deleteTestFromCartUsingPOST', 'cartTestDto', cartTestDto);
            const localVarPath = `/connect/api/dat/public/cart/test/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(cartTestDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * View shopping cart content, by transaction id
         * @summary getCart
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getCartUsingGET', 'transactionId', transactionId);
            const localVarPath = `/connect/api/dat/public/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ShoppingCartPublicControllerApi - functional programming interface
 * @export
 */
export const ShoppingCartPublicControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = ShoppingCartPublicControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Add test to shopping cart
         * @summary addTestToCart
         * @param {CartTestDTO} cartTestDto cartTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestToCartUsingPOST(cartTestDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addTestToCartUsingPOST(cartTestDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Delete test from shopping cart
         * @summary deleteTestFromCart
         * @param {CartTestDTO} cartTestDto cartTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestFromCartUsingPOST(cartTestDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteTestFromCartUsingPOST(cartTestDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * View shopping cart content, by transaction id
         * @summary getCart
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET(transactionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCartUsingGET(transactionId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * ShoppingCartPublicControllerApi - factory interface
 * @export
 */
export const ShoppingCartPublicControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ShoppingCartPublicControllerApiFp(configuration);
    return {
        /**
         * Add test to shopping cart
         * @summary addTestToCart
         * @param {CartTestDTO} cartTestDto cartTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestToCartUsingPOST(cartTestDto, options) {
            return localVarFp.addTestToCartUsingPOST(cartTestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete test from shopping cart
         * @summary deleteTestFromCart
         * @param {CartTestDTO} cartTestDto cartTestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestFromCartUsingPOST(cartTestDto, options) {
            return localVarFp.deleteTestFromCartUsingPOST(cartTestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * View shopping cart content, by transaction id
         * @summary getCart
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET(transactionId, options) {
            return localVarFp.getCartUsingGET(transactionId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ShoppingCartPublicControllerApi - object-oriented interface
 * @export
 * @class ShoppingCartPublicControllerApi
 * @extends {BaseAPI}
 */
export class ShoppingCartPublicControllerApi extends BaseAPI {
    /**
     * Add test to shopping cart
     * @summary addTestToCart
     * @param {CartTestDTO} cartTestDto cartTestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartPublicControllerApi
     */
    addTestToCartUsingPOST(cartTestDto, options) {
        return ShoppingCartPublicControllerApiFp(this.configuration).addTestToCartUsingPOST(cartTestDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete test from shopping cart
     * @summary deleteTestFromCart
     * @param {CartTestDTO} cartTestDto cartTestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartPublicControllerApi
     */
    deleteTestFromCartUsingPOST(cartTestDto, options) {
        return ShoppingCartPublicControllerApiFp(this.configuration).deleteTestFromCartUsingPOST(cartTestDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * View shopping cart content, by transaction id
     * @summary getCart
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartPublicControllerApi
     */
    getCartUsingGET(transactionId, options) {
        return ShoppingCartPublicControllerApiFp(this.configuration).getCartUsingGET(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * StripeControllerApi - axios parameter creator
 * @export
 */
export const StripeControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Update the stripe payment intent status
         * @summary accept
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUsingPOST: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/stripe/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * StripeControllerApi - functional programming interface
 * @export
 */
export const StripeControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = StripeControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Update the stripe payment intent status
         * @summary accept
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUsingPOST(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.acceptUsingPOST(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * StripeControllerApi - factory interface
 * @export
 */
export const StripeControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = StripeControllerApiFp(configuration);
    return {
        /**
         * Update the stripe payment intent status
         * @summary accept
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUsingPOST(options) {
            return localVarFp.acceptUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * StripeControllerApi - object-oriented interface
 * @export
 * @class StripeControllerApi
 * @extends {BaseAPI}
 */
export class StripeControllerApi extends BaseAPI {
    /**
     * Update the stripe payment intent status
     * @summary accept
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeControllerApi
     */
    acceptUsingPOST(options) {
        return StripeControllerApiFp(this.configuration).acceptUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * WebMvcLinksHandlerApi - axios parameter creator
 * @export
 */
export const WebMvcLinksHandlerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/connect/api/actuator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * WebMvcLinksHandlerApi - functional programming interface
 * @export
 */
export const WebMvcLinksHandlerApiFp = function (configuration) {
    const localVarAxiosParamCreator = WebMvcLinksHandlerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.linksUsingGET(options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * WebMvcLinksHandlerApi - factory interface
 * @export
 */
export const WebMvcLinksHandlerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = WebMvcLinksHandlerApiFp(configuration);
    return {
        /**
         *
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET(options) {
            return localVarFp.linksUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * WebMvcLinksHandlerApi - object-oriented interface
 * @export
 * @class WebMvcLinksHandlerApi
 * @extends {BaseAPI}
 */
export class WebMvcLinksHandlerApi extends BaseAPI {
    /**
     *
     * @summary links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebMvcLinksHandlerApi
     */
    linksUsingGET(options) {
        return WebMvcLinksHandlerApiFp(this.configuration).linksUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * WheelCallbackControllerApi - axios parameter creator
 * @export
 */
export const WheelCallbackControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary handleWebhook
         * @param {WheelWebhookDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWebhookUsingPOST: (dto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('handleWebhookUsingPOST', 'dto', dto);
            const localVarPath = `/connect/api/wheel/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(dto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * WheelCallbackControllerApi - functional programming interface
 * @export
 */
export const WheelCallbackControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = WheelCallbackControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary handleWebhook
         * @param {WheelWebhookDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWebhookUsingPOST(dto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.handleWebhookUsingPOST(dto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * WheelCallbackControllerApi - factory interface
 * @export
 */
export const WheelCallbackControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = WheelCallbackControllerApiFp(configuration);
    return {
        /**
         *
         * @summary handleWebhook
         * @param {WheelWebhookDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWebhookUsingPOST(dto, options) {
            return localVarFp.handleWebhookUsingPOST(dto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * WheelCallbackControllerApi - object-oriented interface
 * @export
 * @class WheelCallbackControllerApi
 * @extends {BaseAPI}
 */
export class WheelCallbackControllerApi extends BaseAPI {
    /**
     *
     * @summary handleWebhook
     * @param {WheelWebhookDto} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WheelCallbackControllerApi
     */
    handleWebhookUsingPOST(dto, options) {
        return WheelCallbackControllerApiFp(this.configuration).handleWebhookUsingPOST(dto, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * WheelInternalControllerApi - axios parameter creator
 * @export
 */
export const WheelInternalControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Cancel the consult
         * @summary cancelConsult
         * @param {string} consultId consultId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelConsultUsingPOST: (consultId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'consultId' is not null or undefined
            assertParamExists('cancelConsultUsingPOST', 'consultId', consultId);
            const localVarPath = `/connect/api/dat/internal/wheel/consult-cancel/{consultId}`
                .replace(`{${"consultId"}}`, encodeURIComponent(String(consultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Create the wheel consult
         * @summary createConsult
         * @param {TestConsultDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultUsingPOST: (dto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('createConsultUsingPOST', 'dto', dto);
            const localVarPath = `/connect/api/dat/internal/wheel/consult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(dto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get consults by order number
         * @summary getConsultsList
         * @param {string} orderNumber orderNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultsListUsingGET: (orderNumber, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('getConsultsListUsingGET', 'orderNumber', orderNumber);
            const localVarPath = `/connect/api/dat/internal/wheel/{orderNumber}/consults`
                .replace(`{${"orderNumber"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Store the clinician details
         * @summary storeClinician
         * @param {ProviderDataDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeClinicianUsingPOST: (dto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('storeClinicianUsingPOST', 'dto', dto);
            const localVarPath = `/connect/api/dat/internal/wheel/clinician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(dto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Store follow up id to consult
         * @summary storeFollowUpId
         * @param {string} consultId consultId
         * @param {TestFollowUpDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFollowUpIdUsingPUT: (consultId, dto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'consultId' is not null or undefined
            assertParamExists('storeFollowUpIdUsingPUT', 'consultId', consultId);
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('storeFollowUpIdUsingPUT', 'dto', dto);
            const localVarPath = `/connect/api/dat/internal/wheel/consult/{consultId}/follow-up`
                .replace(`{${"consultId"}}`, encodeURIComponent(String(consultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication JWT required
            yield setApiKeyToObject(localVarHeaderParameter, "x-auth-token", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(dto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * WheelInternalControllerApi - functional programming interface
 * @export
 */
export const WheelInternalControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = WheelInternalControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Cancel the consult
         * @summary cancelConsult
         * @param {string} consultId consultId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelConsultUsingPOST(consultId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cancelConsultUsingPOST(consultId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Create the wheel consult
         * @summary createConsult
         * @param {TestConsultDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultUsingPOST(dto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createConsultUsingPOST(dto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Get consults by order number
         * @summary getConsultsList
         * @param {string} orderNumber orderNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultsListUsingGET(orderNumber, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getConsultsListUsingGET(orderNumber, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Store the clinician details
         * @summary storeClinician
         * @param {ProviderDataDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeClinicianUsingPOST(dto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.storeClinicianUsingPOST(dto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * Store follow up id to consult
         * @summary storeFollowUpId
         * @param {string} consultId consultId
         * @param {TestFollowUpDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFollowUpIdUsingPUT(consultId, dto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.storeFollowUpIdUsingPUT(consultId, dto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * WheelInternalControllerApi - factory interface
 * @export
 */
export const WheelInternalControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = WheelInternalControllerApiFp(configuration);
    return {
        /**
         * Cancel the consult
         * @summary cancelConsult
         * @param {string} consultId consultId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelConsultUsingPOST(consultId, options) {
            return localVarFp.cancelConsultUsingPOST(consultId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the wheel consult
         * @summary createConsult
         * @param {TestConsultDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultUsingPOST(dto, options) {
            return localVarFp.createConsultUsingPOST(dto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get consults by order number
         * @summary getConsultsList
         * @param {string} orderNumber orderNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsultsListUsingGET(orderNumber, options) {
            return localVarFp.getConsultsListUsingGET(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Store the clinician details
         * @summary storeClinician
         * @param {ProviderDataDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeClinicianUsingPOST(dto, options) {
            return localVarFp.storeClinicianUsingPOST(dto, options).then((request) => request(axios, basePath));
        },
        /**
         * Store follow up id to consult
         * @summary storeFollowUpId
         * @param {string} consultId consultId
         * @param {TestFollowUpDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFollowUpIdUsingPUT(consultId, dto, options) {
            return localVarFp.storeFollowUpIdUsingPUT(consultId, dto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * WheelInternalControllerApi - object-oriented interface
 * @export
 * @class WheelInternalControllerApi
 * @extends {BaseAPI}
 */
export class WheelInternalControllerApi extends BaseAPI {
    /**
     * Cancel the consult
     * @summary cancelConsult
     * @param {string} consultId consultId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WheelInternalControllerApi
     */
    cancelConsultUsingPOST(consultId, options) {
        return WheelInternalControllerApiFp(this.configuration).cancelConsultUsingPOST(consultId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create the wheel consult
     * @summary createConsult
     * @param {TestConsultDto} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WheelInternalControllerApi
     */
    createConsultUsingPOST(dto, options) {
        return WheelInternalControllerApiFp(this.configuration).createConsultUsingPOST(dto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get consults by order number
     * @summary getConsultsList
     * @param {string} orderNumber orderNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WheelInternalControllerApi
     */
    getConsultsListUsingGET(orderNumber, options) {
        return WheelInternalControllerApiFp(this.configuration).getConsultsListUsingGET(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Store the clinician details
     * @summary storeClinician
     * @param {ProviderDataDto} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WheelInternalControllerApi
     */
    storeClinicianUsingPOST(dto, options) {
        return WheelInternalControllerApiFp(this.configuration).storeClinicianUsingPOST(dto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Store follow up id to consult
     * @summary storeFollowUpId
     * @param {string} consultId consultId
     * @param {TestFollowUpDto} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WheelInternalControllerApi
     */
    storeFollowUpIdUsingPUT(consultId, dto, options) {
        return WheelInternalControllerApiFp(this.configuration).storeFollowUpIdUsingPUT(consultId, dto, options).then((request) => request(this.axios, this.basePath));
    }
}
