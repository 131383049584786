export var DatComponentName;
(function (DatComponentName) {
    DatComponentName["DAT_CANCELLATION_POLICY"] = "DAT_CANCELLATION_POLICY";
    DatComponentName["DAT_EXPIRATION_POLICY"] = "DAT_EXPIRATION_POLICY";
    DatComponentName["DAT_PURCHASING_CONDITIONS"] = "DAT_PURCHASING_CONDITIONS";
    DatComponentName["DAT_RECEIVING_RESULTS"] = "DAT_RECEIVING_RESULTS";
    DatComponentName["DAT_ORDER_CHECK_EMAIL"] = "DAT_ORDER_CHECK_EMAIL";
    DatComponentName["DAT_SCHEDULING_TESTS_PURCHASING"] = "DAT_SCHEDULING_TESTS_PURCHASING";
    DatComponentName["DAT_SCHEDULING_TESTS_CART"] = "DAT_SCHEDULING_TESTS_CART";
    DatComponentName["DAT_PREPARING_FOR_YOUR_TESTS"] = "DAT_PREPARING_FOR_YOUR_TESTS";
})(DatComponentName || (DatComponentName = {}));
