import validator from 'validator';
import * as R from 'ramda';
import { DateUtilities, SHORT_DATE_SLASH_FORMAT } from './DateUtilities';
export const DEFAULT_REQUIRED_MESSAGE = '* Required';
export const DEFAULT_VALID_EMAIL_MESSAGE = '* Must be a valid email address';
export const PASSWORD_MIN_LENGTH_MESSAGE = '* Must be at least eight characters in length.';
export const PASSWORD_SPECIAL_CHARACTER_MESSAGE = '* Must contain at least one of the following special characters: @#$%^&+=!';
export const PASSWORD_LOWER_CASE_MESSAGE = '* Must contain at least one lowercase character.';
export const PASSWORD_UPPER_CASE_MESSAGE = '* Must contain at least one uppercase character.';
export const PASSWORD_NUMERIC_MESSAGE = '* Must contain at least one number.';
export const US_CURRENCY_MESSAGE = '* Must be a valid US currency.';
export const VALID_USERNAME_MESSAGE = '* Please only use letters, hyphens, and underscores in your username, or user your email.';
export const MUST_MATCH_MESSAGE = '* Values does not match';
export const PASSWORD_MUST_MATCH_MESSAGE = '* Password and Confirm Password must match';
export const DATE_MUST_BE_IN_PAST_MESSAGE = '* Date must not be in the future';
export const DATE_MUST_BE_VALID_DATE_MESSAGE = '* Date must be a valid date in the format MM/DD/YYYY';
export const MUST_BE_18_YEARS_OLD = '* Must be at least 18 years old';
export const FOUR_DIGITS_MESSAGE = '* Must Be 4 Digits';
export const FIVE_DIGITS_MESSAGE = '* Must Be 5 Digits';
export const AT_LEAST_2_CHARS_MESSAGE = '* Must be at least two characters in length.';
export const VALID_PHONE_NUMBER_MESSAGE = '* Must be a valid phone number';
export const MUST_BE_A_VALID_MONTH = '* Must be a valid two digit month';
export const MUST_BE_A_VALID_DAY = '* Must be a valid two digit day of the month';
export const MUST_BE_A_VALID_YEAR = '* Must be a valid four digit year';
export const VALID_USERNAME_REGX = /^[a-zA-Z0-9._%+\-@]+$/;
export const VALID_EMAIL_REGX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const validate = (value, rule, message) => {
    return rule(value) ? message : undefined;
};
export const sameAs = (values, message = MUST_MATCH_MESSAGE) => {
    if (message === MUST_MATCH_MESSAGE) {
        message = `${message}: ${values.first} | ${values.second}`;
    }
    return !R.equals(values.first, values.second) ? message : undefined;
};
export const lessThan = (values, message) => {
    if (!message) {
        message = `Value: ${values.first} must be less than: ${values.second}`;
    }
    return !R.lt(values.first, values.second) ? message : undefined;
};
export const greaterThan = (values, message) => {
    if (!message) {
        message = `Value: ${values.first} must be greater than: ${values.second}`;
    }
    return !R.gt(values.first, values.second) ? message : undefined;
};
export const greaterThanOrEqual = (values, message) => {
    if (!message) {
        message = `Value: ${values.first} must be greater than or equal to: ${values.second}`;
    }
    return !R.gte(values.first, values.second) ? message : undefined;
};
export const lessThanOrEqual = (values, message) => {
    if (!message) {
        message = `Value: ${values.first} must be less or equal to: ${values.second}`;
    }
    return !R.lte(values.first, values.second) ? message : undefined;
};
export const mustBeValidMonth = (value, message = MUST_BE_A_VALID_MONTH) => {
    const test = (value) => isNullOrUndefined(value) || !value.match(/(^0[1-9]$)|(^1[0-2]$)/);
    return validate(value, test, message);
};
export const mustBeValidDay = (value, message = MUST_BE_A_VALID_DAY) => {
    const test = (value) => isNullOrUndefined(value) || !value.match(/(0[1-9]|[12]\d|3[01])/);
    return validate(value, test, message);
};
export const mustBeValidYear = (value, message = MUST_BE_A_VALID_YEAR) => {
    const test = (value) => (value?.length || 0) === 0 || mustBe4Digits(value, message) !== undefined;
    return validate(value, test, message);
};
export const mustPastOrPresentYear = (value, message = DATE_MUST_BE_IN_PAST_MESSAGE) => {
    const test = (value) => !isNullOrUndefined(value) && new Date().getFullYear() < parseInt(value);
    return validate(value, test, message);
};
export const mustBeValidDate = (value, message = DATE_MUST_BE_VALID_DATE_MESSAGE) => {
    const test = (value) => !isNullOrUndefined(value) &&
        !value.match(/^((0[13578]|1[02])[/.]31[/.](18|19|20)[0-9]{2})|((01|0[3-9]|1[1-2])[/.](29|30)[/.](18|19|20)[0-9]{2})|((0[1-9]|1[0-2])[/.](0[1-9]|1[0-9]|2[0-8])[/.](18|19|20)[0-9]{2})|((02)[/.]29[/.](((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))$/);
    return validate(value, test, message);
};
export const usPhoneNumber = (value, message = VALID_PHONE_NUMBER_MESSAGE) => {
    const test = (value) => value === undefined ||
        value === null ||
        !value.match(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/);
    return validate(value, test, message);
};
export const mustBe4Digits = (value, message = FOUR_DIGITS_MESSAGE) => {
    const test = (value) => !isNullOrUndefined(value) && !validator.isEmpty(value, { ignore_whitespace: true }) && !value.match(/^\d{4}$/);
    return validate(value, test, message);
};
export const mustBe5Digits = (value, message = FIVE_DIGITS_MESSAGE) => {
    const test = (value) => !isNullOrUndefined(value) && !validator.isEmpty(value, { ignore_whitespace: true }) && !value.match(/^\d{5}$/);
    return validate(value, test, message);
};
export const minAndMaxLength = (value, message) => {
    if (!message) {
        message = `* Must be between ${value.min} and ${value.max} characters in length.`;
    }
    const test = (value) => !validator.isLength(value.data, {
        min: value.min,
        max: value.max,
    });
    return validate(value, test, message);
};
export const minimumLength = (value, message) => {
    if (!message) {
        message = `* Must bet at least ${value.min} characters in length.`;
    }
    const test = (value) => !validator.isLength(value.data, { min: value.min });
    return validate(value, test, message);
};
export const maximumLength = (value, message) => {
    if (!message) {
        message = `* Must be no longer than ${value.max} characters in length.`;
    }
    const test = (value) => !validator.isLength(value.data, { max: value.max });
    return validate(value, test, message);
};
export const atLeast2Chars = (value, message = AT_LEAST_2_CHARS_MESSAGE) => {
    if (value === null || value === undefined) {
        return message;
    }
    const test = (value) => !validator.isLength(value, { min: 2 });
    return validate(value, test, message);
};
export const isRequired = (value, message = DEFAULT_REQUIRED_MESSAGE) => {
    if (!value) {
        return message;
    }
    return validate(value, validator.isEmpty, message);
};
export const isEmail = (value, message = DEFAULT_VALID_EMAIL_MESSAGE) => {
    if (value) {
        const test = (value) => {
            const isValidEmail = validator.isEmail(value);
            const isValidUserName = value.match(VALID_USERNAME_REGX) != undefined;
            return !(isValidEmail && isValidUserName);
        };
        return validate(value, test, message);
    }
};
const isNullOrUndefined = (value) => {
    return value === null || value === undefined;
};
export const isValidDate = (value, message = DATE_MUST_BE_VALID_DATE_MESSAGE) => {
    const isValidDateRule = () => {
        const parts = value.split('/');
        const isNotNull = !isNullOrUndefined(value);
        const momentIsValid = !DateUtilities.isValid(value, SHORT_DATE_SLASH_FORMAT);
        const partsIsValid = parts.length != 3 || parts[0].length < 1 || parts[1].length < 1 || parts[2].length != 4;
        return isNotNull && (momentIsValid || partsIsValid);
    };
    return validate(value, isValidDateRule, message);
};
export const dateIsInThePast = (value, message = DATE_MUST_BE_IN_PAST_MESSAGE) => {
    const test = (value) => {
        const date = DateUtilities.fromString(value, SHORT_DATE_SLASH_FORMAT);
        return !isNullOrUndefined(value) && date !== undefined && DateUtilities.isInThePast(date);
    };
    return validate(value, test, message);
};
export const mustBe18YearsOld = (value, message = MUST_BE_18_YEARS_OLD) => {
    if (!value) {
        return message;
    }
    const date = DateUtilities.fromString(value, SHORT_DATE_SLASH_FORMAT);
    const test = (value) => !(DateUtilities.yearsSince(value) >= 18);
    return validate(date, test, message);
};
export const eightCharacterMinimum = (value, message = PASSWORD_MIN_LENGTH_MESSAGE) => {
    if (value === null || value === undefined) {
        return message;
    }
    const test = (value) => !validator.isLength(value, { min: 8 });
    return validate(value, test, message);
};
export const containsOneSpecialCharacter = (value, message = PASSWORD_SPECIAL_CHARACTER_MESSAGE) => {
    if (!value) {
        return message;
    }
    const test = (value) => !value.match(/^(?=.*[@#$%^&+=!]).*$/);
    return validate(value, test, message);
};
export const containsOneLowercaseCharacter = (value, message = PASSWORD_LOWER_CASE_MESSAGE) => {
    if (!value) {
        return message;
    }
    const test = (value) => !value.match(/^(?=.*[a-z]).*$/);
    return validate(value, test, message);
};
export const containsOneUppercaseCharacter = (value, message = PASSWORD_UPPER_CASE_MESSAGE) => {
    if (!value) {
        return message;
    }
    const test = (value) => !value.match(/^(?=.*[A-Z]).*$/);
    return validate(value, test, message);
};
export const containsOneNumber = (value, message = PASSWORD_NUMERIC_MESSAGE) => {
    if (!value) {
        return message;
    }
    const test = (value) => !value.match(/^(?=.*[0-9]).*$/);
    return validate(value, test, message);
};
export const usCurrency = (value, message = US_CURRENCY_MESSAGE) => {
    if (value) {
        return validate(value, (value) => !value.match(/^((USD)?\$)?-?(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,2})?$/), message);
    }
};
export const isValidUserName = (value, message = VALID_USERNAME_MESSAGE) => {
    const test = (value) => !value.match(VALID_USERNAME_REGX);
    return validate(value, test, message);
};
