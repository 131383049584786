export class Operation {
    dynamoDbDocClient;
    commandType;
    spec;
    constructor(dynamoDbDocClient, commandType, spec) {
        this.dynamoDbDocClient = dynamoDbDocClient;
        this.commandType = commandType;
        this.spec = spec;
    }
    withSpec = (spec) => {
        this.spec = spec;
        return this;
    };
    execute = async () => {
        const command = new this.commandType(this.spec);
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return await this.dynamoDbDocClient.send(command);
    };
}
