import axios from 'axios';
export class FaqApiV2 {
    apiBaseUrl;
    axiosInstance;
    static FAQ_BASE_PATH = '/v1/faq';
    constructor(apiBaseUrl, axiosInstance) {
        this.apiBaseUrl = apiBaseUrl;
        this.axiosInstance = axiosInstance;
    }
    static create = (apiBaseUrl, axiosInstance) => {
        return new FaqApiV2(apiBaseUrl, axiosInstance || axios.create());
    };
    getFaqsByContext = async (appName, labId) => {
        return await this.axiosInstance.get(`${this.apiBaseUrl}${FaqApiV2.FAQ_BASE_PATH}/${appName}/${labId}`);
    };
}
