import { DynamoRepository } from './DynamoRepository';
import { LhDynamoClient } from '../LhDynamoClient';
import { ComparisonOperator } from '../ComparisonOperator';
import { ConfigConverter } from '../entity';
export class ConfigsRepository extends DynamoRepository {
    converter;
    static TABLE_NAME = 'configs';
    static FIELD_ENTITY_ID = 'entity';
    static LAB_ENTITY_PREFIX = 'LAB_ENTITY:';
    static FIELD_CONFIG_NAME = 'config_name';
    static FIELD_CATEGORY = 'category';
    static FIELD_CONFIG_VALUE = 'config_value';
    constructor(dbClient, environment = process.env.ENV, converter) {
        super(dbClient, `lh-${environment}-${ConfigsRepository.TABLE_NAME}`);
        this.converter = converter;
    }
    static create = (dbClient, environment, converter) => {
        return new ConfigsRepository(dbClient || LhDynamoClient.create(), environment, converter || ConfigConverter.create());
    };
    getLabConfig = async (labId) => {
        const result = await this.query()
            .withKeyCondition(ConfigsRepository.FIELD_ENTITY_ID, ComparisonOperator.Equals, `${ConfigsRepository.LAB_ENTITY_PREFIX}${labId}`)
            .execute();
        return this.converter.toModel(result.Items || []);
    };
}
