export class LabLocationDtoToLabLocationConverter {
    static create = () => {
        return new LabLocationDtoToLabLocationConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            name: source.name,
            address1: source.address1,
            address2: source.address2,
            city: source.city,
            state: source.state,
            zip: source.zip,
            phone: source.phone,
            fax: source.fax,
            coordinate: { latitude: source.latitude, longitude: source.longitude },
            businessHours: source.hours ? source.hours.map((hours) => hours.hours) : [],
        };
    };
}
