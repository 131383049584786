import { DatServiceAreasPublicControllerApi } from '@luminate/connect-api-client-ts/src/api';
import { DatServiceAreasContentConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class DatServiceAreasService {
    api;
    converter;
    constructor(api, converter) {
        this.api = api;
        this.converter = converter;
    }
    static create = (apiBaseUrl) => {
        return new DatServiceAreasService(new DatServiceAreasPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), DatServiceAreasContentConverter.create());
    };
    getDatServiceAreasContent = async () => {
        const response = await this.api.getDatServiceAreasContentByWhiteLabelDomainUsingGET();
        return this.converter.toModel(response.data);
    };
}
