import { intersection } from 'ramda';
export const SONIC_LABS = [12, 13, 15, 16, 17, 34, 35];
export const SONIC_LAB_GROUPS = [1];
export const BIO_REF_LABS = [9];
export const ALVERNO_LABS = [14];
export const COMPUNET_LABS = [6];
export const TRICORE_LABS = [32];
export const DLS_LABS = [36];
export const labConstants = {
    isLabMember: function (userLabIds, labIds) {
        return intersection(userLabIds, labIds).length > 0;
    },
    isSonic: function (labIds) {
        return this.isLabMember(labIds, SONIC_LABS) || this.isLabMember(labIds, SONIC_LAB_GROUPS);
    },
    isTricore: function (labIds) {
        return this.isLabMember(labIds, TRICORE_LABS);
    },
    isBioRef: function (labIds) {
        return this.isLabMember(labIds, BIO_REF_LABS);
    },
    isAlverno: function (labIds) {
        return this.isLabMember(labIds, ALVERNO_LABS);
    },
    isCompuNet: function (labIds) {
        return this.isLabMember(labIds, COMPUNET_LABS);
    },
    isDLS: function (labIds) {
        return this.isLabMember(labIds, DLS_LABS);
    },
};
