import { ShoppingCartPublicControllerApi } from '@luminate/connect-api-client-ts';
import { ConnectApiException, DuplicateTestException, LuminateRuntimeException } from '../models';
import { CartDtoToCartConverter } from './converter';
import { isAxiosErrorResponse } from './ErrorService';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class PublicShoppingCartService {
    publicShoppingCartApi;
    cartDtoConverter;
    static DEFAULT_QUANTITY = 1;
    static DEFAULT_ADD_TO_CART_UNKNOWN_ERROR_MESSAGE = 'Unknown error adding test to cart.';
    constructor(publicShoppingCartApi, cartDtoConverter) {
        this.publicShoppingCartApi = publicShoppingCartApi;
        this.cartDtoConverter = cartDtoConverter;
    }
    /**
     * Helper factory used to create a new instance of {@link PublicShoppingCartService}.
     * @param {@link string} apiBaseUrl Endpoint URL used to make requests for {@link ShoppingCartPublicControllerApi}.
     * @param {@link string} authToken Authorization token used when making requests in some cases like removing items from a cart when a user is logged in.
     * @param {@link ShoppingCartPublicControllerApi} api Public Api used to interact with the Shopping cart.
     * @param {@link CartDtoToCartConverter} cartDtoConverter Service used to transform the Cart from a DTO to an application domain model.
     * @returns {@link PublicShoppingCartService}
     */
    static create = (apiBaseUrl, authToken, api, cartDtoConverter) => {
        return new PublicShoppingCartService(api ? api : new ShoppingCartPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create(authToken)), cartDtoConverter ? cartDtoConverter : CartDtoToCartConverter.create());
    };
    /**
     * Retrieve a shopping cart.
     * @param {string} transactionId Unique identifier of the transaction id associated with the shopping cart.
     * @returns {Promise<Cart>} The shopping cart.
     */
    getCart = async (transactionId) => {
        const response = await this.publicShoppingCartApi.getCartUsingGET(transactionId);
        return this.cartDtoConverter.toModel(response.data);
    };
    /**
     * Add a Test to the Patient's Cart.
     * @param {number} testId Unique identifier of the test to be added to the cart.
     * @param {string} [transactionId] Unique identifier of the transaction associated wit the patient's current cart.
     * If not provided the item will be added to a new cart.
     * @param {number} [quantity] Units of the test to be added to the cart. If not provided the default value is one.
     * @returns {Promise<Cart>} The cart containing the new item.
     */
    addTestToCart = async (testId, transactionId, quantity) => {
        try {
            const response = await this.publicShoppingCartApi.addTestToCartUsingPOST({
                testId: testId,
                quantity: quantity ? quantity : PublicShoppingCartService.DEFAULT_QUANTITY,
                transactionId: transactionId,
            });
            return this.cartDtoConverter.toModel(response.data);
        }
        catch (ex) {
            if (isAxiosErrorResponse(ex)) {
                const error = ex;
                if (error.response !== undefined &&
                    error.response.status === 403 &&
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    error.response.data.additionalInfo !== undefined &&
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    error.response.data.additionalInfo.responseObject !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    throw new DuplicateTestException(error.response.data.additionalInfo.responseObject, error.response.data.message, error);
                }
                else {
                    throw new ConnectApiException(PublicShoppingCartService.DEFAULT_ADD_TO_CART_UNKNOWN_ERROR_MESSAGE, error);
                }
            }
            else {
                throw new LuminateRuntimeException(PublicShoppingCartService.DEFAULT_ADD_TO_CART_UNKNOWN_ERROR_MESSAGE, ex);
            }
        }
    };
    /**
     * Remove an Order-able Test from the Patient's Cart.
     * @param {number} testId Unique identifier of the test to be removed from the cart.
     * @param {string} transactionId Unique identifier of the transaction associated wit the patient's current cart.
     * @param {number} [quantity] Units of the test to be removed from the cart. If not provided the default value is one.
     */
    removeTestFromCart = async (testId, transactionId, quantity) => {
        const response = await this.publicShoppingCartApi.deleteTestFromCartUsingPOST({
            testId: testId,
            transactionId: transactionId,
            quantity: quantity ? quantity : PublicShoppingCartService.DEFAULT_QUANTITY,
        });
        return this.cartDtoConverter.toModel(response.data);
    };
}
