export class CategoryDtoToCategoryConverter {
    static create = () => {
        return new CategoryDtoToCategoryConverter();
    };
    toModel = (source) => {
        return {
            id: source.id,
            name: source.name,
        };
    };
}
