import { ActivityLogEntity } from '../entity';
import { ComparisonOperator, LhDynamoClient } from '..';
import { DynamoRepository } from './DynamoRepository';
export class ActivityLogRepository extends DynamoRepository {
    static ACTIVITY_LOG_PATIENT_ID_LAB_EXTERNAL_ID_ACTIVITY_KEY = 'patient_id_lab_external_id_activity_key';
    static PATIENT_ID_LAB_EXTERNAL_ID_ACTIVITY_KEY_TIMESTAMP_INDEX = 'patient_id_lab_external_id_activity_key-timestamp-index';
    static PATIENT_ID_INDEX = 'patient_id-index';
    static ACTIVITY_LOG_TABLE_NAME = '_activity_log';
    constructor(dbClient, environment = process.env.ENV) {
        super(dbClient, `${environment}${ActivityLogRepository.ACTIVITY_LOG_TABLE_NAME}`);
    }
    static create = (dbClient, environment) => {
        return new ActivityLogRepository(dbClient || LhDynamoClient.create(), environment);
    };
    save = async (activityLog) => {
        await this.dbClient.put().withTableName(this.tableName).withItem(activityLog).execute();
    };
    getActivityLogEntitiesByPatientIdAndActivityKey = async (patientId, activityLogType) => {
        return await this.query()
            .withIndexName(ActivityLogRepository.PATIENT_ID_INDEX)
            .withKeyCondition(ActivityLogEntity.FIELDS.PATIENT_ID, ComparisonOperator.Equals, patientId)
            .withFilter(ActivityLogEntity.FIELDS.ACTIVITY_KEY, ComparisonOperator.Equals, activityLogType)
            .withScanIndexForward(false)
            .execute();
    };
    getActivityLogEntitiesByPatientIdAndLabExternalIdAndActivityKey = async (patientId, labExternalId, activityLogType) => {
        return await this.query()
            .withIndexName(ActivityLogRepository.PATIENT_ID_LAB_EXTERNAL_ID_ACTIVITY_KEY_TIMESTAMP_INDEX)
            .withKeyCondition(ActivityLogRepository.ACTIVITY_LOG_PATIENT_ID_LAB_EXTERNAL_ID_ACTIVITY_KEY, ComparisonOperator.Equals, `${patientId}:${labExternalId}:${activityLogType}`)
            .withScanIndexForward(false)
            .execute();
    };
    getActivityLogEntityById = async (logId) => {
        return await this.findById(logId, ActivityLogEntity.FIELDS.ID).execute();
    };
}
