import { CarouselItemActionDtoToCarouselItemActionConverter } from './CarouselItemActionDtoToCarouselItemActionConverter';
export class CarouselItemDtoToCarouselItemConverter {
    actionConverter;
    constructor(actionConverter) {
        this.actionConverter = actionConverter;
    }
    static create = () => {
        return new CarouselItemDtoToCarouselItemConverter(CarouselItemActionDtoToCarouselItemActionConverter.create());
    };
    toModel = (source) => {
        return {
            id: source.id,
            title: source.title,
            description: source.description,
            imageUrl: source.imageUrl,
            action: source.action ? this.actionConverter.toModel(source.action) : undefined,
        };
    };
}
