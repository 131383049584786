import axios from 'axios';
import { PushDeviceRegistrationResponseToPushRegistryConverter } from '../services';
export class PushNotificationApi {
    apiBaseUrl;
    axiosInstance;
    registrationResponseConverter;
    constructor(apiBaseUrl, axiosInstance, registrationResponseConverter) {
        this.apiBaseUrl = apiBaseUrl;
        this.axiosInstance = axiosInstance;
        this.registrationResponseConverter = registrationResponseConverter;
    }
    static DEVICE_REGISTRATION_PATH = '/v1/push/register';
    static PUSH_BASE_PATH = '/v1/push';
    static create = (apiBaseUrl, axiosInstance) => {
        return new PushNotificationApi(apiBaseUrl, axiosInstance || axios.create(), PushDeviceRegistrationResponseToPushRegistryConverter.create());
    };
    register = async (token, platform, userId, patientId) => {
        const response = await this.axiosInstance.post(`${this.apiBaseUrl}${PushNotificationApi.DEVICE_REGISTRATION_PATH}`, { token, platform, userId, patientId });
        return this.registrationResponseConverter.toModel(response.data);
    };
    enable = async (token) => {
        await this.axiosInstance.post(`${this.apiBaseUrl}${PushNotificationApi.PUSH_BASE_PATH}/${encodeURIComponent(token)}/enable`);
    };
    disable = async (token) => {
        await this.axiosInstance.post(`${this.apiBaseUrl}${PushNotificationApi.PUSH_BASE_PATH}/${encodeURIComponent(token)}/disable`);
    };
}
