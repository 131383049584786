import { AcknowledgmentPublicControllerApi } from '@luminate/connect-api-client-ts/src/api';
import { DatAcknowledgmentConverter } from './converter';
import { AdminApiClientConfigFactory } from './AdminApiClientConfigFactory';
export class DatAcknowledgementService {
    ackApi;
    converter;
    constructor(ackApi, converter) {
        this.ackApi = ackApi;
        this.converter = converter;
    }
    static create = (apiBaseUrl) => {
        return new DatAcknowledgementService(new AcknowledgmentPublicControllerApi(undefined, apiBaseUrl, AdminApiClientConfigFactory.create()), DatAcknowledgmentConverter.create());
    };
    getDatAcknowledgments = async () => {
        const response = await this.ackApi.getAcknowledgmentsByWhiteLabelDomainUsingGET();
        return response.data.map((ack) => this.converter.toModel(ack));
    };
}
