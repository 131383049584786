import { ComparisonOperator, LhDynamoClient } from '..';
import { AccessionViewEntity } from '../entity';
import { DynamoRepository } from './DynamoRepository';
export class AccessionViewsRepository extends DynamoRepository {
    static TABLE_NAME = 'accession-views';
    constructor(dbClient, environment = process.env.ENV) {
        super(dbClient, `lh-${environment}-${AccessionViewsRepository.TABLE_NAME}`);
    }
    static create = (dbClient, environment) => {
        return new AccessionViewsRepository(dbClient || LhDynamoClient.create(), environment);
    };
    getAccessionViewsByPatientAndExternalLabIdAndType = async (patientId, externalLabId, type) => {
        return await this.query()
            .withScanIndexForward(false)
            .withKeyCondition(AccessionViewEntity.FIELDS.PATIENT_ID, ComparisonOperator.Equals, patientId.toString())
            .withFilter(AccessionViewEntity.FIELDS.TYPE, ComparisonOperator.Equals, type)
            .andFilter(AccessionViewEntity.FIELDS.EXTERNAL_LAB_ID, ComparisonOperator.Equals, externalLabId)
            .execute();
    };
    getAccessionViewsByPatientAndExternalLabIdWithTypes = async (patientId, externalLabId, types) => {
        return await this.query()
            .withScanIndexForward(false)
            .withKeyCondition(AccessionViewEntity.FIELDS.PATIENT_ID, ComparisonOperator.Equals, patientId.toString())
            .withFilter(AccessionViewEntity.FIELDS.TYPE, ComparisonOperator.In, types)
            .andFilter(AccessionViewEntity.FIELDS.EXTERNAL_LAB_ID, ComparisonOperator.Equals, externalLabId)
            .execute();
    };
}
