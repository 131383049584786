export class DatComponentContentConverter {
    static create = () => {
        return new DatComponentContentConverter();
    };
    toModel = (source) => {
        return {
            header: source.header,
            content: source.content,
        };
    };
}
